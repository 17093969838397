import { useSearchParams } from 'react-router-dom'
import ErrorPage from '../ErrorPage'
import faAppLogo from '../../assets/fa-app-logo.svg'
import AnchorButton from '../../shared/buttons/AnchorButton'

const Loop = ({
  generateLoopAutologinUrl,
}: {
  generateLoopAutologinUrl: (loopUserId: string) => string
}) => {
  const [params] = useSearchParams()
  const userId = params.get('userId')

  if (!userId) {
    return (
      <ErrorPage
        error={400}
        message="Missing `userId`. Please provide it as a query parameter."
      />
    )
  }

  const autologinFinalUrl = generateLoopAutologinUrl(userId)

  return (
    <div className="text-[#212121] py-8 px-4">
      <section className="space-y-6">
        <p className="text-center px-6">
          Log into your FamilyAlbum account to connect to Loop
        </p>
        <img src={faAppLogo} alt="FamilyAlbum" className="mx-auto pb-4" />
        <p className="text-center px-6">
          This will allow <span className="text-[#EA5B4F] font-bold">Loop</span>{' '}
          to view your Favorites library in the FamilyAlbum app
        </p>
        <AnchorButton
          className="block text-center py-2"
          colorVariant="primary"
          href={autologinFinalUrl}
        >
          Log in via the FamilyAlbum app <br />
          <small className="font-normal">*Latest app version required</small>
        </AnchorButton>
      </section>
      <section className="space-y-6 mt-8">
        <p>
          By continuing, you are agreeing to Loop's{' '}
          <a href="">Privacy Policy</a> and <a href="">Terms of Service.</a>
        </p>
        <p>
          Loop will receive ongoing access to your FamilyAlbum's favorites
          library and FamilyAlbum will record when Loop accesses it.
        </p>
        <p>©MIXI</p>
      </section>
    </div>
  )
}

export default Loop
