import { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import LoadingOverlay from './components/LoadingOverlay'
import ApiClient from '../../ApiClient'
import usePrintify from './usePrintify'
import CreateFlow from './CreateFlow'
import TermsOfUsePanel from './components/TermsOfUsePanel'
import { useNavigate } from 'react-router-dom'
import LogsPanel from './LogsPanel'

export default function PhotoProductsApp({
  api,
  isPrintifyPreviewLogsActive,
  sourcePath,
}: {
  api: ApiClient
  isPrintifyPreviewLogsActive: boolean
  sourcePath: string
}) {
  const {
    isLoading,
    orderState,
    blueprint,
    variantProviders,
    createBaseOrder,
    setOrderState,
    loadOrder,
    previewPhotosList,
    isCreatingBaseOrder,
    isGettingOrder,
    isGettingPreview,
    showNotAllLoadedError,
    regeneratePreview,
    fetchLogs,
  } = usePrintify(api)

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')
  const sourcePathWithUuid = useMemo(() => `${sourcePath}?uuid=${uuid}`, [uuid])

  const [isLogsPageVisible, setIsLogsPageVisible] = useState(false)
  const [isTermsOfUsePageVisible, setIsTermsOfUsePageVisible] = useState(false)

  const navigateBackToSource = useCallback(() => {
    navigate(sourcePathWithUuid)
  }, [navigate, sourcePath, uuid])

  if (isLoading) {
    return <LoadingOverlay>Loading product information</LoadingOverlay>
  }

  if (!blueprint) {
    throw new Error('Blueprint not found')
  }
  return (
    <>
      <CreateFlow
        api={api}
        blueprint={blueprint}
        orderState={orderState}
        variantProviders={variantProviders}
        updateQuantity={(quantity: number) =>
          setOrderState({ ...orderState, quantity })
        }
        updateQuantityAndVariant={(quantity: number, variantId: number) =>
          setOrderState({ ...orderState, quantity, variantId })
        }
        navigateBackToSource={navigateBackToSource}
        createBaseOrder={createBaseOrder}
        previewPhotosList={previewPhotosList}
        loadOrder={loadOrder}
        isCreatingBaseOrder={isCreatingBaseOrder}
        isGettingOrder={isGettingOrder}
        openTermsOfUsePage={() => setIsTermsOfUsePageVisible(true)}
        isGettingPreview={isGettingPreview}
        showNotAllLoadedError={showNotAllLoadedError}
        regeneratePreview={regeneratePreview}
        openLogsPage={() => setIsLogsPageVisible(true)}
        isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
      />
      <TermsOfUsePanel
        isOpen={isTermsOfUsePageVisible}
        close={() => setIsTermsOfUsePageVisible(false)}
      />
      {isPrintifyPreviewLogsActive && (
        <LogsPanel
          logs={fetchLogs}
          isOpen={isLogsPageVisible}
          close={() => setIsLogsPageVisible(false)}
        />
      )}
    </>
  )
}
