import Lottie from 'lottie-react'
import confettiCanon from '../../animations/confetti-cannon.json'

export default function SurveyThankYou({
  title,
  message,
}: {
  title: string
  message: string
}) {
  return (
    <div className="h-screen flex flex-col items-center pt-16 px-4">
      <h2 className="text-2xl font-bold mb-2 text-center">{title}</h2>
      <Lottie
        animationData={confettiCanon}
        className="mx-auto relative w-36 left-4"
      />
      <div className="mt-8 space-y-12">
        <p>{message}</p>
        <p>
          If you have any questions or requests, don’t hesitate to reach out to
          us at{' '}
          <a
            className="text-fa-primary"
            href="mailto:global-support@family-album.com"
          >
            global-support@family-album.com
          </a>
        </p>
      </div>
    </div>
  )
}
