import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { isAxiosError } from 'axios'
import { useRollbar } from '@rollbar/react'
import { keyBy } from 'lodash'
import ApiClient, {
  GetAdvocateRedemptionStateResponse,
} from '../../../ApiClient'
import useTransitionCode from '../../../hooks/useTransaitionCode'
import { createCurrencyFormatter } from '../../../utils'
import { getCopy } from './messages'

const getReferralUrl = (
  referralCode: string,
  hasClaimedAllRedemption: boolean
) => {
  const referralUrl = new URL(window.location.origin)
  referralUrl.pathname = `${
    hasClaimedAllRedemption ? 'r0' : 'r'
  }/${referralCode}`

  return referralUrl.href
}

const useAdvocateData = ({
  api,
  advocateReferralCode,
  familyAlbumDownloadDeepLink,
}: {
  api: ApiClient
  advocateReferralCode: string
  familyAlbumDownloadDeepLink: string
}) => {
  const rollbar = useRollbar()
  const { transitionCode, deleteParam } = useTransitionCode()

  const [state, setState] = useState<GetAdvocateRedemptionStateResponse>({
    friendsReferredNum: 0,
    maxReferralsNum: 0,
    remainingReferralsNum: 0,
    rewardAvailableToClaim: 0,
    alreadyClaimedRedemptions: {
      runa: [],
      familyAlbum: [],
    },
    advocateReward: 0,
    referredReward: 0,
    rewardCurrencyCode: '',
  })

  const [isInvalidAdvocate, setIsInvalidAdvocate] = useState(false)
  const [error, setError] = useState('')
  const [isLoadingReferralState, setIsLoadingReferralState] = useState(true)

  const loadState = useCallback(
    async (code?: string) => {
      try {
        await api.authenticateSession()
        deleteParam()
      } catch (err) {
        if (code) {
          await api.loginWithTransitionCode(code)
          deleteParam()
        }
      }
      try {
        const response = await api.getReferralStateForAdvocateUser()
        setState(response)
        setIsLoadingReferralState(false)
        return response
      } catch (err) {
        if (err instanceof Error) {
          if (isAxiosError(err)) {
            if (err.response?.status === 403) {
              setIsInvalidAdvocate(true)
            } else {
              toast.error(err.response?.data?.message)
              setError(err.response?.data?.message)
            }
          } else {
            toast.error(err.message)
            setError(err.message)
          }
        }
      } finally {
        setIsLoadingReferralState(false)
      }
    },
    [api, rollbar, deleteParam]
  )

  useEffect(() => {
    if (transitionCode !== null) {
      loadState(transitionCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currencyFormatter = useMemo(
    () =>
      state.rewardCurrencyCode
        ? createCurrencyFormatter(state.rewardCurrencyCode)
        : (amount: number) => amount.toString(),
    [state.rewardCurrencyCode]
  )

  const { referredReward, advocateReward, maxReferralsNum } = state
  const hasClaimedAllRedemption = state.remainingReferralsNum === 0

  const messagePayload = {
    claimLink: getReferralUrl(advocateReferralCode, hasClaimedAllRedemption),
    familyAlbumDownloadDeepLink,
    referredReward: currencyFormatter(referredReward / 100),
    advocateReward: currencyFormatter(advocateReward / 100),
    maxEarnings: currencyFormatter((advocateReward * maxReferralsNum) / 100),
  }

  const {
    advocatePreMaxOutCopy,
    upToCopy,
    email,
    copyToClipboardMessage,
    shareData,
  } = getCopy(messagePayload, !hasClaimedAllRedemption)

  return {
    getClaimedFamilyAlbumRedemptionById: (redemptionId: string) => {
      const redemption = state.alreadyClaimedRedemptions.familyAlbum.find(
        (redemption) => redemption.id === parseInt(redemptionId, 10)
      )

      if (!redemption) {
        return null
      }

      return {
        formattedAmount: currencyFormatter(redemption?.value / 100 || 0),
      }
    },
    copyToClipboardMessage,
    email,
    upToCopy,
    advocatePreMaxOutCopy,
    shareData,
    claimedFamilyAlbumRedemptionsById: keyBy(
      state.alreadyClaimedRedemptions.familyAlbum,
      'id'
    ),
    state,
    isLoadingReferralState,
    error,
    referredReward: currencyFormatter(state.referredReward / 100),
    advocateReward: currencyFormatter(state.advocateReward / 100),
    isInvalidAdvocate,
    hasClaimedRedemptions:
      state.alreadyClaimedRedemptions.runa.length > 0 ||
      state.alreadyClaimedRedemptions.familyAlbum.length > 0,
  }
}

export default useAdvocateData
