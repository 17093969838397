import cx from 'classnames'
import { CSSProperties, FC, useState } from 'react'

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useInterval } from 'react-use'

const getDeadlineDiff = (deadline: Date, comparisonDate: Date) => {
  const comparisonUtc = zonedTimeToUtc(
    comparisonDate,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const diffDays = differenceInDays(deadline, comparisonUtc)
  const diffHours = differenceInHours(deadline, comparisonUtc) % 24
  const diffMinutes = differenceInMinutes(deadline, comparisonUtc) % 60
  const diffSeconds = differenceInSeconds(deadline, comparisonUtc) % 60

  return {
    diffDays,
    diffHours,
    diffMinutes,
    diffSeconds,
  }
}

const addLeadingZeros = (num: number) => (num < 10 ? `0${num}` : `${num}`)

interface TimeCardProps {
  number: number
  label: string
}

const DigitCard: FC<{ digit: number }> = ({ digit }) => (
  <span className="text-lg p-1 font-semibold text-black bg-white rounded">
    {digit}
  </span>
)

const NumberCard: FC<{ number: number }> = ({ number }) => (
  <div className="flex space-x-0.5">
    {addLeadingZeros(number)
      .toString()
      .split('')
      .map((digit, i) => (
        <DigitCard key={i} digit={parseInt(digit)} />
      ))}
  </div>
)

const TimeCard: React.FC<TimeCardProps> = ({ number, label }) => (
  <div className="px-1 py-2 rounded flex flex-col items-center space-y-2">
    <NumberCard number={number} />
    <span className="text-xs">{label}</span>
  </div>
)

const DetailedCountdown: FC<{
  deadline: Date
  prefix?: string
  variant?: 'compact' | 'cards'
  style: CSSProperties
}> = ({ deadline, prefix, variant = 'compact', style }) => {
  const [comparisonDate, setComparisonDate] = useState(new Date())
  const { diffDays, diffHours, diffMinutes, diffSeconds } = getDeadlineDiff(
    deadline,
    comparisonDate
  )
  useInterval(() => {
    setComparisonDate(new Date())
  }, 1000)

  if (diffMinutes < 0 || diffHours < 0) {
    return null
  }

  const timeUnits = [
    { number: diffDays, label: 'DAYS', shortLabel: 'd' },
    { number: diffHours, label: 'HRS', shortLabel: 'h' },
    { number: diffMinutes, label: 'MINS', shortLabel: 'm' },
    { number: diffSeconds, label: 'SECS', shortLabel: 's' },
  ]

  return (
    <div
      className={cx('text-center', {
        'flex space-x-0 mt-1 items-baseline justify-center':
          variant === 'cards',
      })}
      style={style}
    >
      {prefix && <span>{prefix}</span>}
      {timeUnits.map((unit, index) => (
        <>
          {variant === 'cards' ? (
            <>
              <TimeCard number={unit.number} label={unit.label} />
              {index < timeUnits.length - 1 && (
                <div className="font-bold">:</div>
              )}
            </>
          ) : (
            <>
              <span className="font-bold">{addLeadingZeros(unit.number)}</span>
              {unit.shortLabel}
              {index < timeUnits.length - 1 && ' '}
            </>
          )}
        </>
      ))}
    </div>
  )
}

export default DetailedCountdown
