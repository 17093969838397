import Markdown from 'react-markdown'
import useMarkdownFile from '../../hooks/useMarkdownFile'

export default function PhotoProductsTou() {
  const content = useMarkdownFile('/markdown/photo-products-tou.md')
  return (
    <div className="px-4 py-8 prose">
      <Markdown>{content}</Markdown>
    </div>
  )
}
