import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import carousel1 from '../../../assets/fathers_day/common/carousel/carousel_1.jpg'
import carousel2 from '../../../assets/fathers_day/common/carousel/carousel_2.jpg'
import carousel3 from '../../../assets/fathers_day/common/carousel/carousel_3.jpg'
import carousel4 from '../../../assets/fathers_day/common/carousel/carousel_4.jpg'
import carousel5 from '../../../assets/fathers_day/common/carousel/carousel_5.jpg'
import carousel6 from '../../../assets/fathers_day/common/carousel/carousel_6.jpg'
import carousel7 from '../../../assets/fathers_day/common/carousel/carousel_7.jpg'
import customSocks1 from '../../../assets/fathers_day/special/fathersday_lp_socks_1.png'
import customSocks2 from '../../../assets/fathers_day/special/fathersday_lp_socks_2.png'
import customSocks3 from '../../../assets/fathers_day/special/fathersday_lp_socks_3.png'
import customSocks4 from '../../../assets/fathers_day/special/fathersday_lp_socks_4.png'
import DetailedCountdown from '../../../components/DetailedCountdown'
import LinkButton from '../../../components/buttons/LinkButton'
import { C2AMetadata } from '../../../hooks/useRecordUserAction'
import Carousel from './Carousel'
import CustomSocks from './CustomSocks'
import SectionHeader from './SectionHeader'

const carouselImages = [
  carousel1,
  carousel2,
  carousel3,
  carousel4,
  carousel5,
  carousel6,
  carousel7,
]

const SocksSection: FC<{
  order: number
  deadline: Date
  backgroundClass: string
  toPath: string
  onCtaClick: (metadata: C2AMetadata) => void
}> = ({ order, deadline, backgroundClass, toPath, onCtaClick }) => {
  return (
    <section className={cx('pb-12 pt-4', backgroundClass)}>
      <SectionHeader order={order} title="Custom Photo Socks">
        Get ready to giggle with special custom photo/drawing socks - because
        every step should be a happy one!
      </SectionHeader>
      <Carousel images={carouselImages} />
      <div className="grid grid-cols-2 p-4 gap-4 mt-2">
        <CustomSocks
          src={customSocks1}
          alt="Socks 1"
          text="Custom #1 Dad Socks"
        />
        <CustomSocks
          src={customSocks2}
          alt="Socks 2"
          text="Custom Drawing Socks"
        />
        <CustomSocks
          src={customSocks3}
          alt="Socks 3"
          text="Custom #1 Grandpa Socks"
        />
        <CustomSocks
          src={customSocks4}
          alt="Socks 4"
          text="Custom #1 Uncle Socks"
        />
      </div>
      <div className="text-right mt-4 mb-6 pr-4">
        <Link
          onClick={() => {
            onCtaClick({
              clickedElement: 'LP_SEE_MORE',
              slot: order.toString(),
            })
          }}
          className="font-bold text-fa-primary leading-snug"
          to={toPath}
        >
          See more
        </Link>
      </div>
      {order === 1 ? (
        <div className="w-full text-center mb-4">
          <div className="font-bold text-base">Socks Sale ends in</div>
          <DetailedCountdown
            deadline={deadline}
            style={{
              color: '#000000',
              width: '100%',
            }}
          />
        </div>
      ) : (
        <div className="text-center mb-4 w-8/12 mx-auto">
          <p className="text-[#202020]">
            <ExclamationCircleIcon className="inline w-7 h-7 mb-1" /> Orders
            made now may be delivered after Father's Day
          </p>
        </div>
      )}
      <LinkButton
        onClick={() =>
          onCtaClick({
            clickedElement: 'LP_SECTION',
            slot: order.toString(),
          })
        }
        className="block font-bold p-2 text-center w-11/12 mx-auto mt-2 rounded-md"
        colorVariant="primary"
        to={toPath}
      >
        Order Custom Socks
      </LinkButton>
    </section>
  )
}

export default SocksSection
