import { range } from 'lodash'

export default function QuantitySelector({
  quantity,
  setQuantity,
  maxQuantity,
}: {
  quantity: number
  setQuantity: (quantity: number) => void
  maxQuantity: number
}) {
  return (
    <>
      <label>Qty:</label>
      <select
        value={quantity}
        onChange={(e) => setQuantity(Number(e.target.value))}
        className="border-[##121211F] border rounded border-solid p-0.5 transition-colors outline-fa-primary"
      >
        {range(1, maxQuantity + 1).map((i) => (
          <option key={i} value={i}>
            {i}
          </option>
        ))}
      </select>
    </>
  )
}
