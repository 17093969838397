import { Fragment, FunctionComponent, ReactNode, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import cx from 'classnames'

const Panel: FunctionComponent<{
  disableFullScreen?: boolean
  isOpen: boolean
  close: Function
  children?: ReactNode
}> = ({ isOpen, close, children, disableFullScreen }) => {
  useEffect(() => {
    if (isOpen) {
      window.document.body.classList.add('overflow-hidden')
    } else {
      window.document.body.classList.remove('overflow-hidden')
    }

    return () => {
      window.document.body.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => close()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-fa-dark bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={cx(
                'transform overflow-hidden align-middle shadow-xl transition-all',
                {
                  'h-full': disableFullScreen,
                  'w-screen h-screen': !disableFullScreen,
                }
              )}
            >
              <div className="relative h-full">{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Panel
