import { useCallback, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import Button from '../../../shared/buttons/Button'
import { CropperRef, Cropper } from 'react-mobile-cropper'
import 'react-mobile-cropper/dist/style.css'

export default function CropDialog({
  isOpen,
  close,
  confirm,
  url,
  aspect,
}: {
  isOpen: boolean
  close: () => void
  confirm: (dataUrl: string) => void
  aspect?: number
  url: string | null
}) {
  const ref = useRef<CropperRef>(null)
  const onClose = useCallback(() => {
    close()
  }, [close])

  const onConfirm = useCallback(() => {
    const base64Url = ref.current?.getCanvas()?.toDataURL()
    if (!base64Url) return
    confirm(base64Url)
  }, [confirm, ref.current])

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  return (
    <Transition show={isOpen}>
      <Dialog onClose={onClose} className="relative z-50">
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" />
        </TransitionChild>

        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 flex w-screen items-center justify-center md:p-4">
            <DialogPanel className="bg-gray-12 relative h-full w-full overflow-auto">
              <div className="mx-auto h-full flex flex-col">
                <header className="py-4 bg-white text-center font-bold text-xl">
                  Crop image
                </header>
                <Cropper
                  className="grow"
                  ref={ref}
                  src={url}
                  stencilProps={{
                    aspectRatio: aspect,
                  }}
                />
                <div className="p-2 grid grid-cols-2 gap-4 w-full bg-white">
                  <Button
                    className="rounded-lg py-2 w-full"
                    colorVariant="primary:invert"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="rounded-lg py-2 w-full"
                    colorVariant="primary"
                    onClick={onConfirm}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </TransitionChild>
      </Dialog>
    </Transition>
  )
}
