export default function AdvocateInvalid({
  supportEmail,
}: {
  supportEmail: string
}) {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <p className="text-fa-text-secondary mb-8 ml-4 mr-4">
        Thank you so much for your interest in the FamilyAlbum referral program!
        Regrettably, your account doesn't currently meet the qualifications to
        participate in our referral program.
      </p>
      <p className="text-fa-text-secondary mb-8 ml-4 mr-4">
        We're continually working to grow and improve our program, and we're
        excited about the possibility of including more users in the future.
        Your patience means a great deal to us, and we hope you'll stay tuned
        for future updates.
      </p>
      <p className="text-fa-text-secondary mb-8 ml-4 mr-4">
        Should you have any questions, thoughts, or concerns, our friendly
        support team is here for you at{' '}
        <a
          className="text-fa-primary"
          href={`mailto:${supportEmail}?subject=Inquiries [Other] []`}
        >
          {supportEmail}
        </a>
        .
      </p>
    </div>
  )
}
