import { FC } from 'react'
import AnchorButton from '../../../shared/buttons/AnchorButton'
import { useParams } from 'react-router-dom'
import postReferralIllustration from '../../../assets/post-referral-illustration.png'
import ErrorPage from '../../ErrorPage'

const RedeemLandingNoReward: FC<{
  generateReferredUserAutologinUrl: (advocateReferralCode: string) => string
}> = ({ generateReferredUserAutologinUrl }) => {
  const { advocateReferralCode } = useParams<{ advocateReferralCode: string }>()

  if (!advocateReferralCode) {
    return <ErrorPage message="Missing referral code" error="Invalid URL" />
  }

  const autologinFinalUrl =
    generateReferredUserAutologinUrl(advocateReferralCode)

  return (
    <div className="max-w-md mx-auto h-screen flex flex-col text-center pt-12">
      <div className="px-4">
        <h2 className="font-bold mb-4">Thank you for joining FamilyAlbum!</h2>
        <p className="mb-4">
          Click below to complete your referral and help your friend get a
          reward!
        </p>
        <img
          alt="Thank you"
          className="w-full mx-auto mb-4"
          src={postReferralIllustration}
        />
        <AnchorButton
          href={autologinFinalUrl}
          colorVariant="primary"
          className="w-full px-2 py-1.5 block"
        >
          Complete my referral
        </AnchorButton>
      </div>
    </div>
  )
}

export default RedeemLandingNoReward
