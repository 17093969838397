import { useSearchParams } from 'react-router-dom'
import ErrorPage from '../ErrorPage'
import Button from '../../shared/buttons/Button'

const LoopAuth = () => {
  const [params] = useSearchParams()
  const code = params.get('code')

  if (!code) {
    return (
      <ErrorPage
        error={400}
        message="Missing `code`. Please provide it as a query parameter."
      />
    )
  }

  const navigateToLoop = () => {
    window.location.assign(
      `joinloop://familyalbum?code=${code}&env=${
        import.meta.env.VITE_ENVIRONMENT
      }`
    )
  }

  return (
    <div className="text-[#212121] py-8 px-4">
      <Button colorVariant="primary" onClick={navigateToLoop}>
        Continue to Loop
      </Button>
    </div>
  )
}

export default LoopAuth
