import { XMarkIcon } from '@heroicons/react/24/solid'
import Panel from '../../shared/Panel'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'
import Button from '../../shared/buttons/Button'

export default function LogsPanel({
  logs,
  isOpen,
  close,
}: {
  logs: { [key: string]: string[] }
  isOpen: boolean
  close: () => void
}) {
  return (
    <Panel isOpen={isOpen} close={close}>
      <div className="w-screen h-screen bg-white relative overflow-auto">
        <div className="sticky top-0 flex justify-end bg-white p-4">
          <button onClick={close} className="text-blue-10 flex items-center">
            Close <XMarkIcon className="w-5" />
          </button>
        </div>
        <div className="px-4 py-8 prose">
          <CopyToClipboard
            text={JSON.stringify(logs, null, 2)}
            onCopy={() => toast.success('success')}
          >
            <Button
              colorVariant="primary"
              className="font-normal rounded-full text-base px-8 py-2 flex items-center mx-auto"
            >
              Copy!
            </Button>
          </CopyToClipboard>
          {Object.entries(logs).map(([title, content]) => (
            <pre className="p-2 text-white" key={title}>
              <h2 className="text-white">{title}</h2>
              <ul className="list-none space-y-0 px-0">
                {content.map((line, index) => (
                  <li key={index}>{line}</li>
                ))}
              </ul>
            </pre>
          ))}
        </div>
      </div>
    </Panel>
  )
}
