import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-hot-toast'
import ApiClient from '../../ApiClient'
import CopyIcon from '../../assets/copy.svg?react'
import EnvelopeIcon from '../../assets/envelope.svg?react'
import MessageBubbleIcon from '../../assets/message-bubble.svg?react'
import ReferMoreIllustration from '../../assets/post-referral-illustration.svg?react'
import AnchorButton from '../../shared/buttons/AnchorButton'
import { BaseButton } from '../../shared/buttons/Button'

interface AndroidShareInterface {
  share(title: string, text: string): void
}

const androidShareInterface = (window as any)
  .AndroidShareInterface as AndroidShareInterface

const AdvocateShareSection = ({
  api,
  copyToClipboardMessage,
  shareData,
  email,
  showReferMore = false,
}: {
  api: ApiClient
  copyToClipboardMessage: string
  shareData: ShareData
  email: string
  showReferMore?: boolean
}) => {
  const onCopyToClipboard = () => {
    api.logUserActionForAdvocate('REFERRAL_CLIPBOARD_SHARE_CLICK')
    toast.success('Link has been copied')
  }

  const onShareClick = () => {
    api.logUserActionForAdvocate('REFERRAL_MESSAGE_SHARE_CLICK')
    if (navigator.canShare && navigator.canShare(shareData)) {
      navigator.share(shareData)
    } else if (androidShareInterface) {
      const { title, text } = shareData
      if (title && text) {
        androidShareInterface.share(title, text)
      }
    }
  }

  const onEmailClick = () => {
    api.logUserActionForAdvocate('REFERRAL_EMAIL_SHARE_CLICK')
  }
  return (
    <section className="space-y-2 w-full px-4 pb-8 pt-8">
      {showReferMore && (
        <div>
          <h4 className="text-fa-text-primary text-center font-bold text-xl">
            Refer More, Spread the Joy!
          </h4>
          <ReferMoreIllustration className="mx-auto mt-4 mb-6" />
        </div>
      )}
      <CopyToClipboard text={copyToClipboardMessage} onCopy={onCopyToClipboard}>
        <BaseButton
          className="flex items-center justify-center w-full py-2 rounded-lg"
          colorVariant="primary:invert"
        >
          <CopyIcon className="mr-2.5 h-4" />
          Copy my referral link
        </BaseButton>
      </CopyToClipboard>
      <BaseButton
        onClick={onShareClick}
        className="flex items-center justify-center w-full py-2 rounded-lg"
        colorVariant="primary:invert"
      >
        <MessageBubbleIcon className="mr-2.5 h-4" />
        Share via text
      </BaseButton>
      <AnchorButton
        href={email}
        onClick={onEmailClick}
        className="flex items-center justify-center w-full py-2 rounded-lg"
        colorVariant="primary:invert"
      >
        <EnvelopeIcon className="mr-2.5 h-4" />
        Share via email
      </AnchorButton>
    </section>
  )
}

export default AdvocateShareSection
