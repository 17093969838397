import invitedGuide from '../../../assets/invited_guide_01.png'

function HowToUseYourBalance() {
  return (
    <section className="space-y-8">
      <div className="space-y-8">
        <h2 className="text-xl font-bold text-center">
          How to use your balance
        </h2>
        <ol className="list-decimal pl-4 leading-7">
          <li>Visit the Store in the FamilyAlbum app</li>
          <li>Choose products* and personalize</li>
          <li>
            At checkout, select "Gift Card Balance" as your payment method
          </li>
        </ol>
        <img src={invitedGuide} />
        <p className="text-fa-text-secondary">
          Your gift card balance can be used to purchase FamilyAlbum products*
          like Photo Prints, Photobooks, and more through the app. Don't worry,
          your balance never expires!
        </p>
      </div>

      <p className="text-s text-fa-text-secondary text-center mt-6">
        *Restrictions: Not applicable to premium subscriptions, digital
        products, or items from third-party partners.
      </p>
    </section>
  )
}

export default HowToUseYourBalance
