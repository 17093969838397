import { FC, useCallback, useState } from 'react'
import { Product, Variant } from '../../clients/storefront/types'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import VariantOption from './components/VariantOption'

const LoopStoreProducts: FC<{
  product: Product | null
  selectedVariant: Variant | null
  handleSelectVariant: (variant: Variant) => void
  handleAddToCart: () => void
}> = ({ product, selectedVariant, handleSelectVariant, handleAddToCart }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const goToSlide = useCallback((index: number) => {
    setCurrentSlide(index)
  }, [])

  const addToCart = useCallback(async () => {
    setIsAddingToCart(true)
    handleAddToCart()
  }, [handleAddToCart])

  const slides = [
    '//loopfamily.com/cdn/shop/products/textphototoframer_360x.png?v=1669593868 360w, //loopfamily.com/cdn/shop/products/textphototoframer_540x.png?v=1669593868 540w, //loopfamily.com/cdn/shop/products/textphototoframer_720x.png?v=1669593868 720w, //loopfamily.com/cdn/shop/products/textphototoframer_900x.png?v=1669593868 900w, //loopfamily.com/cdn/shop/products/textphototoframer_1080x.png?v=1669593868 1080w',
    '//loopfamily.com/cdn/shop/products/UsetheLoopappforiOS_Android_360x.png?v=1669599988 360w, //loopfamily.com/cdn/shop/products/UsetheLoopappforiOS_Android_540x.png?v=1669599988 540w, //loopfamily.com/cdn/shop/products/UsetheLoopappforiOS_Android_720x.png?v=1669599988 720w, //loopfamily.com/cdn/shop/products/UsetheLoopappforiOS_Android_900x.png?v=1669599988 900w, //loopfamily.com/cdn/shop/products/UsetheLoopappforiOS_Android_1080x.png?v=1669599988 1080w',
    '//loopfamily.com/cdn/shop/products/Credenza_eb3dc2f4-7695-497b-b820-c660e6bab3e7_360x.jpg?v=1669599988 360w, //loopfamily.com/cdn/shop/products/Credenza_eb3dc2f4-7695-497b-b820-c660e6bab3e7_540x.jpg?v=1669599988 540w, //loopfamily.com/cdn/shop/products/Credenza_eb3dc2f4-7695-497b-b820-c660e6bab3e7_720x.jpg?v=1669599988 720w, //loopfamily.com/cdn/shop/products/Credenza_eb3dc2f4-7695-497b-b820-c660e6bab3e7_900x.jpg?v=1669599988 900w, //loopfamily.com/cdn/shop/products/Credenza_eb3dc2f4-7695-497b-b820-c660e6bab3e7_1080x.jpg?v=1669599988 1080w',
    '//loopfamily.com/cdn/shop/products/SideOrtho_ec063290-bc65-45ec-b192-561fdb8a60dd_360x.jpg?v=1669599988 360w, //loopfamily.com/cdn/shop/products/SideOrtho_ec063290-bc65-45ec-b192-561fdb8a60dd_540x.jpg?v=1669599988 540w, //loopfamily.com/cdn/shop/products/SideOrtho_ec063290-bc65-45ec-b192-561fdb8a60dd_720x.jpg?v=1669599988 720w, //loopfamily.com/cdn/shop/products/SideOrtho_ec063290-bc65-45ec-b192-561fdb8a60dd_900x.jpg?v=1669599988 900w, //loopfamily.com/cdn/shop/products/SideOrtho_ec063290-bc65-45ec-b192-561fdb8a60dd_1080x.jpg?v=1669599988 1080w',
    '//loopfamily.com/cdn/shop/products/Backangle02_8aa5a506-8da5-45dd-b533-c71e7de5648b_360x.jpg?v=1669599988 360w, //loopfamily.com/cdn/shop/products/Backangle02_8aa5a506-8da5-45dd-b533-c71e7de5648b_540x.jpg?v=1669599988 540w, //loopfamily.com/cdn/shop/products/Backangle02_8aa5a506-8da5-45dd-b533-c71e7de5648b_720x.jpg?v=1669599988 720w, //loopfamily.com/cdn/shop/products/Backangle02_8aa5a506-8da5-45dd-b533-c71e7de5648b_900x.jpg?v=1669599988 900w, //loopfamily.com/cdn/shop/products/Backangle02_8aa5a506-8da5-45dd-b533-c71e7de5648b_1080x.jpg?v=1669599988 1080w',
    '//loopfamily.com/cdn/shop/products/Frontangle02_aa1156c7-9d81-4288-8fd4-67dbd4d7d6c7_360x.jpg?v=1669599988 360w, //loopfamily.com/cdn/shop/products/Frontangle02_aa1156c7-9d81-4288-8fd4-67dbd4d7d6c7_540x.jpg?v=1669599988 540w, //loopfamily.com/cdn/shop/products/Frontangle02_aa1156c7-9d81-4288-8fd4-67dbd4d7d6c7_720x.jpg?v=1669599988 720w, //loopfamily.com/cdn/shop/products/Frontangle02_aa1156c7-9d81-4288-8fd4-67dbd4d7d6c7_900x.jpg?v=1669599988 900w, //loopfamily.com/cdn/shop/products/Frontangle02_aa1156c7-9d81-4288-8fd4-67dbd4d7d6c7_1080x.jpg?v=1669599988 1080w',
  ]

  const faqData = [
    {
      question: 'Do I need more than one Loop to work?',
      answer: 'No! You only need one Loop to have a great experience.',
    },
    {
      question: 'What is included in the box?',
      answer: 'The Loop frame, stand, power cord, and instructions.',
    },
    {
      question:
        'Can multiple people send photos and video to one Loop display?',
      answer:
        "Yes! You can invite as many people as you'd like to share content to your Loop. You can create different Channels and invite different people to each one. All they need to get started is the free Loop app.",
    },
    {
      question: 'Is an internet connection required?',
      answer:
        'Loop requires wi-fi to receive new photos, play videos or video chat, but you can still view photos without a wi-fi connection.',
    },
    {
      question: 'Does Loop play videos?',
      answer:
        "Yes, we love videos and believe they're the best way to share memories. Loop supports all popular video formats - if it can play on your phone or tablet, it can play on Loop!",
    },
    {
      question: 'What are the product dimensions?',
      answer: '10.75 in x 7.75 in x 0.75 - 10" High resolution display',
    },
  ]

  return (
    <>
      <Navbar showStoreIcon={false} showCartIcon={true} />
      <section>
        <div className="max-w-lg mx-auto p-2">
          <div className="bg-white">
            <div>
              {slides.map((slide, index) => (
                <div
                  key={slide}
                  className={`duration-300 ease-in-out ${
                    index === currentSlide ? 'block' : 'hidden'
                  }`}
                >
                  <div className="p-6">
                    <img src={slide} className="w-full" />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center p-4 space-x-2 overflow-hidden">
              {slides.map((_, index) => (
                <img
                  key={index}
                  onClick={() => goToSlide(index)}
                  srcSet={slides[index]}
                  className={`max-h-12 max-w-12 ${
                    index === currentSlide ? 'border-2 border-black' : ''
                  }`}
                ></img>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="sm:w-10/12 md:w-8/12 lg:w-6/12 mx-auto">
        {product && (
          <div className="p-4">
            <h1 className="text-loop-text-store-primary font-bold text-3xl">
              {product.title}
            </h1>
            <hr className="mx-auto my-[25px]" />
            {product.variants.map((variant) => (
              <VariantOption
                key={variant.id}
                variant={variant}
                selectedVariant={selectedVariant}
                handleSelectVariant={handleSelectVariant}
              />
            ))}
            <button
              onClick={addToCart}
              disabled={isAddingToCart}
              className="w-full font-bold text-lg mt-4 px-0 py-5 rounded-md text-white bg-loop-text-store-primary"
            >
              Add to Cart
            </button>
          </div>
        )}
      </section>
      <section>
        <div className="p-4 max-w-2xl mx-auto">
          <div className="space-y-6 text-base font-semibold text-[#21719e]">
            <p>🚀 Fast and Free Shipping in 2 days.</p>
            <p>
              👍 100% Satisfaction Guarantee. Fast and easy returns within 100
              days.
            </p>
            <div className="text-[#2b4353]">
              <p className="font-bold text-base mb-2">
                Loop is the easiest way to share pictures with your family and
                friends to keep everyone connected. Instantly and easily share
                photos with our app to Loop’s gorgeous 10” touch screen, no
                matter where you are.
              </p>
              <ul className="list-disc pl-16 space-y-1 text-sm mt-6 font-light">
                <li>Wifi Connected</li>
                <li>16GB storage</li>
                <li>Send pictures from your phone directly to Loop</li>
                <li>10" high-resolution IPS display</li>
                <li>
                  Invite family to share with the free app (iPhone and Android)
                </li>
                <li>Sets up in 2 minutes</li>
                <li>Plays videos and pictures</li>
                <li>Makes the perfect gift</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="sm:w-10/12 md:w-8/12 lg:w-6/12 mx-auto mt-8">
        <div className="p-4 mx-auto">
          <h1 className="text-loop-text-store-primary font-bold text-3xl mb-8">
            How It Works
          </h1>
          <div className="iframe-wrapper w-full h-72 md:h-96">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/NMzBYkAAo14?modestbranding=1&controls=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
              allowFullScreen
            />{' '}
          </div>
        </div>
      </section>
      <section className="sm:w-10/12 md:w-8/12 lg:w-6/12 mx-auto mt-8">
        <div className="p-4 mx-auto text-loop-text-store-primary">
          <h1 className="font-bold text-3xl mb-8">FAQs</h1>
          <div className="mx-auto">
            {faqData.map((faq) => (
              <div key={faq.question} className="mb-6">
                <h4 className="font-semibold text-lg">{faq.question}</h4>
                <span>{faq.answer}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default LoopStoreProducts
