import { FunctionComponent } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import cx from 'classnames'
import { ButtonVariant, buttonVariants } from './Button'

const LinkButton: FunctionComponent<
  LinkProps & { colorVariant: ButtonVariant }
> = ({ children, className, colorVariant, ...props }) => (
  <Link
    {...props}
    className={cx(
      'font-bold text-base rounded tracking-tight',
      buttonVariants[colorVariant],
      className
    )}
  >
    {children}
  </Link>
)

export default LinkButton
