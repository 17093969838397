import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { FC, useCallback, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import ApiClient from '../../ApiClient'
import carousel1 from '../../assets/grandparents_day/common/carousel/carousel_1.jpg'
import carousel2 from '../../assets/grandparents_day/common/carousel/carousel_2.jpg'
import carousel3 from '../../assets/grandparents_day/common/carousel/carousel_3.jpg'
import carousel4 from '../../assets/grandparents_day/common/carousel/carousel_4.jpg'
import carousel5 from '../../assets/grandparents_day/common/carousel/carousel_5.jpg'
import carousel6 from '../../assets/grandparents_day/common/carousel/carousel_6.jpg'
import carousel7 from '../../assets/grandparents_day/common/carousel/carousel_7.jpg'
import DivvyUpHeaderTitle from '../../assets/grandparents_day/divvy_up/divvyup_lp_header_title.svg?react'
import FaDivvyUpLogos from '../../assets/grandparents_day/divvy_up/fa_divvyup_logos.svg?react'
import header from '../../assets/grandparents_day/divvy_up/header.jpg'
import howItWorks1 from '../../assets/grandparents_day/divvy_up/how_it_works_1.png'
import howItWorks2 from '../../assets/grandparents_day/divvy_up/how_it_works_2.png'
import howItWorks3 from '../../assets/grandparents_day/divvy_up/how_it_works_3.png'
import customSocks1 from '../../assets/grandparents_day/common/socks/socks_1_5usd_off.png'
import customSocks2 from '../../assets/grandparents_day/common/socks/socks_2_5usd_off.png'
import customSocks3 from '../../assets/grandparents_day/common/socks/socks_3_5usd_off.png'
import customSocks4 from '../../assets/grandparents_day/common/socks/socks_4_5usd_off.png'
import DetailedCountdown from '../../components/DetailedCountdown'
import FaqItem from '../../components/FaqItem'
import FooterDisclaimer from '../../components/FooterDisclaimer'
import StickyFooter from '../../components/StickyFooter'
import LinkButton from '../../components/buttons/LinkButton'
import useRecordUserAction, {
  C2AMetadata,
} from '../../hooks/useRecordUserAction'
import Carousel from './components/Carousel'
import CouponCodeSection from './components/CouponCodeSection'
import CustomSocks from './components/CustomSocks'
import HowItWorksItem from './components/HowItWorksItem'
import WhyHeWillLoveItItem from './components/WhyHeWillLoveItItem'

const faqItems = [
  {
    question: 'Do I have to crop my own image when uploading?',
    answer:
      'Nope! Simply upload a picture with good consistent lighting, decent resolution, and the entire subject/face inside the frame of the photo. DivvyUp’s designers will handle the rest. If a person in the picture or your pet is wearing an accessory or outfit, please let their design team know if you would like it included (or excluded) in the order notes at checkout.',
  },
  {
    question: 'Can I get more than 1 face on a pair of socks?',
    answer:
      'You sure can! DivvyUp allows up to 3 unique faces/subjects on most designs.',
  },
  {
    question: 'Can I select a photo from FamilyAlbum?',
    answer:
      'Unfortunately, you can’t select a photo from FamilyAlbum directly. If your album has download permission, then open the Album tab and tap a photo to download it to your device. You can then upload it when placing an order on the DivvyUp website.',
  },
  {
    question: 'How long does it take to get my socks?',
    answer:
      'In the USA, shipping via Flat Rate (USPS) takes 3 to 5 days. You also have the option to choose FedEx for Expedited shipping. Shipping time will be calculated at checkout.',
  },
  {
    question: 'Can I ship internationally?',
    answer:
      "Yes! DivvyUp ships internationally, but please note the shipping period will be longer than US domestic shipping, and an extra import fee might be required depending on the recipient's region.",
  },
  {
    question: 'What is the return policy?',
    answer:
      'Hopefully, you immediately fall in love with your custom socks. But if for some reason there is an issue or concern, we accept all returns/exchanges. Ultimately, we want to ensure you’re completely satisfied with your purchase, and we’re happy to help any way we can! Please reach out to the DivvyUp team at team@divvyupsocks.com.',
  },
]

const whyHeWillLoveItItems = [
  {
    title: '💓 Joy Every Step of the Way',
    text: "Whether it's your baby's adorable face or your child's playful drawings, these socks bring daily joy and warmth, sparking conversations wherever they go.",
  },
  {
    title: '🎁 The Gift of Cherished Memories',
    text: 'Turn precious moments into wearable one-of-a-kind keepsakes with each unique pair, capturing the love shared with your family.',
  },
  {
    title: '💫 Comfort with Style',
    text: 'Beyond sentiment, these socks offer everyday comfort and effortless style, perfect for anything from a lazy Sunday to a special event.',
  },
]

const howItWorksItems = [
  {
    src: howItWorks1,
    title: '1. Choose Your Design',
    text: "Explore our partner's website to select your favorite custom socks design",
    className: 'w-[187px]',
  },
  {
    src: howItWorks2,
    title: '2. Customize Your Socks',
    text: 'Upload your photo, select the base color, and choose the size. Add a gift box for an extra special touch!',
    className: 'w-[155.57px]',
  },
  {
    src: howItWorks3,
    title: '3. Checkout & Spread Joy',
    text: 'Complete checkout and make your loved ones smile with this heartfelt gift',
    className: 'w-[115.51px]',
  },
]

const customSocksItems = [
  {
    src: customSocks1,
  },
  {
    src: customSocks2,
  },
  {
    src: customSocks3,
  },
  {
    src: customSocks4,
  },
]

const carouselImages = [
  carousel1,
  carousel2,
  carousel3,
  carousel4,
  carousel5,
  carousel6,
  carousel7,
]

const SALE_DEADLINE_DATE = '2024-08-27T23:59:59-07:00' // August 27th, 2024 23:59:59 PDT

const DivvyUpGrandparentsDay: FC<{
  api: ApiClient
  supportEmail: string
}> = ({ api, supportEmail }) => {
  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')
  const { recordDidTapSocksC2A, recordDidCopySocksPromoCodeTap } =
    useRecordUserAction(api)

  const now = new Date()
  const saleDeadline = new Date(SALE_DEADLINE_DATE)
  const isSaleActive = now.getTime() < saleDeadline.getTime()

  const onCtaClick = useCallback(async (metadata: C2AMetadata) => {
    recordDidTapSocksC2A(metadata)
  }, [])

  const COUPONS = [
    {
      code: 'FAMALBUM1',
      validFrom: '2024-08-15T00:00:00-07:00', // August 15th, 2024 00:00:00 PDT
      validTo: '2024-08-22T23:59:59-07:00', // August 22nd, 2024 23:59:59 PDT
    },
    {
      code: 'FAMALBUM2',
      validFrom: '2024-08-23T00:00:00-07:00', // August 23rd, 2024 00:00:00 PDT
      validTo: '2024-08-28T23:59:59-07:00', // August 28th, 2024 23:59:59 PDT
    },
    {
      code: 'FAMALBUM3',
      validFrom: '2024-08-29T00:00:00-07:00', // August 29th, 2024 00:00:00 PDT
      validTo: '2024-09-04T23:59:59-07:00', // September 4th, 2024 23:59:59 PDT
    },
    {
      code: 'FAMALBUM4',
      validFrom: '2024-09-05T00:00:00-07:00', // September 5th, 2024 00:00:00 PDT
      validTo: '2024-09-10T23:59:59-07:00', // September 10th, 2024 23:59:59 PDT
    },
  ]
  const couponCode = COUPONS.find((coupon) => {
    return new Date(coupon.validFrom) <= now && now <= new Date(coupon.validTo)
  })?.code

  const divvyUpStoreLink = useMemo(() => {
    const couponParam = couponCode ? `&apply_coupon=${couponCode}` : ''
    return `https://www.divvyupsocks.com/?fa_user_id=${uuid}&utm_source=familyalbum&utm_medium=affiliate&${couponParam}`
  }, [couponCode, uuid])

  const onCopy = useCallback(() => {
    recordDidCopySocksPromoCodeTap()
    toast.success('Copied!')
  }, [])

  return (
    <div className="max-w-lg mx-auto">
      <section className="bg-[#B2DDB5] relative overflow-hidden min-h-[450px]">
        <div
          className="bg-no-repeat bg-cover absolute top-0 left-0 right-0 h-96"
          style={{
            backgroundImage: `url(${header})`,
          }}
        ></div>
        <div className="bg-white w-11/12 mx-auto rounded-2xl py-8 absolute bottom-0 left-0 right-0">
          <DivvyUpHeaderTitle className="w-full h-auto" />
        </div>
      </section>
      <section className="bg-[#B2DDB5]">
        {isSaleActive ? (
          <div className="w-full text-[#202020] text-center p-3">
            <div className="font-bold">Sale ends in</div>
            <DetailedCountdown
              deadline={saleDeadline}
              variant="cards"
              style={{
                color: '#202020',
                width: '100%',
              }}
            />
          </div>
        ) : (
          <div className="w-full text-[#202020] text-center p-6 font-bold">
            <ExclamationCircleIcon className="inline w-7 h-7 mb-1" /> Orders
            made now may be <br /> delivered after Grandparents’ Day
          </div>
        )}
      </section>
      <CouponCodeSection couponCode={couponCode} onCopy={onCopy} />
      <section className="my-8">
        <h3 className="text-center font-semibold text-lg mb-4">
          Various designs & colors available
        </h3>
        <div className="grid grid-cols-2 gap-4 p-1 mt-2 mx-4">
          {customSocksItems.map((item, index) => (
            <CustomSocks key={index} src={item.src} alt="" />
          ))}
        </div>
        <div className="text-right mt-6 pr-4">
          <Link
            onClick={() => {
              onCtaClick({
                clickedElement: 'LP_SEE_MORE',
              })
            }}
            className="font-bold text-fa-primary leading-snug"
            to={divvyUpStoreLink}
          >
            See more designs at DivvyUp
          </Link>
        </div>
      </section>
      <section className="px-4 py-12 text-center space-y-8">
        <FaDivvyUpLogos className="mx-auto mb-2" />
        <h2 className="font-semibold text-3xl text-center">How It Works</h2>
        <p className="px-8">
          With just a few clicks, you can create an unforgettable Grandparents’
          Day surprise!
        </p>
        <div className="grid grid-cols-1 gap-8 my-8 px-2">
          {howItWorksItems.map((item) => (
            <HowItWorksItem
              key={item.title}
              src={item.src}
              title={item.title}
              text={item.text}
              className={item.className}
            />
          ))}
        </div>
      </section>
      <CouponCodeSection couponCode={couponCode} onCopy={onCopy} />
      <section className="bg-[#F5FBF5] py-12">
        <h2 className="font-semibold text-3xl text-center">
          Why They'll Love It!
        </h2>
        <ul className="grid grid-cols-1 gap-8 my-8 px-4">
          {whyHeWillLoveItItems.map((item) => (
            <WhyHeWillLoveItItem
              key={item.title}
              title={item.title}
              text={item.text}
            />
          ))}
        </ul>
        <Carousel images={carouselImages} />
      </section>
      <section className="px-4 py-12 bg-white">
        <h2 className="font-bold text-2xl mb-4 text-center">FAQ</h2>
        <div className="text-fa-text-secondary space-y-8">
          {faqItems.map((item) => (
            <FaqItem
              key={item.question}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </section>
      <footer className="px-4 pb-8 mt-12">
        <p>
          Have questions? Contact us at <br />
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </p>
        <FooterDisclaimer />
      </footer>
      <StickyFooter>
        <LinkButton
          onClick={() => {
            onCtaClick({
              clickedElement: 'LP_FOOTER_CTA',
            })
          }}
          className="block p-2"
          colorVariant="primary"
          to={divvyUpStoreLink}
        >
          Get started
        </LinkButton>
      </StickyFooter>
    </div>
  )
}

export default DivvyUpGrandparentsDay
