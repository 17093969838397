import cx from 'classnames'

const FeatureBlock = ({
  imgSrc,
  title,
  content,
  index,
}: {
  imgSrc: string
  title: string
  content: string
  index: number
}) => {
  const isOdd = index % 2 === 1

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 items-center mx-auto">
      <div
        className={cx('w-full h-auto mb-8 overflow-hidden drop-shadow-md', {
          'lg:order-2': isOdd,
          'lg:order-1': !isOdd,
        })}
      >
        <img
          className="transform transition-transform duration-500 hover:scale-110 md:w-full"
          src={imgSrc}
          alt={title}
        />
      </div>
      <div
        className={cx('w-80 lg:w-full lg:p-12', {
          'lg:order-1': isOdd,
          'lg:order-2': !isOdd,
        })}
      >
        <h3 className="text-left text-3xl font-bold mb-4">{title}</h3>
        <p className="text-left leading-5 text-lg mb-12">{content}</p>
      </div>
    </div>
  )
}

export default FeatureBlock
