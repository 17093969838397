import { ChangeEventHandler, useCallback, useState } from 'react'
import cx from 'classnames'
import ApiClient from '../../../ApiClient'
import Button from '../../../shared/buttons/Button'
import FamilyAlbumPhotosSelectionPanel from '../FamilyAlbumPhotoSelectionPanel'
import PhotoUploadButton from '../FamilyAlbumPhotoSelectionPanel/PhotoUploadButton'
import { isDeviceAndroid } from '../../../utils'

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      if (!fileReader.result) {
        reject('FileReader result is empty')
        return
      }
      resolve(fileReader.result?.toString())
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export default function PhotoSelector({
  onPhotoSelected,
  api,
}: {
  onPhotoSelected: (base64: string) => void
  api: ApiClient
}) {
  const [isPanelOpen, setIsPanelOpen] = useState(false)

  const openFamilyAlbumPanel = useCallback(() => {
    setIsPanelOpen(true)
  }, [])

  const closeFamilyAlbumPanel = useCallback(() => {
    setIsPanelOpen(false)
  }, [])

  const onPhotoSelectedFromFamilyAlbum = useCallback(
    async (photo: { url: string; uuid: string }) => {
      return onPhotoSelected(photo.url)
    },
    [onPhotoSelected]
  )

  const onPhotoSelectedFromDevice: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      async (event) => {
        if (!event.target.files || !event.target.files[0]) {
          return
        }

        const base64Original = await convertFileToBase64(event.target.files[0])
        return onPhotoSelected(base64Original)
      },
      [onPhotoSelected]
    )

  const showDeviceUpload = !isDeviceAndroid()

  return (
    <>
      <div
        className={cx('grid gap-2', {
          'grid-cols-1': !showDeviceUpload,
          'grid-cols-2': showDeviceUpload,
        })}
      >
        {showDeviceUpload && (
          <PhotoUploadButton
            colorVariant="primary:invert"
            className="text-s"
            onFilesSelected={onPhotoSelectedFromDevice}
          >
            From Device
          </PhotoUploadButton>
        )}

        <Button
          colorVariant="primary:invert"
          className="text-s"
          onClick={openFamilyAlbumPanel}
        >
          From FamilyAlbum
        </Button>
      </div>
      <FamilyAlbumPhotosSelectionPanel
        api={api}
        isOpen={isPanelOpen}
        onClose={closeFamilyAlbumPanel}
        next={onPhotoSelectedFromFamilyAlbum}
      />
    </>
  )
}
