import { ReactNode } from 'react'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

const FaqItem = ({
  question,
  answer,
}: {
  question: string
  answer: string | ReactNode
}) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <DisclosureButton className="text-left w-full flex justify-between items-center mb-4">
            <div>{question}</div>
            <div className="text-right">
              {open ? (
                <ChevronUpIcon className="w-6" />
              ) : (
                <ChevronDownIcon className="w-6" />
              )}
            </div>
          </DisclosureButton>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <DisclosurePanel>{answer}</DisclosurePanel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export default FaqItem
