import { FC } from 'react'

const Carousel: FC<{ images: string[] }> = ({ images }) => {
  return (
    <div className="relative overflow-hidden">
      <div className="flex space-x-2 animate-slide pl-2">
        {images.map((image) => (
          <img
            key={image}
            src={image}
            alt="Carousel"
            className="w-36 rounded-lg"
          />
        ))}
      </div>
    </div>
  )
}

export default Carousel
