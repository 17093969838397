import { useEffect, useMemo } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import CheckoutProgressBar from '../components/CheckoutProgressBar'
import { GetPrintifyOrderFunction } from '../../../clients/fagl-server/types/printify'
import LoadingOverlay from '../components/LoadingOverlay'

export default function Checkout({
  isGettingOrder,
  loadOrder,
  isOrderLoaded,
  navigateBackToCart,
}: {
  isGettingOrder: boolean
  isOrderLoaded: boolean
  loadOrder: GetPrintifyOrderFunction
  navigateBackToCart: (orderId: string) => void
}) {
  const { orderId } = useParams<{ orderId: string }>()
  const location = useLocation()

  const activeStepNumber = useMemo(() => {
    const isCheckoutPage = location.pathname.includes('checkout')
    if (isCheckoutPage) {
      return 2
    }

    const isSuccessPage = location.pathname.includes('success')
    if (isSuccessPage) {
      return 3
    }

    return 1
  }, [location])

  useEffect(() => {
    if (!isOrderLoaded && orderId) {
      loadOrder(orderId)
    }
  }, [orderId, isOrderLoaded])

  if (isGettingOrder) {
    return <LoadingOverlay>Loading order information</LoadingOverlay>
  }

  return (
    <div className="h-screen bg-gray-2 relative flex flex-col">
      <div className="shrink-0 grow-0 py-4">
        <CheckoutProgressBar
          onBackClick={
            activeStepNumber !== 2
              ? undefined
              : () => navigateBackToCart(orderId as string)
          }
          orderedSteps={['Cart', 'Payment', 'Complete']}
          activeStepNumber={activeStepNumber}
        />
      </div>
      <Outlet />
    </div>
  )
}
