import { FC, useCallback, useState } from 'react'
import StickyFooter from '../../components/StickyFooter'
import FAQ from './components/FAQ'
import FeatureBlock from './components/FeatureBlock'
import FramePlaces from './components/FramePlaces'
import SharingFeatureBlock from './components/SharingFeatureBlock'

const LoopStoreLanding: FC<{
  onBuyNow: () => void
}> = ({ onBuyNow }) => {
  const [openFAQ, setOpenFAQ] = useState<number | null>(null)

  const handleBuyNow = useCallback(() => {
    onBuyNow()
  }, [onBuyNow])

  const toggleFAQ = useCallback(
    (index: number) => {
      setOpenFAQ(openFAQ === index ? null : index)
    },
    [openFAQ, setOpenFAQ]
  )

  const sharingFeatures = [
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/9a5075bd-loop-feature-invite.svg',
      altText: 'Heart icon representing invitation feature',
      title: 'Grandparent Approved',
      paragraphs: [
        "Thousands of families and grandparents love Loop frame because it's simple to setup and easy to use.",
        'No complicated app and tech know how required to view and share photos!',
      ],
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/01556fc4-loop-feature-connect-familyalbum.svg',
      altText: 'Icon representing connection to FamilyAlbum',
      title: 'Connect Your Frame to FamilyAlbum',
      paragraphs: [
        'Your favorited photos and videos from FamilyAlbum displayed in your home',
      ],
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/6fc7f695-loop-feature-frame.svg',
      altText: 'Icon representing the digital frame',
      title: 'Vivid Memories, Perfectly Displayed',
      paragraphs: [
        "Our Digital Frame uses cutting-edge IPS technology to breathe life into your family photos. You'll see every smile, every laugh, every precious moment in stunningly vibrant color and detail.",
        "It's not just a frame, it's a window into your treasured memories.",
      ],
    },
  ]

  const places = [
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/10995603-loop-memories-room-entryway_108y08c08808c00c000000.jpg',
      title: 'Entryway',
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/d66abfee-loop-memories-room-office_108y08c08808c00c000000.jpg',
      title: 'Office',
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/e1ef7fec-loop-memories-room-living-room_108y08c08808c00c000000.jpg',
      title: 'Living Room',
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/aaf92261-loop-memories-room-bedroom_108y08c08808c00c000000.jpg',
      title: 'Bedroom',
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/4ece3200-loop-memories-room-bathroom_108y08c08808c00c000000.jpg',
      title: 'Bathroom',
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/453482f4-loop-memories-room-kitchen_108y08c08808c00c000000.jpg',
      title: 'Kitchen',
    },
  ]

  const features = [
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/eaac6f65-loop-image-setup-minute_108g04s000000000000000.jpg',
      altText: 'Happy Elderly Couple',
      title: 'Sets up in 1 minute',
      content:
        'Just plug in Loop, connect it to Wi-Fi and start adding photos straight from your phone - it’s that simple.',
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/b4dc92ae-loop-image-map_108g04s000000000000000.jpg',
      title: 'Everyone in the family can send photos to Loop',
      content:
        'Make your Loop experience even more special by connecting the entire family. Invite them with the app so they can add photos and see what’s being shared.',
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/cd17f96f-loop-image-phone_108g04s000000000000000.jpg',
      title: 'Text photos to Loop and they show up instantly',
      content:
        'Text message photos to your Loop phone number and they’ll go straight to your frame. You can also use the app to send photos as well!',
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/88086e19-loop-image-touch_108g04s000000000000000.jpg',
      title: 'Easy to use touchscreen',
      content:
        'Sit back and let Loop rotate through photos all by itself or use the touch screen to swipe to the next one - there’s no need for a remote control or awkward buttons on the back of the frame.',
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/8891a93d-loop-image-device-front-babe_108g04s000000000000000.jpg',
      title: 'Tap "like" on your favorite photos',
      content:
        "Love a new photo that just came in? Simply tap on the heart button on Loop's touchscreen to let the sender know.",
    },
    {
      imgSrc:
        '//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/0feebbff-loop-image-phone2_108g04s000000000000000.jpg',
      title: 'Get notified when they see your photos',
      content:
        "After someone 'likes' a photo, you get a notification so you know they saw it !",
    },
  ]

  const faqData = [
    {
      question: 'How is Loop different than other frames?',
      answer:
        'Instead of white-labeling another manufacture’s frame like many others, we’ve spent years designing Loop from scratch to create a quality product. Loop features a high end custom IPS screen so your pictures look great, and a contemporary design meant to look good from every angle. We’ve also put a lot of care into our mobile app because we believe it’s just as important as the frame itself.',
    },
    {
      question: 'Is Loop easy to use for people that aren’t tech savvy?',
      answer:
        'Loop was designed specifically with non-tech savvy people in mind. You can set up your frame for a completely hands-free experience. The frame can be controlled and customized remotely from anywhere with the app so the gift recipient never has to worry. There’s also a super simple touch screen interface for customizing Loop on the frame directly as well.',
    },
    {
      question: 'How do I setup my frame',
      answer:
        'You can send photos by text messaging them to your Loop phone number, uploading them with the app, or choosing an Instagram, Facebook or Google Photos album to sync with. There’s multiple ways to add photos so you can decide what’s easiest for you!',
    },
    {
      question: 'How do I send photos to Loop',
      answer:
        'Loop requires wi-fi to receive new photos, play videos or video chat, but you can still view photos without a wi-fi connection.',
    },
    {
      question: 'Can others send photos to Loop?',
      answer:
        'Yes absolutely! Everyone in the family can download the free app and connect to your frame(s). Once connected they can share photos, view what other family members are sharing, and comment on photos.',
    },
    {
      question: 'Do other family members need a Loop frame to participate?',
      answer:
        'No, we designed the Loop app to be a wonderful experience on its own. Family members can see everything that’s happening with the app and are notified about the latest updates.',
    },
    {
      question: 'Can I send photos to multiple frames at once?',
      answer:
        'Yes, simply send the photo to whichever album you’d like and if you’ve set up that album to go to multiple frames then the photo will show up in multiple frames at once.',
    },
    {
      question: 'Can I setup Loop before gifting it to someone?',
      answer:
        'Many people love to buy Loop as a gift for a love one so we’ve tried to make this as easy as possible. We recommend setting your Loop up and pre-loading it with photos for the best experience. If you’re mailing the Loop you can also pre-program the gift recipient’s Wi-Fi information so all they need to do is plug it in for the ultimate hassle free setup.',
    },
    {
      question: 'How do I connect Loop to FamilyAlbum?',
      answer:
        'When you’re setting up your Loop frame you’ll have the opportunity to connect it to your FamilyAlbum account. All the photos you’ve favorited will be automatically synced to Loop and displayed on the frame.',
    },
  ]

  return (
    <>
      <div className="bg-white text-center">
        <header className="bg-loop-bg-orange pb-10">
          <div className="px-8 sm:px-16 py-2 md:w-11/12 md:mx-auto">
            <div className="flex justify-between items-center mb-4">
              <div>
                <img
                  className="w-12 h-12"
                  src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/b8652cb5-icon-loop_101e01e000000000000028.png"
                  alt="Loop"
                  srcSet="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/b8652cb5-icon-loop_101e01e000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/b8652cb5-icon-loop_102s02s000000000000028.png 2x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/b8652cb5-icon-loop_1000000000000000000028.png 3x"
                  sizes="(max-width: 600px) 100vw, 600px"
                />
              </div>
              <div>
                <img
                  className="w-40 md:w-52 lg:w-60"
                  src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/6885983f-familyalbum-logo-horizontal.svg"
                  alt="FamilyAlbum"
                  data-src-desktop-1x="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/6885983f-familyalbum-logo-horizontal.svg"
                  data-src-mobile-1x="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/6885983f-familyalbum-logo-horizontal.svg"
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 items-center">
              <div className="md:order-2">
                <video
                  controls
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="w-full mb-10"
                >
                  <source
                    src="https://d35gjrncmn3isp.cloudfront.net/Loop+Homepage+VB+3.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="md:order-1">
                <div className="flex justify-center items-center mb-4">
                  <img
                    className="w-40 md:w-52 lg:w-60"
                    src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/91922be6-loop-home-5-stars.svg"
                    alt="5 stars rating"
                  />
                </div>
                <p className="text-loop-text-primary text-base leading-5 lg:text-xl">
                  The ONLY digital photo frame <br /> compatible with
                  FamilyAlbum
                </p>
              </div>
            </div>
          </div>
        </header>
        <section className="bg-white pt-10 mb-8">
          <div className="w-80 md:w-8/12 mx-auto py-2">
            <h3 className="font-linguisticsProBold text-loop-primary text-3xl font-semibold mb-10">
              As Featured In
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-5 justify-items-center gap-y-6">
              <img
                src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/1c1ec113-loop-featured-in-forbes_103c01b000000000000028.png"
                alt="Forbes"
                srcSet="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/1c1ec113-loop-featured-in-forbes_103c01b000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/1c1ec113-loop-featured-in-forbes_106o02m000000000000028.png 2x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/1c1ec113-loop-featured-in-forbes_1000000000000000000028.png 3x"
                sizes="(max-width: 600px) 100vw, 600px"
                loading="lazy"
              />
              <img
                src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/aa733e80-loop-featured-in-cbs_103c01b000000000000028.png"
                alt="CBS"
                srcSet="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/aa733e80-loop-featured-in-cbs_103c01b000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/aa733e80-loop-featured-in-cbs_106o02m000000000000028.png 2x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/aa733e80-loop-featured-in-cbs_1000000000000000000028.png 3x"
                sizes="(max-width: 600px) 100vw, 600px"
                loading="lazy"
              />
              <img
                src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/47919e68-loop-featured-in-time_103c01b000000000000028.png"
                alt="Time"
                srcSet="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/47919e68-loop-featured-in-time_103c01b000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/47919e68-loop-featured-in-time_106o02m000000000000028.png 2x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/47919e68-loop-featured-in-time_1000000000000000000028.png 3x"
                sizes="(max-width: 600px) 100vw, 600px"
                loading="lazy"
              />
              <img
                src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/9fda1a03-loop-featured-in-techcrunch_103c01b000000000000028.png"
                alt="TechCrunch"
                srcSet="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/9fda1a03-loop-featured-in-techcrunch_103c01b000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/9fda1a03-loop-featured-in-techcrunch_106o02m000000000000028.png 2x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/9fda1a03-loop-featured-in-techcrunch_1000000000000000000028.png 3x"
                sizes="(max-width: 600px) 100vw, 600px"
                loading="lazy"
              />
              <img
                className="col-span-2 md:col-span-1"
                src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/c0d03322-loop-featured-in-pcmag_103c01b000000000000028.png"
                alt="PCMAG"
                srcSet="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/c0d03322-loop-featured-in-pcmag_103c01b000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/c0d03322-loop-featured-in-pcmag_106o02m000000000000028.png 2x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/c0d03322-loop-featured-in-pcmag_1000000000000000000028.png 3x"
                sizes="(max-width: 600px) 100vw, 600px"
                loading="lazy"
              />
            </div>
          </div>
        </section>
        <section className="bg-white pb-10">
          <div className="bg-loop-bg-orange w-80 md:w-9/12 mx-auto rounded-lg pt-12 pb-1 px-10 drop-shadow-lg">
            <h1 className="text-3xl font-extrabold text-loop-text-primary">
              Sharing Family Photos Made Effortless
            </h1>
            <div className="grid grid-cols-1 lg:grid-cols-3">
              {sharingFeatures.map((sharingFeature, i) => (
                <SharingFeatureBlock key={i} {...sharingFeature} />
              ))}
            </div>
          </div>
        </section>
        <section className="bg-loop-bg-dark-cream py-16 px-10">
          <div className="md:w-8/12 mx-auto">
            <div className="text-center mb-6 px-6">
              <h2 className="text-4xl font-bold mb-4 text-loop-text-primary">
                Invest In Showcasing Your Family's Moments
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div>
                  <h3 className="text-2xl text-loop-text-primary font-semibold mt-8">
                    Standard Digital Frame
                  </h3>
                  <img
                    className="mx-auto w-80 mb-10 md:w-full"
                    src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/a9926520-loop-standard-frame_108k06a000000000000028.png"
                    alt="Other frames"
                    srcSet="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/a9926520-loop-standard-frame_108k06a000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/a9926520-loop-standard-frame_1000000000000000000028.png 2x"
                    sizes="(max-width: 600px) 100vw, 600px"
                  />
                </div>
                <div>
                  <h3 className="text-2xl text-loop-text-primary font-semibold mt-8">
                    Loop Frame
                  </h3>
                  <img
                    className="mx-auto mb-10 w-80 md:w-full"
                    src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/e3a7f563-loop-frame_108g067000000000000028.png"
                    alt="Loop Frame"
                    srcSet="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/e3a7f563-loop-frame_108g067000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/e3a7f563-loop-frame_1000000000000000000028.png 2x"
                    sizes="(max-width: 600px) 100vw, 600px"
                  />
                </div>
              </div>
            </div>
            <div className="text-left px-12 text-loop-text-primary text-sm md:text-base">
              <span className="font-semibold">Loop’s Frame: </span>
              <span>
                IPS (In- Plane Switching) technology sets the gold standard for
                LCD displays, delivering stunningly accurate colors and wide
                viewing angles. Its superior color reproduction and contrast
                ratio breathes life into your images, ensuring every memory is
                displayed with the vividness and detail it deserves.
              </span>
            </div>
          </div>
        </section>
        <section className="bg-white py-16 mx-auto">
          <div className="w-80 mx-auto md:w-10/12">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-10 text-loop-text-primary">
                Bring Your <br className="sm:hidden" />
                Memories to Every <br className="sm:hidden" />
                Room
              </h2>
              <p className="text-base leading-5 text-left text-loop-text-primary md:w-10/12 mx-auto">
                Make a loved one’s day by keeping them involved in your every
                day life. With Loop you can capture a moment from your world and
                send it straight into theirs so they can share in everything
                from your little one’s first steps to your latest vacation. Send
                a snapshot in the moment, or relive memories with old family
                favorites.
              </p>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 mt-8 justify-items-center">
              {places.map((place, i) => (
                <FramePlaces key={i} {...place} />
              ))}
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="grid grid-cols-1 md:grid-cols-2 md:w-10/12 mx-auto items-center md:bg-loop-bg-orange">
            <div className="bg-loop-bg-orange w-80 md:w-8/12 text-center pt-12 px-4 mx-auto">
              <h1 className="text-4xl font-bold mb-8 md:mt-8 text-loop-text-primary">
                100 Day Risk-Free Guarantee
              </h1>
              <p className="text-base mb-8 leading-5 px-3">
                We are so confident your family will love Loop that we will give
                you a 100% satisfaction guarantee, or your money back!
              </p>
            </div>
            <div className="w-80 md:w-full text-center mx-auto">
              <img
                className="w-full h-auto"
                src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/1e0fd5ba-loop-mother-and-son_108w08u000000000000000.jpg"
                alt="Happy Family in Loop Frame"
                srcSet="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/1e0fd5ba-loop-mother-and-son_108w08u000000000000000.jpg 1x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/1e0fd5ba-loop-mother-and-son.jpg 2x"
                sizes="(max-width: 600px) 100vw, 600px"
              />
            </div>
          </div>
        </section>
        <section className="bg-loop-bg-dark-cream py-12">
          <div className="px-24">
            <div>
              <h2 className="text-loop-text-primary text-3xl font-bold text-center mb-6">
                Real Stories, Real Joy
              </h2>
              <div className="text-center text-loop-text-primary">
                <p className="text-lg italic mb-8">
                  "We regularly share photos of our kids, travels, and pics of
                  everyday life. It helps keep people feeling close and
                  included"
                </p>
                <p>- Monica J. from Seattle, Washington</p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white lg:mb-12">
          <div className="w-80 lg:w-10/12 mx-auto text-loop-text-primary">
            <h2 className="text-4xl font-extrabold my-12">
              Beautifully Simple To Use
            </h2>
            {features.map((feature, i) => (
              <FeatureBlock key={i} {...feature} index={i} />
            ))}
          </div>
        </section>
        <section className="bg-white">
          <div className="w-80 lg:w-10/12 mx-auto text-loop-text-primary lg:grid lg:grid-cols-2">
            <div className="overflow-hidden lg:overflow-auto lg:relative">
              <img
                src="https://d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/62a31ac8-loop-front-device_10000000go0fn000004000.jpg"
                alt="Baby Photo Frame"
                className="h-48 w-full object-cover lg:h-auto"
              />
              <h3 className="text-lg font-bold text-center mb-10 lg:absolute lg:bottom-8 lg:w-full">
                Vibrant class leading IPS display
              </h3>
            </div>
            <div className="relative">
              <img
                src="https://d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/dc53eb9b-loop-back-device_10000000g00f000000i000.jpg"
                alt="Loop Frame Back"
                className="w-full h-auto lg:h-auto"
              />
              <h3 className="text-lg font-semibold absolute bottom-2 px-6 lg:bottom-20 lg:w-full">
                Thoughtfully designed to fit in any home
              </h3>
            </div>
          </div>
        </section>
        <section className="bg-loop-bg-pink">
          <div className="text-gray-900 w-80 mx-auto md:w-8/12">
            <h2 className="text-left text-2xl md:text-4xl font-bold pt-12 pb-4 md:pb-12">
              FAQs
            </h2>
            <div>
              {faqData.map((faq, index) => (
                <FAQ
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  index={index}
                  isOpen={openFAQ === index}
                  onToggle={toggleFAQ}
                />
              ))}
            </div>
          </div>
        </section>
        <footer className="bg-loop-bg-salmon py-8">
          <div className="w-80 mx-auto text-loop-text-primary">
            <img
              className="mx-auto mb-8 w-48"
              src="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/08155fb4-logo-footer03_104w01o04w01m000001028.png"
              alt="Loop Logo"
              srcSet="//d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/08155fb4-logo-footer03_104w01o04w01m000001028.png 1x, //d9hhrg4mnvzow.cloudfront.net/try.loopfamily.com/06122023-1/08155fb4-logo-footer03_100000007902e000001028.png 2x"
              sizes="(max-width: 600px) 100vw, 600px"
            />
            <p className="text-xs mt-2">
              © 2023 California Labs Inc. | All rights reserved.
            </p>
          </div>
        </footer>
        <StickyFooter>
          <button
            className="bg-loop-primary text-loop-text-tertiary text-lg font-bold py-2 px-24 rounded"
            onClick={handleBuyNow}
          >
            Buy Now
          </button>
        </StickyFooter>
      </div>
    </>
  )
}

export default LoopStoreLanding
