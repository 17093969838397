import { ChevronDownIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useRef, useState } from 'react'
interface IProps {
  open?: boolean
  header: string | React.ReactNode
  children: React.ReactNode
}

const Collapsible = ({ open, children, header }: IProps) => {
  const [isOpen, setIsOpen] = useState(open)
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0)
  const ref = useRef<HTMLDivElement>(null)
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev)
  }
  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined
    // @ts-ignore
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height)
    })
    resizeObserver.observe(ref.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [height, isOpen])

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height)
    else setHeight(0)
  }, [isOpen])

  return (
    <div className="transition-all border-b border-fa-border">
      <button
        className="flex justify-between p-4 w-full items-center"
        onClick={handleFilterOpening}
      >
        <div className="font-bold text-left">{header}</div>
        <ChevronDownIcon
          className={`transition-all duration-200 ease-linear w-4 h-4 ${
            isOpen ? 'translate-y-1 rotate-180' : 'translate-y-0 rotate-0'
          }`}
        />
      </button>
      <div
        className="overflow-hidden transition-all duration-200 ease-in-out"
        style={{ height }}
      >
        <div ref={ref}>
          <div className="p-4">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Collapsible
