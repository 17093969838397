import { ReactNode, useMemo, useState } from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'

export enum SurveyAccount {
  JP = 'jp',
  EN = 'en',
}

const SURVEY_MONKEY_BASE_URLS = {
  [SurveyAccount.JP]: 'https://jp.surveymonkey.com/r/',
  [SurveyAccount.EN]: 'https://www.surveymonkey.com/r/',
}

const SurveyIframe = ({
  surveyId,
  surveyAccount,
  uuid,
}: {
  surveyId: string
  surveyAccount: SurveyAccount
  uuid: string
}) => {
  const url = useMemo(() => {
    const baseUrl = SURVEY_MONKEY_BASE_URLS[surveyAccount]
    const url = new URL(baseUrl)
    url.pathname += surveyId
    url.searchParams.append('uuid', uuid)
    return url
  }, [surveyId, surveyAccount, uuid])

  return (
    <div className="relative">
      <iframe
        title="SurveyMonkey"
        className="w-full h-screen"
        src={url.toString()}
      />
    </div>
  )
}

const SurveyMonkeySurvey = ({
  surveyId,
  surveyAccount = SurveyAccount.EN,
  children,
}: {
  surveyId: string
  surveyAccount: SurveyAccount
  children: ReactNode
}) => {
  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')
  return (
    <Routes>
      <Route
        path="content"
        element={
          <SurveyIframe
            uuid={uuid}
            surveyId={surveyId}
            surveyAccount={surveyAccount}
          />
        }
      />
      <Route index element={children} />
    </Routes>
  )
}

export default SurveyMonkeySurvey
