import { FunctionComponent } from 'react'
import cx from 'classnames'

const Footer: FunctionComponent<{
  className?: string
  children?: React.ReactNode
}> = ({ children, className }) => {
  return (
    <section className={cx('relative', className)}>
      <div className="shadow-line" />
      <div className="p-2 text-center bg-white">{children}</div>
    </section>
  )
}

export default Footer
