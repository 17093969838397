import { FC } from 'react'

export type UsageContent = {
  src: string
  title: string
  description: string
}

const UsageSection: FC<UsageContent> = ({ src, title, description }) => (
  <div>
    <img src={src} alt={title} className="mx-auto mb-6" />
    <h3 className="font-bold text-xl mb-6 text-fa-text-primary text-center">
      {title}
    </h3>
    <p className="text-fa-text-primary tracking-tight font-light text-base">
      {description}
    </p>
  </div>
)

export default UsageSection
