import { Component } from 'react'
import { clarity } from 'react-microsoft-clarity'

interface ClarityProps {
  projectId: string
}

class Clarity extends Component<ClarityProps> {
  constructor(props: ClarityProps) {
    super(props)
    const { projectId } = props
    clarity.init(projectId)
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    return false
  }
}

export default Clarity
