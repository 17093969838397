import {
  BuildingStorefrontIcon,
  ShoppingCartIcon,
} from '@heroicons/react/24/outline'
import cx from 'classnames'
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const Navbar: FC<{ showStoreIcon: boolean; showCartIcon: boolean }> = ({
  showStoreIcon,
  showCartIcon,
}) => {
  const navigate = useNavigate()

  const goToStore = useCallback(() => {
    window.scrollTo(0, 0)
    navigate('/loop-store/buy')
  }, [])

  const goToLanding = useCallback(() => {
    window.scrollTo(0, 0)
    navigate('/loop-store')
  }, [])

  const goToCart = useCallback(() => {
    window.scrollTo(0, 0)
    navigate('/loop-store/cart')
  }, [])

  return (
    <nav className="flex justify-between items-center px-4 py-1.5 bg-[#F9F9F9]">
      <div className="flex items-center">
        <button
          onClick={goToStore}
          className={cx({
            invisible: !showStoreIcon,
          })}
        >
          <BuildingStorefrontIcon className="h-auto w-8 text-loop-text-primary" />
        </button>
      </div>
      <div className="flex items-center">
        <div className="p-2">
          <button onClick={goToLanding}>
            <img
              src="//loopfamily.com/cdn/shop/files/loop-logo_210x.png?v=1613504008"
              alt="Logo"
              className="h-10 w-10 text-white"
            />
          </button>
        </div>
      </div>
      <div className="flex items-center">
        <button
          onClick={goToCart}
          className={cx({
            invisible: !showCartIcon,
          })}
        >
          <ShoppingCartIcon className="h-auto w-8 text-loop-text-primary" />
        </button>
      </div>
    </nav>
  )
}

export default Navbar
