import { PrintifyBlueprintVariant } from '../../../clients/fagl-server/types/printify'
import VariantSelector from './VariantSelector'

export default function VariantSelectionSection({
  variants,
  selectedVariantId,
  updateVariantId,
}: {
  variants: PrintifyBlueprintVariant[]
  selectedVariantId: number
  updateVariantId: (variantId: number) => void
}) {
  const uniqueColors = new Set<string>()
  const uniqueSizes = new Set<string>()

  variants.forEach((variant) => {
    const { color, hexColor, size } = variant.options
    if (color && hexColor) {
      uniqueColors.add(`${color}-${hexColor}`)
    }
    if (size) {
      uniqueSizes.add(size)
    }
  })
  const hasVariantsWithColors = uniqueColors.size > 1
  const hasVariantsWithSizes = uniqueSizes.size > 1

  const variantsWithColors = variants.filter(
    (variant) => variant.options.color && variant.options.hexColor
  )
  const variantsWithSizes = variants.filter((variant) => variant.options.size)
  const colorName = variantsWithColors.find(
    (variant) => variant.id === selectedVariantId
  )?.options.color

  return (
    <>
      {hasVariantsWithColors && (
        <VariantSelector
          title="Choose Color"
          option="color"
          variants={variantsWithColors}
          selectedVariantId={selectedVariantId}
          updateVariantId={updateVariantId}
          additionalInfo={colorName}
        />
      )}
      {hasVariantsWithSizes && (
        <VariantSelector
          title="Choose Size"
          option="size"
          variants={variantsWithSizes}
          selectedVariantId={selectedVariantId}
          updateVariantId={updateVariantId}
        />
      )}
    </>
  )
}
