import useAdvocateData from './useAdvocateData'
import ApiClient from '../../../ApiClient'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AdvocatePostMaxOut from './AdvocatePostMaxOut'
import AdvocatePreMaxOut from './AdvocatePreMaxOut'
import AdvocateInvalid from './AdvocateInvalid'
import AdvocateLoading from './AdvocateLoading'
import AdvocateError from './AdvocateError'
import AdvocateClaimedRedemptions from './AdvocateClaimedRedemptions'
import AdvocateShareSection from '../AdvocateShareSection'

const AdvocateLanding = ({
  api,
  familyAlbumDownloadDeepLink,
  supportEmail,
}: {
  api: ApiClient
  familyAlbumDownloadDeepLink: string
  supportEmail: string
}) => {
  const { advocateReferralCode } = useParams<{ advocateReferralCode: string }>()
  const navigate = useNavigate()
  if (!advocateReferralCode) {
    return <AdvocateError error="Invalid URL" />
  }
  const {
    state: {
      remainingReferralsNum,
      friendsReferredNum,
      maxReferralsNum,
      alreadyClaimedRedemptions,
      rewardCurrencyCode,
    },
    isInvalidAdvocate,
    isLoadingReferralState,
    error,
    hasClaimedRedemptions,
    advocatePreMaxOutCopy,
    upToCopy,
    copyToClipboardMessage,
    email,
    shareData,
  } = useAdvocateData({
    api,
    advocateReferralCode,
    familyAlbumDownloadDeepLink,
  })

  if (isLoadingReferralState) {
    return <AdvocateLoading />
  }

  if (isInvalidAdvocate) {
    return <AdvocateInvalid supportEmail={supportEmail} />
  }

  if (error) {
    return <AdvocateError error={error} />
  }

  return (
    <div className="max-w-md mx-auto relative">
      <div className="bg-fa-bg-blue py-2 w-full text-center">
        {friendsReferredNum}/{maxReferralsNum} friends referred
      </div>
      <div className="space-y-8">
        <section>
          {remainingReferralsNum === 0 ? (
            <AdvocatePostMaxOut />
          ) : (
            <AdvocatePreMaxOut
              upToCopy={upToCopy}
              copy={advocatePreMaxOutCopy}
            />
          )}
        </section>
        <AdvocateShareSection
          api={api}
          copyToClipboardMessage={copyToClipboardMessage}
          email={email}
          shareData={shareData}
        />
        {hasClaimedRedemptions && (
          <AdvocateClaimedRedemptions
            onFamilyAlbumRedemptionClick={(redemptionId: number) => {
              navigate(
                `/advocate/${advocateReferralCode}/redemptions/${redemptionId}/past`
              )
            }}
            claimedFamilyAlbumRedemptions={
              alreadyClaimedRedemptions.familyAlbum
            }
            claimedRunaRedemptions={alreadyClaimedRedemptions.runa}
          />
        )}
        <footer className="border-t-object-separator-2 border-t py-8 space-y-4 text-fa-text-tertiary">
          <p className="text-sm text-left px-4">
            *See{' '}
            <Link
              className="text-fa-primary"
              to={`/referral/gc-terms/${rewardCurrencyCode}`}
            >
              FamilyAlbum Gift Card Terms and Conditions
            </Link>
          </p>
          <p className="my-1 text-sm text-left px-4">
            By participating in our referral program, you agree to the{' '}
            <a
              href="https://family-album.com/en/terms"
              className="text-fa-primary"
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions
            </a>
            . Any violation or abuse of the program may lead to disqualification
            and loss of benefits.
          </p>
        </footer>
      </div>
    </div>
  )
}

export default AdvocateLanding
