import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid'

const FAQ = ({
  question,
  answer,
  index,
  isOpen,
  onToggle,
}: {
  question: string
  answer: string
  index: number
  isOpen: boolean
  onToggle: (index: number) => void
}) => {
  const iconClasses = 'w-6 h-6 px-1 mr-4 text-loop-bg-pink bg-black rounded'
  return (
    <div className="pb-6">
      <button
        className="flex items-center justify-between w-full px-8 mb-4 focus:outline-none"
        onClick={() => onToggle(index)}
        aria-expanded={isOpen}
      >
        <div className="flex items-start">
          <div className="mt-2">
            {isOpen ? (
              <MinusIcon className={iconClasses} />
            ) : (
              <PlusIcon className={iconClasses} />
            )}
          </div>
          <div className="text-left">
            <span className="text-xl md:text-3xl font-bold">{question}</span>
          </div>
        </div>
      </button>
      <div
        className={`transition-all duration-500 ease-in-out overflow-hidden ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        <div className="pr-6 pl-16 pb-4 text-left leading-5 md:text-lg">
          {answer}
        </div>
      </div>
    </div>
  )
}

export default FAQ
