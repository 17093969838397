export default function AdvocateError({ error }: { error: string }) {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      Oops... Something went wrong
      <div className="mt-2">
        <pre>{error}</pre>
      </div>
    </div>
  )
}
