import { useCallback } from 'react'
import cx from 'classnames'
import { CheckIcon } from '@heroicons/react/24/solid'

export default function PhotoTile({
  uuid,
  togglePhotoSelection,
  url,
  isSelected,
}: {
  uuid: string
  togglePhotoSelection: (uuid: string) => void
  url: string
  isSelected: boolean
}) {
  const onClick = useCallback(() => {
    togglePhotoSelection(uuid)
  }, [togglePhotoSelection, uuid])

  return (
    <button onClick={onClick} className="h-full w-full relative" style={{}}>
      <img
        src={url}
        alt={uuid}
        className={cx('w-full h-full', {
          'bg-white bg-opacity-30': isSelected,
        })}
      />
      <div
        className={cx('absolute w-full h-full top-0 right-0', {
          'bg-white bg-opacity-30': isSelected,
        })}
      >
        <span
          className={cx(
            'w-5 h-5 rounded-full right-2 top-2 absolute flex items-center justify-center',
            {
              'bg-fa-primary': isSelected,
              'border-solid border-[3px]': !isSelected,
            }
          )}
        >
          {isSelected && <CheckIcon className="text-white w-3 h-3" />}
        </span>
      </div>
    </button>
  )
}
