import ReferralInviteHeader from '../../../assets/referral-invite-header.svg?react'

export default function AdvocatePreMaxOut({
  copy,
  upToCopy,
}: {
  copy: string
  upToCopy: string
}) {
  return (
    <div>
      <ReferralInviteHeader className="w-full h-auto mb-8" />
      <div className="px-4 w-full flex flex-col items-center">
        <h2 className="font-bold mb-2 text-lg">{upToCopy}</h2>
        <p className="px-4 text-center leading-7">
          {copy}
          <a
            target="_blank"
            href="https://help.family-album.com/hc/en-us"
            className="text-fa-primary font-extrabold block text-center mt-2"
            rel="noreferrer"
          >
            Learn more
          </a>
        </p>
      </div>
    </div>
  )
}
