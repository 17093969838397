import cx from "classnames";
import { FunctionComponent } from "react";
import Lottie from "lottie-react";
import animationData from "../assets/spinner.json";

const Spinner: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <Lottie animationData={animationData} className={cx(className)} autoplay />
  );
};

export default Spinner;
