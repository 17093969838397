import cx from 'classnames'
import Spinner from '../shared/Spinner'

export default function LoadingScreen({ className }: { className?: string }) {
  return (
    <div
      className={cx(
        'h-screen flex flex-col justify-center items-center px-8 text-center',
        className
      )}
    >
      <Spinner className="block h-40" />
    </div>
  )
}
