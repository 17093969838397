import { FC } from 'react'
import Lottie from 'lottie-react'
import loopHeader10pctOff from '../../../animations/grandparents_day/store_lp_loop_header_10pct_off.json'
import loopHeader from '../../../animations/grandparents_day/store_lp_loop_header.json'
import HeaderTitle from '../../../assets/grandparents_day/special/store_lp_loop_header_title.svg?react'
import LinkButton from '../../../components/buttons/LinkButton'
import DetailedCountdown from '../../../components/DetailedCountdown'
import { C2AMetadata } from '../../../hooks/useRecordUserAction'

const LoopHeader: FC<{
  deadline: Date
  onCtaClick: (metadata: C2AMetadata) => void
  toPath: string
  displaySaleHeader: boolean
}> = ({ deadline, onCtaClick, toPath, displaySaleHeader }) => {
  const header = displaySaleHeader ? loopHeader10pctOff : loopHeader
  return (
    <section className="bg-[#D5EBF7]">
      <div className="w-full mx-auto relative">
        <Lottie loop={true} animationData={header} autoplay />
        <HeaderTitle className="mx-auto mb-4 absolute top-8 left-0 right-0" />
      </div>
      <LinkButton
        onClick={() => onCtaClick({ clickedElement: 'LP_HEADER' })}
        className="block p-2 text-center w-11/12 mx-auto mt-2 rounded-md"
        colorVariant="primary"
        to={toPath}
      >
        Get a Digital Photo Frame
      </LinkButton>
      <div className="w-full text-center p-4">
        <div className="text-base">
          {displaySaleHeader
            ? <>Limited <b>10% OFF</b> Sale ends in</>
            : <b>Delivery by Grandparents’ Day ends in</b>}
        </div>
        <DetailedCountdown
          deadline={deadline}
          variant="cards"
          style={{
            color: '#000000',
            width: '100%',
          }}
        />
      </div>
    </section>
  )
}

export default LoopHeader
