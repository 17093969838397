const Footer = () => {
  return (
    <footer className="bg-[#0F2D3D] text-white p-8">
      <div className="max-w-3xl mx-auto text-center">
        <div className="text-gray-400 text-sm mb-8">
          <a
            href="https://loopfamily.com/pages/terms"
            className="hover:underline"
          >
            Privacy Policy and Terms of Service
          </a>
        </div>
        <div className="text-gray-400 text-sm">
          © 2024 California Labs Inc. All rights reserved.
        </div>
      </div>
    </footer>
  )
}

export default Footer
