import HeartIcon from '../../assets/heart.svg?react'
import UploadIcon from '../../assets/upload.svg?react'
import GiftCardIcon from '../../assets/giftcard.svg?react'

export default function WhatIsNext() {
  return (
    <section className="my-8 px-4 text-center">
      <h2 className="text-xl font-bold mb-4">What's next?</h2>
      <ul className="space-y-4 text-left">
        <li className="flex items-center space-x-4">
          <div className="bg-fa-bg-guide flex-shrink-0 rounded-full w-10 h-10 flex items-center justify-center">
            <HeartIcon className="w-5" />
          </div>
          <div>
            <h3 className="font-bold">Connect with Loved Ones</h3>
            <p className="text-fa-text-secondary">
              Invite family and friends to join your album{' '}
            </p>
          </div>
        </li>
        <li className="flex items-center space-x-4">
          <div className="bg-fa-bg-guide flex-shrink-0 rounded-full w-10 h-10 flex items-center justify-center">
            <UploadIcon className="w-5" />
          </div>
          <div>
            <h3 className="font-bold">Share Cherished Moments</h3>
            <p className="text-fa-text-secondary">
              Upload and share photos and videos with your loved ones!
            </p>
          </div>
        </li>
        <li className="flex items-center space-x-4">
          <div className="bg-fa-bg-guide flex-shrink-0 rounded-full w-10 h-10 flex items-center justify-center">
            <GiftCardIcon className="w-5" />
          </div>
          <div>
            <h3 className="font-bold">Create Enduring Treasures</h3>
            <p className="text-fa-text-secondary">
              Use your gift card balance to order photo prints and albums
            </p>
          </div>
        </li>
      </ul>
    </section>
  )
}
