import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

const TRANSITION_CODE_QUERY_PARAM_KEY = 'code'

const useTransitionCode = () => {
  const [params] = useSearchParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const code = params.get(TRANSITION_CODE_QUERY_PARAM_KEY) || ''
  const deleteParam = useCallback(() => {
    searchParams.delete(TRANSITION_CODE_QUERY_PARAM_KEY)
    setSearchParams(searchParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    deleteParam,
    transitionCode: code,
  }
}

export default useTransitionCode
