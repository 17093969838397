import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useCallback, useEffect, useState } from 'react'
import ApiClient from '../../../ApiClient'
import { useParams } from 'react-router-dom'
import LoadingOverlay from '../components/LoadingOverlay'

const stripePromise = loadStripe(import.meta.env.VITE_PRINTIFY_STRIPE_KEY)

export default function StripeCheckoutPage({
  next,
  createPaymentIntent,
  clientSecret,
}: {
  next: (orderId: string) => void
  createPaymentIntent: (orderId: string) => Promise<void>
  clientSecret: string | null
}) {
  const { orderId } = useParams<{ orderId: string }>()

  const onComplete = useCallback(() => {
    if (!orderId) {
      return
    }

    next(orderId)
  }, [next, orderId])

  useEffect(() => {
    if (!orderId) {
      return
    }
    createPaymentIntent(orderId)
  }, [orderId])

  return (
    <>
      <div className="w-full mx-auto">
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{
            onComplete,
            clientSecret,
          }}
        >
          <EmbeddedCheckout className="bg-gray-2" />
        </EmbeddedCheckoutProvider>
      </div>
      {!clientSecret && (
        <LoadingOverlay>Creating secure payment form</LoadingOverlay>
      )}
    </>
  )
}
