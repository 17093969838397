import ApiClient from '../../../ApiClient'
import Spinner from '../../../shared/Spinner'
import useReferredData from './useReferredData'
import RedeemNotEligibleHasReward from './RedeemNotEligibleHasReward'
import WhatIsNext from '../WhatIsNext'
import SuccesfulFamilyAlbumRewardAutoRedemption from '../SuccesfulFamilyAlbumRewardAutoRedemption'

const Redeem = ({
  api,
  familyAlbumStoreDeepLink,
  supportEmail,
}: {
  api: ApiClient
  familyAlbumStoreDeepLink: string
  supportEmail: string
}) => {
  const {
    isValidatingRedeem,
    isEligible,
    formattedRewardToClaim,
    isExistingRedemptionFamilyAlbum,
    existingRedemptionReward,
  } = useReferredData({
    api,
  })

  if (isValidatingRedeem) {
    return (
      <div className="h-screen flex flex-col justify-center items-center px-8 text-center">
        <p className="font-bold mb-2">Thanks for your patience!</p>
        <p className="text-fa-text-secondary mb-8">
          Please wait while we load your referral information...
        </p>
        <Spinner className="block h-20" />
      </div>
    )
  }

  if (!isEligible && !isExistingRedemptionFamilyAlbum) {
    return <RedeemNotEligibleHasReward supportEmail={supportEmail} />
  }

  if (isExistingRedemptionFamilyAlbum) {
    return (
      <SuccesfulFamilyAlbumRewardAutoRedemption
        formattedReward={existingRedemptionReward}
        isPastRedemtpion
        familyAlbumStoreDeepLink={familyAlbumStoreDeepLink}
      >
        <WhatIsNext />
      </SuccesfulFamilyAlbumRewardAutoRedemption>
    )
  }

  return (
    <SuccesfulFamilyAlbumRewardAutoRedemption
      formattedReward={formattedRewardToClaim}
      familyAlbumStoreDeepLink={familyAlbumStoreDeepLink}
    >
      <WhatIsNext />
    </SuccesfulFamilyAlbumRewardAutoRedemption>
  )
}

export default Redeem
