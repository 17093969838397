const familyAlbumAutoLoginUrl =
  import.meta.env.VITE_FAMILY_ALBUM_AUTOLOGIN_URL || '/'
const familyAlbumDownloadDeepLink = import.meta.env
  .VITE_FAMILY_ALBUM_DOWNLOAD_DEEPLINK
const baseUrl = import.meta.env.VITE_PUBLIC_URL || '/'
const advocateLinkBaseUrl = baseUrl || ''
const clarityProjectId = import.meta.env.VITE_CLARITY_PROJECT_ID
const isProduction = import.meta.env.VITE_ENVIRONMENT === 'production'
const supportEmail =
  import.meta.env.VITE_SUPPORT_EMAIL || 'global-support@family-album.com'
const familyAlbumStoreDeepLink = import.meta.env
  .VITE_FAMILY_ALBUM_STORE_DEEPLINK

const isPrintifyPreviewLogsActive = import.meta.env
  .VITE_ARE_PRINTIFY_PREVIEW_LOGS_ACTIVE

const config = {
  familyAlbumAutoLoginUrl,
  familyAlbumDownloadDeepLink,
  advocateLinkBaseUrl,
  clarityProjectId,
  isProduction,
  baseUrl,
  supportEmail,
  familyAlbumStoreDeepLink,
  isPrintifyPreviewLogsActive,
}

export default config
