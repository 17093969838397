import Lottie from 'lottie-react'
import { FC, useCallback, useMemo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'
import ApiClient from '../../ApiClient'
import animationData from '../../animations/loop-header.json'
import CopyToClipboardIcon from '../../assets/copy-to-clipboard-icon.svg?react'
import loopUsagePhoto from '../../assets/loop-usage-photo.png'
import LoopWhy1 from '../../assets/loop-why-1.svg?react'
import LoopWhy2 from '../../assets/loop-why-2.svg?react'
import LoopWhy3v2 from '../../assets/loop-why-3-v2.svg?react'
import LoopWhy3v3 from '../../assets/loop-why-3-v3.svg?react'
import FaqItem from '../../components/FaqItem'
import FooterDisclaimer from '../../components/FooterDisclaimer'
import StickyFooter from '../../components/StickyFooter'
import Button from '../../components/buttons/Button'
import LinkButton from '../../components/buttons/LinkButton'
import useRecordUserAction from '../../hooks/useRecordUserAction'
import {
  COUPON_CODE_QUERY_PARAM_KEY,
  LOCAL_STORAGE_CART_ID_KEY,
} from './LoopStore'

const faqItems = [
  {
    question: 'How do I connect Loop with my FamilyAlbum account?',
    answer:
      'Connecting Loop with your FamilyAlbum account is a breeze—complete the Loop photo frame setup and download the Loop app to connect your FamilyAlbum account. Your personal favorites in the FamilyAlbum app will automatically sync with your Loop frame each day.',
  },
  {
    question: 'Can I select specific photos from FamilyAlbum?',
    answer:
      'Loop is set up to automatically sync to your Favorites in FamilyAlbum. If you want a photo to appear on Loop, just add it to your Favorites by selecting the star icon in the FamilyAlbum app.',
  },
  {
    question: 'Can I select photos from my phone?',
    answer:
      "Yes! Use the Loop app to handpick photos or opt for hassle-free automatic sync with your FamilyAlbum favorites. You're in control—choose multiple albums for a frame, blending your favorites with cherished moments effortlessly.",
  },
  {
    question: 'Can I gift a Loop frame with pre-loaded FamilyAlbum photos?',
    answer:
      "Absolutely! When gifting a Loop frame, it's recommended to set it up and pre-load it with FamilyAlbum photos for an optimal unboxing experience. The gift receiver will only have to connect the frame to their local Wi-Fi to start seeing photos sync automatically from FamilyAlbum. You'll then be able to remotely update the photos displayed on the frame any time you'd like, and the recipient won’t need to download the app either!",
  },
  {
    question: 'Does Loop play videos?',
    answer:
      'Yes! Loop brings your photos and videos to life, offering dynamic displays for short clips or longer videos, ensuring an engaging showcase of your family memories.',
  },
  {
    question: 'Does Loop need a WiFi connection?',
    answer:
      'Loop requires WiFi for initial setup and automatic syncs with new photos and videos from FamilyAlbum. That said, no WiFi is required to keep showing photos and videos already loaded to Loop. If you choose to gift Loop pre-loaded with photos and no need for automatic updates, there should be no problem using without WiFi.',
  },
  {
    question:
      'Can I connect the Loop frame to more than one FamilyAlbum album?',
    answer:
      'There is no limit on the number of FamilyAlbum albums that can be connected to a single Loop frame. All the albums must be accessible with a single FamilyAlbum account.',
  },
]

const LoopModal: FC<{
  api: ApiClient
  supportEmail: string
  couponCode?: 'Family10' | 'FAMILYALBUM'
}> = ({ api, supportEmail, couponCode }) => {
  const { recordLoopC2ATap, recordDidCopyLoopPromoCodeTap } =
    useRecordUserAction(api)

  const goToLoopStoreLp = useCallback(() => {
    window.scrollTo(0, 0)
    localStorage.removeItem(LOCAL_STORAGE_CART_ID_KEY)
    recordLoopC2ATap()
  }, [])

  const onCopy = useCallback(() => {
    recordDidCopyLoopPromoCodeTap()
    toast.success('Copied!')
  }, [])

  const toPath = useMemo(
    () =>
      `/loop-store${
        couponCode ? `?${COUPON_CODE_QUERY_PARAM_KEY}=${couponCode}` : ''
      }`,
    [couponCode]
  )

  return (
    <div>
      <div
        className="text-white font-bold text-center leading-5 py-2 px-4"
        style={{
          background: 'linear-gradient(88deg, #223750 23.16%, #657D9A 97.15%)',
        }}
      >
        Fast and free shipping! 📦
        <br /> 100% money-back guarantee
      </div>

      <div>
        <Lottie loop={true} animationData={animationData} autoplay />
      </div>
      <section className="px-4 py-12 text-center space-y-12">
        <header className="space-y-4">
          <h2 className="font-bold text-xl">Why Loop X FamilyAlbum?</h2>
          <p>
            Loop is the perfect way to get those special FamilyAlbum photos into
            your home. For those who aren’t tech savvy, Loop can be a life
            changing way to stay connected without using a smartphone.
          </p>
        </header>
        <div>
          <LoopWhy1 className="mx-auto mb-4" />
          <h3 className="font-bold text-xl mb-2">Sets up in 1 minute</h3>
          <p>
            Just plug in Loop, connect it to Wi-Fi and start adding your
            favorite photos straight from FamilyAlbum or your phone - it’s that
            simple.
          </p>
        </div>
        <div>
          <LoopWhy2 className="mx-auto mb-4" />
          <h3 className="font-bold text-xl mb-2">Easy-to-use touchscreen</h3>
          <p>
            It's not just a frame, it's a window into your treasured FamilyAlbum
            memories.
          </p>
        </div>
        {couponCode && (
          <div>
            {couponCode === 'FAMILYALBUM' ? (
              <LoopWhy3v2 className="mx-auto mb-4" />
            ) : (
              <LoopWhy3v3 className="mx-auto mb-4" />
            )}
            <h3 className="font-bold text-xl mb-2">Limited time offer</h3>
            <p className="mb-6">
              Order today and get {couponCode === 'FAMILYALBUM' ? '5%' : '$10'}{' '}
              off your purchase with coupon code “<strong>{couponCode}</strong>
              ”!
            </p>
            <CopyToClipboard text={couponCode} onCopy={onCopy}>
              <Button
                colorVariant="primary:invert"
                className="rounded-full text-s px-4 py-2 flex items-center mx-auto"
              >
                <CopyToClipboardIcon />
                Copy coupon code
              </Button>
            </CopyToClipboard>
          </div>
        )}
      </section>
      <section className="bg-fa-bg-blue px-4 py-12 text-center">
        <h2 className="font-bold text-xl mb-4">
          Preload FamilyAlbum photos for a special surprise
        </h2>
        <p>
          Surprise your loved ones with the perfect gift that keeps on giving!
        </p>
        <img className="mx-auto py-6" src={loopUsagePhoto} alt="Loop" />
        <p>
          Connect your FamilyAlbum account to effortlessly sync all your
          favorited photos while enjoying the convenience of remotely
          controlling frame settings via Wi-Fi.
        </p>
      </section>
      <section className="px-4 py-12">
        <h2 className="font-bold text-xl mb-4">FAQ</h2>
        <div className="text-fa-text-secondary space-y-8">
          {faqItems.map((item) => (
            <FaqItem
              key={item.question}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </section>
      <footer className="px-4 pb-8">
        <p>
          Have questions? Contact us at <br />
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </p>
        <FooterDisclaimer />
      </footer>
      <StickyFooter>
        <LinkButton
          onClick={goToLoopStoreLp}
          className="block p-2"
          colorVariant="primary"
          to={toPath}
        >
          Visit Loop{couponCode && ' and use my discount'}
        </LinkButton>
      </StickyFooter>
    </div>
  )
}

export default LoopModal
