const SharingFeatureBlock = ({
  imgSrc,
  altText,
  title,
  paragraphs,
}: {
  imgSrc: string
  altText: string
  title: string
  paragraphs: string[]
}) => (
  <div className="my-10 md:px-16">
    <img src={imgSrc} alt={altText} className="mx-auto w-20 md:w-28 lg:w-40" />
    <h3 className="text-lg font-bold mt-4 text-loop-text-secondary">{title}</h3>
    {paragraphs.map((p, i) => (
      <p key={i} className="mt-4 text-sm md:text-base text-loop-text-primary">
        {p}
      </p>
    ))}
  </div>
)

export default SharingFeatureBlock
