import { useCallback } from 'react'
import cx from 'classnames'

export default function VariantSelectButton({
  isSelected,
  colorName,
  colorValue,
  updateVariantId,
  variantId,
}: {
  isSelected: boolean
  colorName: string
  colorValue: string
  updateVariantId: (variantId: number) => void
  variantId: number
}) {
  const onClick = useCallback(() => {
    updateVariantId(variantId)
  }, [variantId, updateVariantId])

  return (
    <button
      onClick={onClick}
      className={cx(
        'w-9 h-9 rounded-full border border-solid p-1 transition-all',
        {
          'border-fa-border': colorName === 'white' && !isSelected,
          'border-transparent': colorName !== 'white' && !isSelected,
          'border-fa-primary border-2': isSelected,
        }
      )}
      style={{
        backgroundColor: isSelected ? 'transparent' : colorValue,
      }}
    >
      <span
        className="rounded-full w-full h-full block"
        style={{
          backgroundColor: colorValue,
        }}
      />
    </button>
  )
}
