import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import limitedTimeUpTo20PctOffBanner from '../assets/photo-gifts/limited_time_up_to_20pct_off_banner.png'
import ApiClient from '../ApiClient'
import usePhotoGifts from '../hooks/usePhotoGifts'
import useRecordUserAction, { C2AMetadata } from '../hooks/useRecordUserAction'
import PhotoGiftsSection from '../pages/grandparents-day/components/PhotoGiftsSection'

export default function PhotoGiftsProductList({
  api,
  printifyProductsListLink,
}: {
  api: ApiClient
  printifyProductsListLink: string
}) {
  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')

  const photoGifts = usePhotoGifts(printifyProductsListLink, uuid)
  const photoProductItems = photoGifts.getPhotoProductItems()
  const socksItems = photoGifts.getSocksItems()

  const {
    photoProducts: { printify, divvyup },
  } = useRecordUserAction(api)

  const onProductClick = useCallback(
    (metadata: C2AMetadata) => {
      window.scrollTo(0, 0)
      if (metadata.blueprintId) {
        printify.recordDidSelectPhotoProduct(metadata)
      } else {
        divvyup.recordDidSelectPhotoProduct(metadata)
      }
    },
    [printify, divvyup]
  )

  return (
    <div className="max-w-lg mx-auto">
      <img src={limitedTimeUpTo20PctOffBanner} className="w-full" />
      <section className="pt-8 pb-4 px-4 text-black">
        <h2 className="text-center text-xl font-bold">Select a Photo Gift</h2>
        <p className="pt-4">
          Make Grandparents in your life smile with unforgettable personalized
          gifts!
        </p>
      </section>
      <PhotoGiftsSection
        onProductClick={onProductClick}
        photoProductItems={photoProductItems}
        socksItems={socksItems} />
    </div>
  )
}
