export default function ProgressBarLoadingAnimation() {
  return (
    <div className="rounded-full w-40 bg-white p-1 shadow-md h-6 overflow-hidden">
      <div
        className="w-full h-full rounded-full transition-all"
        style={{
          animation: 'colorChangingProgressBar 25s linear infinite',
        }}
      />
    </div>
  )
}
