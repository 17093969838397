import { FC } from 'react'
import cx from 'classnames'

const HowItWorksItem: FC<{
  src: string
  title: string
  text: string
  className: string
}> = ({
  src,
  title,
  text,
  className,
}) => {
  return (
    <div>
      <img src={src} alt={src} className={cx('mx-auto mb-4', className)} />
      <h3 className="font-semibold text-xl mb-2 text-fa-text-primary">
        {title}
      </h3>
      <p className="text-fa-text-primary tracking-wider">{text}</p>
    </div>
  )
}
export default HowItWorksItem
