import { FC, ReactNode } from 'react'

const SectionHeader: FC<{
  order: number
  title: string
  children: string | ReactNode
}> = ({ order, title, children }) => (
  <div className="p-4 text-center">
    <h1 className="text-xl italic font-semibold">{order}</h1>
    <h1 className="text-xl font-semibold">{title}</h1>
    <p className="mt-6 px-6 text-base">{children}</p>
  </div>
)

export default SectionHeader
