/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from 'react'
import ApiClient, { ReferralStateForReferredUser } from '../../../ApiClient'
import { toast } from 'react-hot-toast'
import { isAxiosError } from 'axios'
import { createCurrencyFormatter } from '../../../utils'

const useReferredData = ({ api }: { api: ApiClient }) => {
  const [isValidatingRedeem, setIsValidatingRedeem] = useState(true)
  const [isProcessingClaim, setIsProcessingClaim] = useState(false)
  const [isEligible, setIsEligible] = useState(false)

  const [state, setState] = useState<ReferralStateForReferredUser>({
    advocateUserUuid: '',
    referredUserUuid: '',
    reward: 0,
    existingRedemption: null,
    valid: false,
    deliveryUrl: '',
    error: '',
    rewardCurrencyCode: '',
  })

  const claim = useCallback(async () => {
    setIsProcessingClaim(true)
    try {
      const response = await api.createReferredRedemption()
      return response
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message)
        console.error(err)
      }
    } finally {
      setIsProcessingClaim(false)
    }
  }, [api])

  const loadState = useCallback(async () => {
    try {
      const response = await api.getReferralStateForReferredUser()
      setState(response)

      api.logUserActionForReferredOfAdvocate('REFERRED_VIEWED_REDEEM_PAGE', {
        isEligible: response.valid,
        error: response.error,
        referredUserId: response.referredUserUuid,
      })

      if (response.valid) {
        setIsEligible(true)
        await claim()
      }
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err.response?.data.message)
      } else if (err instanceof Error) {
        toast.error(err.message)
      }
      console.error(err)
    } finally {
      setIsValidatingRedeem(false)
    }
  }, [api, claim])

  useEffect(() => {
    loadState()
  }, [])

  const currencyFormatter = useMemo(
    () =>
      state.rewardCurrencyCode
        ? createCurrencyFormatter(state.rewardCurrencyCode)
        : (amount: number) => amount.toString(),
    [state.rewardCurrencyCode]
  )

  const formatReward = (reward: number) =>
    `${currencyFormatter(
      reward / 100
    )} ${state.rewardCurrencyCode.toUpperCase()}`

  return {
    isValidatingRedeem,
    isEligible,
    isProcessingClaim,
    formattedRewardToClaim: formatReward(state.reward),
    existingRedemptionReward: state.existingRedemption
      ? formatReward(state.existingRedemption.amount)
      : '',
    isExistingRedemptionFamilyAlbum:
      state.existingRedemption?.provider === 'FAMILY_ALBUM',
  }
}

export default useReferredData
