import { FC } from 'react'

export type LoopWhyContent = {
  src: string
  title: string
  description: string
}

const LoopWhySection: FC<LoopWhyContent> = ({ src, title, description }) => (
  <div>
    <img src={src} alt={title} className="mx-auto mb-6" />
    <h3 className="font-bold text-xl mb-4 text-fa-text-primary text-center">
      {title}
    </h3>
    <p className="text-fa-text-primary tracking-wider font-light text-base">
      {description}
    </p>
  </div>
)

export default LoopWhySection
