import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Cart } from '../../clients/storefront/types'
import { create2DecimalsCurrencyFormatter } from '../../utils'
import CartLineItem from './components/CartLineItem'
import Navbar from './components/Navbar'

const LoopStoreCart: FC<{
  cart: Cart | null
  onRemove: (lineId: string) => void
  onUpdate: (lines: { id: string; quantity: number }[]) => void
  onCheckout: () => void
}> = ({ cart, onRemove, onUpdate, onCheckout }) => {
  const navigate = useNavigate()
  const [lineQuantities, setLineQuantities] = useState<{
    [lineId: string]: number | ''
  }>({})

  const handleQuantityChange = (lineId: string, quantity: string) => {
    if (!isNaN(Number(quantity))) {
      setLineQuantities((prevQuantities) => ({
        ...prevQuantities,
        [lineId]: quantity === '' ? '' : parseInt(quantity),
      }))
    }
  }

  const handleUpdateClick = () => {
    if (!cart) {
      return
    }

    const updatedLines = cart.lines.nodes.map((line) => ({
      id: line.id,
      quantity: lineQuantities[line.id] || 0,
    }))

    onUpdate(updatedLines)
  }

  const handleContinueShopping = () => {
    navigate('/loop-store/buy')
  }

  const currencyFormatter = useMemo(
    () => create2DecimalsCurrencyFormatter('USD'),
    []
  )

  useEffect(() => {
    if (cart) {
      const quantities = cart.lines.nodes.reduce(
        (acc, line) => ({
          ...acc,
          [line.id]: line.quantity,
        }),
        {}
      )
      setLineQuantities(quantities)
    }
  }, [cart])

  if (!cart) {
    cart = {
      id: '',
      createdAt: '',
      updatedAt: '',
      lines: { nodes: [] },
      cost: {
        subtotalAmount: {
          amount: '0.00',
          currencyCode: 'USD',
        },
      },
      checkoutUrl: '',
    }
  }

  return (
    <>
      <Navbar showStoreIcon={true} showCartIcon={false} />
      <div className="container mx-auto p-4 mb-12 text-loop-text-store-primary">
        <h1 className="text-3xl mb-2">Cart</h1>
        {cart.lines.nodes.map((line) => (
          <div key={line.id}>
            <CartLineItem
              key={line.id}
              line={line}
              quantity={lineQuantities[line.id] || ''}
              onQuantityChange={handleQuantityChange}
              onRemove={onRemove}
            />
          </div>
        ))}

        <div className="border-t mt-4 pt-4">
          <div className="flex flex-col text-center space-y-2">
            <p>
              Subtotal{' '}
              <span className="font-semibold">
                {currencyFormatter(parseFloat(cart.cost.subtotalAmount.amount))}
              </span>
            </p>
            <p>Shipping and taxes calculated at checkout.</p>
          </div>

          <div className="flex justify-center mt-4">
            {cart.lines.nodes.length === 0 ? (
              <button
                className="bg-[#2b4353] text-white px-4 py-2 text-sm"
                onClick={handleContinueShopping}
              >
                Continue shopping
              </button>
            ) : (
              <>
                <button
                  type="submit"
                  name="update"
                  className="bg-gray-200 text-gray-700 px-4 py-2 mr-2 text-sm"
                  onClick={handleUpdateClick}
                >
                  Update cart
                </button>
                <button
                  className="bg-[#2b4353] text-white px-4 py-2 text-sm"
                  type="submit"
                  name="checkout"
                  onClick={onCheckout}
                >
                  Check out
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <footer className="bg-[#0F2D3D] text-white p-8">
        <div className="max-w-3xl mx-auto text-center">
          <div className="text-gray-400 text-sm mb-8">
            <a
              href="https://loopfamily.com/pages/terms"
              className="hover:underline"
            >
              Privacy Policy and Terms of Service
            </a>
          </div>
          <div className="text-gray-400 text-sm">
            © 2024 California Labs Inc. All rights reserved.
          </div>
        </div>
      </footer>
    </>
  )
}

export default LoopStoreCart
