import { FunctionComponent } from 'react'
import cx from 'classnames'
import Footer from './Footer'

const StickyFooter: FunctionComponent<{
  className?: string
  children?: React.ReactNode
}> = ({ children, className }) => {
  return (
    <Footer className={cx('sticky bottom-0 w-full', className)}>
      {children}
    </Footer>
  )
}

export default StickyFooter
