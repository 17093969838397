import ApiClient from '../../ApiClient'
import PhotoSelector from './components/PhotoSelector'
import { useCallback } from 'react'
import { Navigate } from 'react-router-dom'
import CropIcon from '../../assets/crop.svg?react'
import Button from '../../shared/buttons/Button'
import CropDialog from './components/CropDialog'

export default function PersonalizePage({
  api,
  closeCropDialog,
  openCropDialog,
  confirmCrop,
  previous,
  next,
  preCropDataUrl,
  aspectRatio,
  isCropping,
  croppedDataUrl,
  onNewPhotoSelected,
  candidateDataUrl,
}: {
  api: ApiClient
  closeCropDialog: () => void
  openCropDialog: () => void
  confirmCrop: (dataUrl: string) => void
  next: () => void
  previous: () => void
  preCropDataUrl: string | null
  isCropping: boolean
  aspectRatio: number
  croppedDataUrl: string | null
  onNewPhotoSelected: (base64: string) => void
  candidateDataUrl: string | null
}) {
  const onNext = useCallback(() => {
    next()
  }, [next])

  const onPrevious = useCallback(() => {
    previous()
  }, [previous])

  if (!preCropDataUrl) {
    return <Navigate to="../select-photo" />
  }

  return (
    <>
      <div className="h-screen bg-gray-2 relative">
        <div className="px-4 pb-8 space-y-8 flex flex-col mb-auto">
          <header className="pt-8">
            <h1 className="font-bold text-xl">Personalize your design</h1>
          </header>
          <section>
            <img
              src={croppedDataUrl || preCropDataUrl}
              className="max-w-full max-h-60 mx-auto"
            />
            <button
              onClick={openCropDialog}
              className="text-fa-primary mt-4 flex items-center font-bold text-s justify-center w-full"
            >
              <CropIcon className="w-6 mr-2" />
              Crop
            </button>
          </section>
          <section className="space-y-2">
            <h2 className="font-bold">Select a different photo</h2>
            <PhotoSelector api={api} onPhotoSelected={onNewPhotoSelected} />
          </section>
        </div>
        <footer className="text-center space-y-4 p-2 bg-white sticky w-full top-full">
          <div className="grid grid-cols-2 gap-2">
            <Button onClick={onPrevious} colorVariant="primary:invert">
              Back
            </Button>
            <Button onClick={onNext} colorVariant="primary">
              Preview
            </Button>
          </div>
        </footer>
      </div>
      <CropDialog
        isOpen={isCropping}
        url={candidateDataUrl || preCropDataUrl}
        aspect={aspectRatio}
        confirm={confirmCrop}
        close={closeCropDialog}
      />
    </>
  )
}
