import semver from 'semver'

export default function useFamilyAlbumUserAgent() {
  const agentInfoParser = () => {
    const userAgent = navigator.userAgent
    const matches = userAgent.match(
      /\b(Mitene|MayChannel|Mitene-Android|MiteneWebView)\/([\d\.]+)\b/
    )
    const matched = matches && matches.length == 3
    const appName = matched ? matches[1] : ''
    const appVersion = matched ? matches[2] : ''

    return {
      matched,
      appName,
      appVersion,
    }
  }
  const agentInfo = agentInfoParser()

  const appVersionSatisfies = (range: string) => {
    return agentInfo.matched && semver.satisfies(agentInfo.appVersion, range)
  }

  return {
    appVersionSatisfies,
    appVersionSupportsCloseWebView: appVersionSatisfies('>=22.3.0'),
  }
}