import { useEffect, useState } from 'react'
import axios from 'axios'

export default function useMarkdownFile(url: string) {
  const [content, setContent] = useState('')
  const loadMarkdownn = async () => {
    const file = await axios.get(url)
    setContent(file.data)
  }

  useEffect(() => {
    loadMarkdownn()
  }, [])

  return content
}
