import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import ApiClient from '../../ApiClient'
import carousel1 from '../../assets/fathers_day/common/carousel/carousel_1.jpg'
import carousel2 from '../../assets/fathers_day/common/carousel/carousel_2.jpg'
import carousel3 from '../../assets/fathers_day/common/carousel/carousel_3.jpg'
import carousel4 from '../../assets/fathers_day/common/carousel/carousel_4.jpg'
import carousel5 from '../../assets/fathers_day/common/carousel/carousel_5.jpg'
import carousel6 from '../../assets/fathers_day/common/carousel/carousel_6.jpg'
import carousel7 from '../../assets/fathers_day/common/carousel/carousel_7.jpg'
import divvyUp20OffCoupon from '../../assets/fathers_day/divvy_up/20_off_coupon.png'
import carouselExample1 from '../../assets/fathers_day/divvy_up/carousel/carouse_example_1.jpg'
import carouselExample2 from '../../assets/fathers_day/divvy_up/carousel/carouse_example_2.jpg'
import carouselExample3 from '../../assets/fathers_day/divvy_up/carousel/carouse_example_3.jpg'
import carouselExample4 from '../../assets/fathers_day/divvy_up/carousel/carouse_example_4.jpg'
import DivvyUpHeaderTitle from '../../assets/fathers_day/divvy_up/divvyip_lp_header_title.svg?react'
import FaDivvyUpLogos from '../../assets/fathers_day/divvy_up/fa_divvyup_logos.svg?react'
import headerCarousel1 from '../../assets/fathers_day/divvy_up/header_carousel/carousel_1.jpg'
import headerCarousel2 from '../../assets/fathers_day/divvy_up/header_carousel/carousel_2.jpg'
import headerCarousel3 from '../../assets/fathers_day/divvy_up/header_carousel/carousel_3.jpg'
import headerCarousel4 from '../../assets/fathers_day/divvy_up/header_carousel/carousel_4.jpg'
import headerCarousel5 from '../../assets/fathers_day/divvy_up/header_carousel/carousel_5.jpg'
import howItWorks1 from '../../assets/fathers_day/divvy_up/how_it_works_1.png'
import howItWorks2 from '../../assets/fathers_day/divvy_up/how_it_works_2.png'
import howItWorks3 from '../../assets/fathers_day/divvy_up/how_it_works_3.png'
import promoSectionBackground from '../../assets/fathers_day/divvy_up/promo_bg.png'
import customSocks1 from '../../assets/fathers_day/divvy_up/socks_1.png'
import customSocks2 from '../../assets/fathers_day/divvy_up/socks_2.png'
import customSocks3 from '../../assets/fathers_day/divvy_up/socks_3.png'
import customSocks4 from '../../assets/fathers_day/divvy_up/socks_4.png'
import customSocks5 from '../../assets/fathers_day/divvy_up/socks_5.png'
import customSocks6 from '../../assets/fathers_day/divvy_up/socks_6.png'
import DetailedCountdown from '../../components/DetailedCountdown'
import FaqItem from '../../components/FaqItem'
import FooterDisclaimer from '../../components/FooterDisclaimer'
import StickyFooter from '../../components/StickyFooter'
import LinkButton from '../../components/buttons/LinkButton'
import useRecordUserAction, {
  C2AMetadata,
} from '../../hooks/useRecordUserAction'
import Carousel from './components/Carousel'
import CustomSocks from './components/CustomSocks'
import HowItWorksItem from './components/HowItWorksItem'
import WhyHeWillLoveItItem from './components/WhyHeWillLoveItItem'

const faqItems = [
  {
    question: 'Do I have to crop my own image when uploading?',
    answer:
      'Nope! Simply upload a picture with good consistent lighting, decent resolution, and the entire subject/face inside the frame of the photo. DivvyUp’s designers will handle the rest. If a person in the picture or your pet is wearing an accessory or outfit, please let their design team know if you would like it included (or excluded) in the order notes at checkout.',
  },
  {
    question: 'Can I get more than 1 face on a pair of socks?',
    answer:
      'You sure can! DivvyUp allows up to 3 unique faces/subjects on most designs.',
  },
  {
    question: 'How long does it take to get my socks?',
    answer:
      'In the USA, shipping via Flat Rate (USPS) takes 3 to 5 days. You also have the option to choose FedEx for Expedited shipping. Shipping time will be calculated at checkout.',
  },
  {
    question: 'Can I ship internationally?',
    answer:
      "Yes! DivvyUp ships internationally, but please note the shipping period will be longer than US domestic shipping, and an extra import fee might be required depending on the recipient's region.",
  },
  {
    question: 'What is the return policy?',
    answer:
      'Hopefully, you immediately fall in love with your custom socks. But if for some reason there is an issue or concern, we accept all returns/exchanges. Ultimately, we want to ensure you’re completely satisfied with your purchase, and we’re happy to help any way we can! Please reach out to the DivvyUp team at team@divvyupsocks.com.',
  },
]

const whyHeWillLoveItItems = [
  {
    title: '💓 Joy Every Step of the Way',
    text: "Whether it's your baby's adorable face or your child's playful drawings, these socks bring daily joy and warmth, sparking conversations wherever he goes.",
  },
  {
    title: '🎁 The Gift of Cherished Memories',
    text: 'Turn precious moments into wearable one-of-a-kind keepsakes with each unique pair, capturing the love shared with your little ones.',
  },
  {
    title: '💫 Comfort with Style',
    text: 'Beyond sentiment, these socks offer everyday comfort and effortless style, perfect for any outing or office wear for him!',
  },
]

const howItWorksItems = [
  {
    src: howItWorks1,
    title: '1. Choose Your Design',
    text: 'Explore our partner’s website to select your favorite custom socks design',
  },
  {
    src: howItWorks2,
    title: '2. Customize Your Socks',
    text: 'Upload your photo, select the base color, and choose the size. Add a gift box for an extra special touch!',
  },
  {
    src: howItWorks3,
    title: '3. Checkout & Spread Joy',
    text: 'Complete checkout and make your loved one smile with this heartfelt gift',
  },
]

const customSocksItems = [
  {
    src: customSocks1,
    text: '#1 Dad',
  },
  {
    src: customSocks2,
    text: 'Custom Drawing Socks',
  },
  {
    src: customSocks3,
    text: '#1 Grandpa',
  },
  {
    src: customSocks4,
    text: '#1 Uncle',
  },
  {
    src: customSocks5,
    text: 'Beer and Pizza',
  },
  {
    src: customSocks6,
    text: 'Dog Dad',
  },
]

const headerCarouselImages = [
  headerCarousel1,
  headerCarousel2,
  headerCarousel3,
  headerCarousel4,
  headerCarousel5,
]
const carouselImages = [
  carousel1,
  carousel2,
  carousel3,
  carousel4,
  carousel5,
  carousel6,
  carousel7,
]

const exampleCarouselImages = [
  carouselExample1,
  carouselExample2,
  carouselExample3,
  carouselExample4,
  carouselExample1,
  carouselExample2,
  carouselExample3,
  carouselExample4,
]

const TWENTY_PERCENT_OFF_DEADLINE_DATE = '2024-06-02T23:59:59.000-04:00' // June 2nd, 2024 23:59:59 AM EST

const DivvyUpFathersDay: FC<{
  api: ApiClient
  supportEmail: string
}> = ({ api, supportEmail }) => {
  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')
  const { recordDidTapSocksC2A } = useRecordUserAction(api)

  const now = new Date()
  const twentyPercentOffDeadline = new Date(TWENTY_PERCENT_OFF_DEADLINE_DATE)
  const isPromoActive = now.getTime() < twentyPercentOffDeadline.getTime()

  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const onCtaClick = useCallback(async (metadata: C2AMetadata) => {
    recordDidTapSocksC2A(metadata)
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % headerCarouselImages.length
      )
    }, 3000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const divvyUpStoreLink = useMemo(() => {
    const lastDigit = parseInt(uuid.slice(-1))

    if (lastDigit % 2 === 0) {
      return 'http://www.divvyupsocks.com/shop?a_aid=8751&a_bid=35c1756d'
    } else {
      return 'http://www.divvyupsocks.com/shop?a_aid=8756&a_bid=35c1756d'
    }
  }, [uuid])

  return (
    <div className="max-w-lg mx-auto">
      <section className="bg-[#53B9AB] relative overflow-hidden pb-[220px] min-h-[580px]">
        <img
          key={currentImageIndex}
          src={headerCarouselImages[currentImageIndex]}
          alt=""
          className="object-cover absolute animate-fadeOut"
        />
        <img
          key={(currentImageIndex + 1) % headerCarouselImages.length}
          src={
            headerCarouselImages[
              (currentImageIndex + 1) % headerCarouselImages.length
            ]
          }
          alt=""
          className="object-cover absolute animate-fadeIn"
        />
        <div className="bg-white w-11/12 mx-auto rounded-2xl py-8 absolute bottom-8 left-0 right-0">
          <DivvyUpHeaderTitle className="mb-4 w-full h-auto" />
          {isPromoActive ? (
            <p className="text-center leading-8 px-4 text-lg font-bold text-[#FB6B92]">
              Last chance to get custom socks <br /> delivered by Father’s Day!
            </p>
          ) : (
            <p className="text-center leading-8 px-4 text-lg text-fa-text-primary">
              Get ready to giggle with special custom <br /> photo/drawing socks
              - because every <br /> step should be a happy one!
            </p>
          )}
        </div>
      </section>
      <section className="bg-[#53B9AB]">
        {isPromoActive ? (
          <div className="w-full  text-white text-center px-4 pb-2">
            <div className="font-bold text-lg">Sale ends in</div>
            <DetailedCountdown
              deadline={twentyPercentOffDeadline}
              variant="cards"
              style={{
                color: '#FFFFFF',
                width: '100%',
              }}
            />
          </div>
        ) : (
          <div className="w-full text-white text-center p-4 px-8 font-bold text-lg">
            <ExclamationCircleIcon className="inline w-7 h-7 mb-1" /> Orders
            made now may be delivered after Father's Day
          </div>
        )}
      </section>
      <section className="bg-[#FEFBE9] py-12">
        <h2 className="font-semibold text-3xl text-center">
          Why He'll Love It!
        </h2>
        <ul className="grid grid-cols-1 gap-8 my-8 px-4">
          {whyHeWillLoveItItems.map((item) => (
            <WhyHeWillLoveItItem
              key={item.title}
              title={item.title}
              text={item.text}
            />
          ))}
        </ul>
        <Carousel images={carouselImages} />
      </section>
      <section className="px-4 py-12 text-center space-y-8">
        <FaDivvyUpLogos className="mx-auto mb-2" />
        <h2 className="font-semibold text-3xl text-center">How It Works</h2>
        <p className="px-8">
          With just a few clicks, you can create an unforgettable Father’s Day
          surprise!
        </p>
        <div className="grid grid-cols-1 gap-8 my-8 px-2">
          {howItWorksItems.map((item) => (
            <HowItWorksItem
              key={item.title}
              src={item.src}
              title={item.title}
              text={item.text}
            />
          ))}
        </div>
      </section>
      <section className="mb-8">
        <h3 className="text-center font-semibold text-lg mb-4">
          Various designs & colors available
        </h3>
        <div className="grid grid-cols-2 p-1 gap-4 mt-2">
          {customSocksItems.map((item) => (
            <CustomSocks
              key={item.text}
              src={item.src}
              alt={item.text}
              text={item.text}
              pClassName="ml-4 mt-2"
            />
          ))}
        </div>
        <div className="text-right mt-6 pr-4">
          <Link
            onClick={() => {
              onCtaClick({
                clickedElement: 'LP_SEE_MORE',
              })
            }}
            className="font-bold text-fa-primary leading-snug"
            to={divvyUpStoreLink}
          >
            See more designs at DivvyUp
          </Link>
        </div>
      </section>
      <section className="mb-12">
        <Carousel images={exampleCarouselImages} />
      </section>
      {isPromoActive ? (
        <section className="bg-[#C2E3FE]">
          <div
            className="py-12 px-8 bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${promoSectionBackground})`,
            }}
          >
            <div className="flex flex-col items-center bg-white py-8 text-center rounded-2xl">
              <h3 className="font-semibold text-3xl mb-4 text-[#1D3E56]">
                Limited time <br />
                Father’s Day offer
              </h3>
              <div className="mb-4">
                <DetailedCountdown
                  deadline={twentyPercentOffDeadline}
                  prefix="Ends in "
                  variant="compact"
                  style={{
                    color: '#212121',
                    width: '100%',
                  }}
                />
              </div>
              <img
                src={divvyUp20OffCoupon}
                alt={'20% discount'}
                className="w-32 mx-auto mb-4"
              />
              <p className="text-lg font-semibold">
                20% OFF 3+ pairs of custom socks
              </p>
              <p className="mb-4 text-lg">
                (applied automatically at checkout) 
              </p>
            </div>
          </div>
        </section>
      ) : (
        <div className="bg-[#53B9AB] w-full text-white text-center p-4 px-8 font-bold text-lg">
          <ExclamationCircleIcon className="inline w-7 h-7 mb-1" /> Orders made
          now may be delivered after Father's Day
        </div>
      )}
      <section className="px-4 py-12 bg-[#FEFBE9]">
        <h2 className="font-bold text-2xl mb-4 text-center">FAQ</h2>
        <div className="text-fa-text-secondary space-y-8">
          {faqItems.map((item) => (
            <FaqItem
              key={item.question}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </section>
      <footer className="px-4 pb-8 mt-12">
        <p>
          Have questions? Contact us at <br />
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </p>
        <FooterDisclaimer />
      </footer>
      <StickyFooter>
        <LinkButton
          onClick={() => {
            onCtaClick({
              clickedElement: 'LP_FOOTER_CTA',
            })
          }}
          className="block p-2"
          colorVariant="primary"
          to={divvyUpStoreLink}
        >
          Get started
        </LinkButton>
      </StickyFooter>
    </div>
  )
}

export default DivvyUpFathersDay
