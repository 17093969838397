import AnchorButton from '../../../shared/buttons/AnchorButton'

export default function RedeemNotEligibleHasReward({
  supportEmail,
}: {
  supportEmail: string
}) {
  return (
    <div className="max-w-md mx-auto h-screen flex flex-col items-center pt-12 px-4 text-center">
      <h1 className="text-xl font-bold mb-4">Thank you for your interest</h1>
      <p className="mb-8 px-6">
        Unfortunately, your account is currently not eligible for rewards.
      </p>
      <div className="bg-fa-bg-secondary p-4 rounded-lg text-fa-dark-gray mb-10 text-left">
        <h2 className="font-bold mb-3">Possible reasons:</h2>
        <ul className="font-normal list-disc pl-5 leading-7">
          <li>
            Your account may have been previously registered with FamilyAlbum
          </li>
          <li>Your account may not be registered as an admin account</li>
          <li>
            The referral reward may have already been claimed for your account
          </li>
        </ul>
      </div>
      <AnchorButton
        className="px-2 py-1.5 w-full mb-12"
        colorVariant="primary"
        target="_blank"
        href="https://help.family-album.com/hc/en-us"
      >
        Visit FAQ
      </AnchorButton>
      <p>
        If you have any questions or concerns, please visit our FAQ or contact
        our customer support team at{' '}
        <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
          {supportEmail}
        </a>
      </p>
    </div>
  )
}
