import cx from 'classnames'
import { FC } from 'react'
import { Variant } from '../../../clients/storefront/types'

const VariantOption: FC<{
  variant: Variant
  selectedVariant: Variant | null
  handleSelectVariant: (variant: Variant) => void
}> = ({ variant, selectedVariant, handleSelectVariant }) => (
  <label className="mb-2 flex items-center text-loop-text-store-primary border border-[#E4E4E4] rounded-md px-0 py-6 relative">
    <span className="flex pl-2">
      <input
        type="radio"
        name="id"
        value={variant.id}
        checked={selectedVariant?.id === variant.id}
        onChange={() => handleSelectVariant(variant)}
        className="opacity-0 w-0 h-0"
      />
      <span
        className={cx('block w-4 h-4 rounded-full border-2', {
          'bg-[radial-gradient(currentcolor_50%,_rgba(255,_0,_0,_0)_51%)]':
            selectedVariant?.id === variant.id,
        })}
      ></span>
    </span>
    <span className="leading-none opacity-80 ml-2 normal-case font-bold text-lg">
      {variant.title}
    </span>
    <div className="absolute right-0 mr-3">
      <p className="font-normal text-[#868686] tracking-0 text-right m-0 normal-case">
        {variant.discountedPercentage}% off
      </p>
      <p className="font-bold text-[#22709E] tracking-0 text-right m-0 normal-case">
        ${variant.unitPrice.amount} per Loop
      </p>
    </div>
  </label>
)
export default VariantOption
