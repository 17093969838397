import React from 'react'
import cx from 'classnames'
import Spinner from '../../../shared/Spinner'
import ProgressBarLoadingAnimation from '../../../shared/ProgressBarLoadingAnimation'

export default function LoadingOverlay({
  children,
  opaque = false,
  animationType = 'spinner',
}: {
  children: React.ReactNode
  opaque?: boolean
  animationType?: 'spinner' | 'progress-bar'
}) {
  return (
    <div
      className={cx(
        'fixed w-full h-full bg-white flex top-0 left-0 items-center justify-center',
        {
          'bg-opacity-90': !opaque,
        }
      )}
    >
      <div className="space-y-4 p-8 flex flex-col items-center justify-centers">
        {animationType === 'spinner' && <Spinner className="w-40" />}
        {animationType === 'progress-bar' && <ProgressBarLoadingAnimation />}
        <p className="text-center">{children}</p>
      </div>
    </div>
  )
}
