import { FC } from 'react'

const HowItWorksItem: FC<{ src: string; title: string; text: string }> = ({
  src,
  title,
  text,
}) => {
  return (
    <div>
      <img src={src} alt={src} className="mx-auto mb-4 w-52" />
      <h3 className="font-semibold text-xl mb-2 text-fa-text-primary">
        {title}
      </h3>
      <p className="text-fa-text-primary tracking-wider">{text}</p>
    </div>
  )
}
export default HowItWorksItem
