import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Lottie from 'lottie-react'
import ApiClient from '../../ApiClient'
import HeaderTitle from '../../assets/grandparents_day/photo_gifts/header_title.svg?react'
import HeaderCatchCopy from '../../assets/grandparents_day/photo_gifts/header_catch_copy.svg?react'
import headerAnimation from '../../animations/grandparents_day/photo_gifts_grandparents_day.json'
import DetailedCountdown from '../../components/DetailedCountdown'
import FaqItem from '../../components/FaqItem'
import FooterDisclaimer from '../../components/FooterDisclaimer'
import StickyFooter from '../../components/StickyFooter'
import LinkButton from '../../components/buttons/LinkButton'
import usePhotoGifts from '../../hooks/usePhotoGifts'
import useRecordUserAction, {
  C2AMetadata,
} from '../../hooks/useRecordUserAction'
import PhotoGiftsSection from './components/PhotoGiftsSection'

const SALE_DEADLINE_DATE = '2024-08-27T23:59:59-07:00' // August 27th, 2024 23:59:59 PDT

export default function PhotoGiftsGrandparentsDay({
  api,
  supportEmail,
  printifyProductsListLink,
}: {
  api: ApiClient
  supportEmail: string
  printifyProductsListLink: string
}) {
  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')
  const printifyProductsListUrlWithUuid =
    `${printifyProductsListLink}?uuid=${uuid}`

  const photoGifts = usePhotoGifts(printifyProductsListLink, uuid)
  const photoProductItems = photoGifts.getPhotoProductItems()
  const socksItems = photoGifts.getSocksItems()

  const { recordDidTapC2A } = useRecordUserAction(api)

  const now = new Date()
  const saleDeadline = new Date(SALE_DEADLINE_DATE)
  const isSaleActive = now.getTime() < saleDeadline.getTime()

  const onCtaClick = useCallback(async (metadata: C2AMetadata) => {
    window.scrollTo(0, 0)
    recordDidTapC2A(metadata)
  }, [])

  const divvyUpContactEmail = 'support@divvyupsocks.com'
  const faqItems = [
    {
      question: 'Can I see a preview of my order?',
      answer:
        'Yes, all custom photo product orders with FamilyAlbum can be previewed before payment. Orders from our custom socks partner, DivvyUp, cannot be previewed.',
    },
    {
      question: 'How long does it take to get my order?',
      answer:
        'Orders are typically delivered in 2 to 8 business days but shipping times may vary due to the shipping method you choose, seasonality, and more.',
    },
    {
      question: 'How much is shipping?',
      answer: (
        <>
          Shipping charges vary by product and location. Your shipping fees
          will be calculated on the checkout screen after you enter your
          shipping address.
          <br />
          Note: Photo Gifts are not eligible for free shipping for Premium users.
        </>
      ),
    },
    {
      question: 'What is your return policy?',
      answer: (
        <>
          This is a custom personalized product, therefore we do not offer
          refunds, returns, or exchanges except for orders with physical defects
          or damage. Damaged orders are eligible for a full refund within 30
          days of delivery. Please reach out to with a photo of your purchase to{' '}
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>{' '}
          to request a refund.
          <br />
          <br />
          For sock orders, please contact our partner DivvyUp at{' '}
          <a className="text-fa-primary" href={`mailto:${divvyUpContactEmail}`}>
            {divvyUpContactEmail}
          </a>
          .
        </>
      ),
    },
    {
      question: 'I have other questions!',
      answer: (
        <>
          Please reach out to{' '}
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>{' '}
          for additional assistance.
        </>
      ),
    },
  ]

  return (
    <div className="max-w-lg mx-auto">
      <section className="bg-[#FFDC00] w-full text-gray-12 text-center p-3 font-bold font-[15px]">
        Up to 20% OFF all Custom Photo Gifts
      </section>
      <section className="bg-[#B2DDB5] relative overflow-hidden min-h-[450px]">
        <Lottie loop={true} animationData={headerAnimation} autoplay />
        <div className="w-11/12 absolute top-8 left-0 right-0 mx-auto">
          <HeaderTitle className="w-full h-auto" />
        </div>
        <div className="text-gray-12 absolute bottom-8 left-9 right-9 text-center">
          <HeaderCatchCopy className="w-full h-auto" />
        </div>
      </section>
      <section className="bg-[#65BA74]">
        {isSaleActive ? (
          <div className="w-full text-white text-center p-3">
            <div className="font-bold">Photo Gifts Sale ends in</div>
            <DetailedCountdown
              deadline={saleDeadline}
              variant="cards"
              style={{
                color: '#ffffff',
                width: '100%',
              }}
            />
          </div>
        ) : (
          <div className="w-full text-gray-12 text-center p-6 font-bold">
            <ExclamationCircleIcon className="inline w-7 h-7 mb-1" /> Orders
            made now may be <br /> delivered after Grandparents’ Day
          </div>
        )}
      </section>
      <PhotoGiftsSection
        onProductClick={onCtaClick}
        socksItems={socksItems}
        photoProductItems={photoProductItems} />
      <section className="px-4 bg-white text-gray-12 mb-12">
        <h2 className="font-bold text-2xl text-center mb-4">Shipping</h2>
        <div className="mb-4">
          <h3 className="font-bold mb-2">Cost:</h3>
          <p className="mb-2">
            Shipping charges and times vary by product type, location and method.
            Enter your shipping address at checkout for details.
          </p>
          <p className="text-fa-text-accent-secondary">
            Note: Photo Gifts are not eligible for free shipping for Premium users.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold mb-2">Delivery:</h3>
          <p className="mb-2">
            Photo Gifts ship only within the U.S. Delivery to the mainland U.S.
            takes about 4 to 8 business days.*
            Tracking information will be sent via email once your order ships.
          </p>
          <p className="text-gray-11 text-[13px]">
            *For Alaska, Hawaii, Puerto Rico, and U.S. territories, please allow an additional 7 to 12 business days.
          </p>
        </div>
      </section>
      <section className="px-4 bg-white mt-4">
        <h2 className="font-bold text-2xl text-center mb-6">FAQ</h2>
        <div className="text-fa-text-secondary space-y-8">
          {faqItems.map((item) => (
            <FaqItem
              key={item.question}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </section>
      <footer className="px-4 pb-8">
        <FooterDisclaimer />
      </footer>
      <StickyFooter>
        <LinkButton
          onClick={() => {
            onCtaClick({
              clickedElement: 'LP_FOOTER_CTA',
            })
          }}
          className="block p-2"
          colorVariant="primary"
          to={printifyProductsListUrlWithUuid}
        >
          Get started
        </LinkButton>
      </StickyFooter>
    </div>
  )
}
