import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import main from '../../../assets/grandparents_day/special/photo_gifts_main.png'
import sub1 from '../../../assets/grandparents_day/special/photo_gifts_sub_1.png'
import sub2 from '../../../assets/grandparents_day/special/photo_gifts_sub_2.png'
import DetailedCountdown from '../../../components/DetailedCountdown'
import RebateBadge from '../../../components/RebateBadge'
import LinkButton from '../../../components/buttons/LinkButton'
import { C2AMetadata } from '../../../hooks/useRecordUserAction'
import SectionHeader from './SectionHeader'

export default function PhotoGiftsSpecialSection({
  order,
  deadline,
  displayDeadline,
  backgroundClass,
  toPath,
  onCtaClick,
}: {
  order: number
  deadline: Date
  displayDeadline: boolean
  backgroundClass: string
  toPath: string
  onCtaClick: (metadata: C2AMetadata) => void
}) {
  return (
    <section className={cx('pb-12 pt-4', backgroundClass)}>
      <SectionHeader order={order} title="Custom Photo Gifts">
        Create joyful moments with custom photo mugs, puzzles, and socks – a
        personal touch for every occasion!
      </SectionHeader>
      <Link
        onClick={() =>
          onCtaClick({
            clickedElement: 'IMAGE',
            slot: order.toString(),
          })
        }
        to={toPath}
      >
        <div className="relative my-2 mx-4 rounded-lg overflow-hidden">
          <img src={main} className="w-full" />
          <RebateBadge rebate="18%" className="absolute top-2 right-2" />
        </div>
        <div className="grid grid-cols-2 gap-2 px-4 pb-6">
          <div className="relative bg-fa-light-gray rounded-lg">
            <img src={sub1} alt="Sub 1" className="w-full" />
            <RebateBadge rebate="20%" className="absolute top-2 right-2" />
          </div>
          <div className="relative bg-fa-light-gray rounded-lg">
            <img src={sub2} alt="Sub 2" className="w-full" />
            <RebateBadge rebate="$5" className="absolute top-2 right-2" />
          </div>
        </div>
      </Link>
      {displayDeadline ? (
        <div className="w-full text-center mb-4">
          <div className="font-bold text-base">Photo Gifts Sale ends in</div>
          <DetailedCountdown
            deadline={deadline}
            style={{
              color: '#000000',
              width: '100%',
            }}
          />
        </div>
      ) : (
        <div className="text-center mb-4 w-11/12 mx-auto">
          <p className="text-[#202020]">
            <ExclamationCircleIcon className="inline w-7 h-7 mb-1" />{' '}
            <b>
              Orders made now may be <br /> delivered after Grandparents’ Day
            </b>
          </p>
        </div>
      )}
      <LinkButton
        onClick={() =>
          onCtaClick({
            clickedElement: 'LP_SECTION',
            slot: order.toString(),
          })
        }
        className="block font-bold p-2 text-center w-11/12 mx-auto mt-2 rounded-md"
        colorVariant="primary"
        to={toPath}
      >
        Order Photo Gifts
      </LinkButton>
    </section>
  )
}
