function DialogCloseButton({
  onClick,
  className,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}) {
  return (
    <button className={className} onClick={onClick}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.45846 0.169129C1.23295 -0.0563767 0.867331 -0.0563763 0.641825 0.16913L0.169276 0.641678C-0.0562298 0.867184 -0.05623 1.2328 0.169276 1.45831L7.7109 8.99993L0.169129 16.5417C-0.0563768 16.7672 -0.0563763 17.1328 0.16913 17.3583L0.641678 17.8309C0.867185 18.0564 1.2328 18.0564 1.45831 17.8309L9.00008 10.2891L16.5417 17.8307C16.7672 18.0562 17.1328 18.0562 17.3583 17.8307L17.8309 17.3582C18.0564 17.1327 18.0564 16.7671 17.8309 16.5415L10.2893 8.99993L17.8307 1.45846C18.0562 1.23295 18.0562 0.867337 17.8307 0.641831L17.3582 0.169283C17.1327 -0.0562236 16.7671 -0.0562237 16.5416 0.169282L9.00008 7.71075L1.45846 0.169129Z"
          fill="black"
        />
      </svg>
    </button>
  )
}

export default DialogCloseButton
