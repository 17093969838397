import familyAlbumGrayLogo from '../assets/fa-gray-logo.svg'

const MaintenancePage = () => {
  return (
    <div className="w-96 h-96 px-4 py-20 flex-col justify-start items-center gap-12 inline-flex">
      <div className="w-24 h-14 relative">
        <img src={familyAlbumGrayLogo} alt="FamilyAlbum" />
      </div>
      <div className="self-stretch h-36 flex-col justify-start items-center gap-4 flex">
        <div className="w-80 text-center text-fa-text-secondary text-base font-bold leading-snug tracking-tight">
          Maintenance in progress
        </div>
        <div className="self-stretch text-center text-fa-text-secondary text-base font-normal leading-relaxed">
          FamilyAlbum is currently undergoing maintenance, but we'll be back
          soon! <br />
          Thank you for your understanding and <br />
          please check back shortly.
        </div>
      </div>
    </div>
  )
}

export default MaintenancePage
