import { ChangeEventHandler } from 'react'
import cx from 'classnames'
import {
  buttonVariants,
  buttonCommonClassName,
} from '../../../shared/buttons/config'
import { ButtonVariant } from '../../../shared/buttons/config'

export default function PhotoUploadButton({
  onFilesSelected,
  className,
  disabled = false,
  colorVariant,
  children,
}: {
  onFilesSelected: ChangeEventHandler<HTMLInputElement>
  className: string
  disabled?: boolean
  colorVariant: ButtonVariant
  children: React.ReactNode
}) {
  return (
    <label
      className={cx(
        buttonCommonClassName,
        'flex flex-col items-center justify-center cursor-pointer',
        buttonVariants[colorVariant],
        className,
        {
          'pointer-events-none opacity-50': disabled,
        }
      )}
    >
      {children}
      <input
        name="photo"
        accept="image/*"
        onChange={onFilesSelected}
        type="file"
        className="hidden"
      />
    </label>
  )
}
