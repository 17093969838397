import { FC, useCallback, useEffect, useState } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import ApiClient from '../../ApiClient'
import { StorefrontClient } from '../../clients/storefront'
import { Cart, Product, Variant } from '../../clients/storefront/types'
import useRecordUserAction from '../../hooks/useRecordUserAction'
import { isDeviceIOS } from '../../utils'
import LoopStoreCart from './LoopStoreCart'
import LoopStoreLanding from './LoopStoreLanding'
import LoopStoreProducts from './LoopStoreProducts'

export const LOCAL_STORAGE_CART_ID_KEY = 'cartId'
export const COUPON_CODE_QUERY_PARAM_KEY = 'couponCode'

const isIOs = isDeviceIOS()

const LoopStore: FC<{ api: ApiClient; storefrontClient: StorefrontClient }> = ({
  api,
  storefrontClient,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [params] = useSearchParams()

  const {
    recordDidTapLoopStoreBuyNow,
    recordDidAddLoopProductToCart,
    recordDidReachLoopCheckout,
    recordDidCreateLoopCart,
  } = useRecordUserAction(api)

  const [cartId, setCartId] = useState<string | null>(null)
  const [product, setProduct] = useState<Product | null>(null)
  const [selectedVariant, setSelectedVariant] = useState<Variant | null>(null)
  const [cart, setCart] = useState<Cart | null>(null)
  const [couponCode, setCouponCode] = useState<string | null>(null)

  useEffect(() => {
    const storedCartId = localStorage.getItem(LOCAL_STORAGE_CART_ID_KEY)
    if (storedCartId) {
      setCartId(storedCartId)
    }
  }, [])

  useEffect(() => {
    if (couponCode === null) {
      const initialCouponCode = params.get(COUPON_CODE_QUERY_PARAM_KEY)
      setCouponCode(initialCouponCode)
    }
  }, [couponCode])

  useEffect(() => {
    if (cartId) {
      localStorage.setItem(LOCAL_STORAGE_CART_ID_KEY, cartId)
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CART_ID_KEY)
    }
  }, [cartId])

  useEffect(() => {
    if (location.pathname === '/loop-store/cart' && cartId) {
      const fetchCart = async () => {
        const fetchedCart = await storefrontClient.getCart(cartId)
        setCart(fetchedCart)
      }
      fetchCart()
    }
  }, [location.pathname, cartId, storefrontClient])

  const clearCartFromLocalStorage = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_CART_ID_KEY)
    setCartId(null)
  }, [])

  const onBuyNow = useCallback(() => {
    window.scrollTo(0, 0)
    recordDidTapLoopStoreBuyNow()
    navigate('buy')
  }, [recordDidTapLoopStoreBuyNow, navigate])

  const extractCartId = useCallback((shopifyCartId: string) => {
    // The cart id format is: gid://shopify/Cart/CART_ID?key=A_HASHED_VALUE
    // we only need the CART_ID for the user action
    const regex = /gid:\/\/shopify\/Cart\/([^?]+)/
    const match = shopifyCartId.match(regex)

    if (match) {
      return match[1]
    }

    return shopifyCartId.split('/').pop()?.split('?').shift() || ''
  }, [])

  const handleAddToCart = async () => {
    if (!selectedVariant) {
      return
    }

    try {
      let newCart
      if (!cartId) {
        newCart = couponCode
          ? await storefrontClient.createCartWithCouponCode(couponCode)
          : await storefrontClient.createCart()

        recordDidCreateLoopCart(extractCartId(newCart.id))
        setCartId(newCart.id)
      }

      if (cartId || newCart.id) {
        const updatedCart = await storefrontClient.addLineItem(
          cartId || newCart.id,
          selectedVariant.id,
          1
        )
        setCart(updatedCart)
        recordDidAddLoopProductToCart(product!.handle, selectedVariant.title)
        navigate('cart')
        window.scrollTo(0, 0)
      }
    } catch (err) {
      console.error('Failed to add to cart:', err)
    }
  }

  const handleSelectVariant = (variant: Variant) => {
    setSelectedVariant(variant)
  }

  const handleOnRemove = async (lineId: string) => {
    if (cartId) {
      const updatedCart = await storefrontClient.removeLineItem(cartId, lineId)
      setCart(updatedCart)
    }
  }

  const handleOnUpdate = async (lines: { id: string; quantity: number }[]) => {
    if (cartId) {
      const updatedCart = await storefrontClient.updateLineItem(cartId, lines)
      setCart(updatedCart)
    }
  }

  const handleOnCheckout = async () => {
    if (cart) {
      clearCartFromLocalStorage()
      await storefrontClient.updateCartAttributes(cart.id, [
        { key: 'vendor', value: 'FamilyAlbum' },
      ])
      recordDidReachLoopCheckout()
      if (isIOs) {
        window.location.href = cart?.checkoutUrl
      } else {
        window.open(cart?.checkoutUrl, '_blank')
        navigate('/modal-loop')
      }
    }
  }

  const fetchProduct = async () => {
    const product = await storefrontClient.getProduct()
    setProduct(product)
  }

  if (!product) {
    fetchProduct()
  }

  return (
    <Routes>
      <Route
        path="buy"
        element={
          <LoopStoreProducts
            product={product}
            selectedVariant={selectedVariant}
            handleSelectVariant={handleSelectVariant}
            handleAddToCart={handleAddToCart}
          />
        }
      />
      <Route
        path="cart"
        element={
          <LoopStoreCart
            cart={cart}
            onRemove={handleOnRemove}
            onCheckout={handleOnCheckout}
            onUpdate={handleOnUpdate}
          />
        }
      />
      <Route index element={<LoopStoreLanding onBuyNow={onBuyNow} />} />
    </Routes>
  )
}

export default LoopStore
