const FramePlaces = ({ imgSrc, title }: { imgSrc: string; title: string }) => (
  <div className="relative">
    <img
      src={imgSrc}
      alt={title}
      className="rounded-lg aspect-square object-contain"
    />
    <span className="absolute top-4 right-4 md:right-6 bg-loop-primary font-bold text-white w-10/12 py-1 rounded-md">
      {title}
    </span>
  </div>
)

export default FramePlaces
