import { FC } from 'react'

const WhyHeWillLoveItItem: FC<{ title: string; text: string }> = ({
  title,
  text,
}) => {
  return (
    <li>
      <h3 className="font-semibold text-lg text-fa-text-primary">{title}</h3>
      <p className="mt-2 text-lg leading-8">{text}</p>
    </li>
  )
}

export default WhyHeWillLoveItItem
