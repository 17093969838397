import { FC, useCallback, useMemo } from 'react'
import { Line } from '../../../clients/storefront/types'
import { create2DecimalsCurrencyFormatter } from '../../../utils'

const CartLineItem: FC<{
  line: Line
  quantity: number | ''
  onQuantityChange: (lineId: string, quantity: string) => void
  onRemove: (lineId: string) => void
}> = ({ line, quantity, onQuantityChange, onRemove }) => {
  const currencyFormatter = useMemo(
    () => create2DecimalsCurrencyFormatter('USD'),
    []
  )

  const handleQuantityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onQuantityChange(line.id, e.target.value)
    },
    [line.id, onQuantityChange]
  )

  const handleRemove = useCallback(() => {
    onRemove(line.id)
  }, [line.id, onRemove])

  return (
    <div className="pt-4 mb-8">
      <div className="flex justify-between items-start mb-4">
        <div className="flex">
          <img
            src={line.merchandise.image.url}
            alt={line.merchandise.title}
            className="border mr-4 w-24"
          />
          <div>
            <h2 className="text-lg">{line.merchandise.product.title}</h2>
            <p className="text-base">{line.merchandise.title}</p>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <input
          type="number"
          name="updates[]"
          value={quantity}
          min={0}
          className="form-input w-20 text-center border border-[#e8e8e1] px-2 py-2"
          onChange={handleQuantityChange}
        />
        <button
          className="px-5 py-1 leading-5 text-sm border text-[#fff] bg-[#2b4353]"
          onClick={handleRemove}
        >
          Remove
        </button>
        <p className="text-lg">
          {currencyFormatter(parseFloat(line.cost.subtotalAmount.amount))}
        </p>
      </div>
    </div>
  )
}

export default CartLineItem
