import useReferralData from './useAdvocateData'
import ApiClient from '../../../ApiClient'
import { useParams } from 'react-router-dom'
import AdvocateInvalid from './AdvocateInvalid'
import AdvocateLoading from './AdvocateLoading'
import AdvocateError from './AdvocateError'
import SuccesfulAutoRedemption from '../SuccesfulFamilyAlbumRewardAutoRedemption'
import AdvocateShareSection from '../AdvocateShareSection'

const AdvocateRedemptionPage = ({
  api,
  familyAlbumDownloadDeepLink,
  familyAlbumStoreDeepLink,
  isPastRedemtpion,
  supportEmail,
}: {
  api: ApiClient
  familyAlbumDownloadDeepLink: string
  familyAlbumStoreDeepLink: string
  isPastRedemtpion?: boolean
  supportEmail: string
}) => {
  const { advocateReferralCode, redemptionId } = useParams<{
    advocateReferralCode: string
    redemptionId: string
  }>()

  if (!advocateReferralCode || !redemptionId) {
    return <AdvocateError error="Invalid URL" />
  }

  const {
    isInvalidAdvocate,
    isLoadingReferralState,
    error,
    shareData,
    email,
    getClaimedFamilyAlbumRedemptionById,
    copyToClipboardMessage,
  } = useReferralData({
    api,
    advocateReferralCode,
    familyAlbumDownloadDeepLink,
  })

  const succesfulRedemption = getClaimedFamilyAlbumRedemptionById(redemptionId)

  if (isLoadingReferralState) {
    return <AdvocateLoading />
  }

  if (!succesfulRedemption) {
    return <AdvocateError error={error} />
  }

  if (isInvalidAdvocate) {
    return <AdvocateInvalid supportEmail={supportEmail} />
  }

  if (error) {
    return <AdvocateError error={error} />
  }

  return (
    <SuccesfulAutoRedemption
      isPastRedemtpion={isPastRedemtpion}
      familyAlbumStoreDeepLink={familyAlbumStoreDeepLink}
      formattedReward={succesfulRedemption.formattedAmount}
    >
      <AdvocateShareSection
        api={api}
        email={email}
        shareData={shareData}
        copyToClipboardMessage={copyToClipboardMessage}
        showReferMore={true}
      />
    </SuccesfulAutoRedemption>
  )
}

export default AdvocateRedemptionPage
