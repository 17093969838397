import { FunctionComponent, MouseEventHandler } from 'react'
import cx from 'classnames'

export const buttonCommonClassName =
  'font-bold rounded tracking-tight relative outline-0'

export const buttonVariants: Record<ButtonVariant, string> = {
  primary:
    'border border-fa-primary bg-fa-primary text-white disabled:bg-button-disabled disabled:text-white disabled:border-button-disabled',
  'primary:invert':
    'border border-fa-primary bg-white text-fa-primary disabled:border-button-disabled disabled:text-button-disabled',
  'primary:invert:no-border': 'border border-white bg-white text-fa-primary',
  'primary:invert:no-border:transparent': 'text-fa-primary',
  secondary:
    'bg-gray-200 text-fa-primary border border-gray-200 disabled:opacity-50',
  danger: 'bg-red-500 text-white border border-red-500',
  'danger:invert': 'bg-white border-fa-attention text-fa-attention border',
  'black-and-white': 'bg-white text-fa-text-secondary',
}

export type ButtonVariant =
  | 'primary'
  | 'primary:invert'
  | 'primary:invert:no-border'
  | 'primary:invert:no-border:transparent'
  | 'secondary'
  | 'danger'
  | 'danger:invert'
  | 'black-and-white'

interface ButtonProps {
  onClick?: MouseEventHandler
  disabled?: boolean
  className?: string
  colorVariant: ButtonVariant
  isLoading?: boolean
  children?: React.ReactNode
}

const BaseButton: FunctionComponent<ButtonProps> = ({
  children,
  className,
  onClick,
  colorVariant,
  disabled,
  isLoading,
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={cx(
      buttonCommonClassName,
      buttonVariants[colorVariant],
      className
    )}
  >
    {isLoading ? (
      <svg
        className="animate-spin h-5 w-5 inline"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    ) : (
      children
    )}
  </button>
)

const Button: FunctionComponent<ButtonProps> = (props) => (
  <BaseButton {...props} className={cx(props.className, 'py-2.5')} />
)

export default Button
