import { useLocation } from 'react-router-dom'
import { ApiClient } from '../ApiClient'
import { useCallback } from 'react'

enum UserActions {
  DID_TAP_LOOP_C2A = 'DidTapLoopC2A',
  DID_COPY_LOOP_PROMO_CODE = 'DidCopyLoopPromoCode',
  DID_CLICK_SMOKESCREEN_C2A = 'DidClickSmokescreenC2A',
  DID_TAP_LOOP_STORE_BUY_NOW = 'DidTapLoopStoreBuyNow',
  DID_ADD_LOOP_PRODUCT_TO_CART = 'DidAddLoopProductToCart',
  DID_REACH_LOOP_CHECKOUT = 'DidReachLoopCheckout',
  DID_CREATE_LOOP_CART = 'DidCreateLoopCart',
  DID_TAP_SOCKS_C2A = 'DidTapSocksC2A',
  DID_COPY_SOCKS_PROMO_CODE = 'DidCopySocksPromoCode',
  DID_TAP_EGIFTS_C2A = 'DidTapEGiftsC2A',
  DID_TAP_PHOTO_GIFTS_C2A = 'DidTapPhotoGiftsC2A',
  DID_TAP_C2A = 'DidTapC2A',
  DID_SELECT_PHOTO_PRODUCT = 'DidSelectPhotoProduct',
  DID_TAP_PERSONALIZE = 'DidTapPersonalizePhotoProduct',
  DID_TAP_PREVIEW = 'DidTapPreviewPhotoProduct',
  DID_TAP_SHOPPING_CART = 'DidTapShoppingCart',
  DID_TAP_TO_GET_TO_CHECKOUT = 'DidTapToGetToCheckout',
  DID_COMPLETE_CHECKOUT = 'DidCompleteCheckout',
  PRINTIFY_PREVIEW_SUCCESS = 'PrintifyPreviewSuccess',
  PRINTIFY_PREVIEW_FAILURE = 'PrintifyPreviewFailure',
}

enum ProductVendor {
  DIVVYUP = 'DIVVYUP',
  PRINTIFY = 'PRINTIFY',
}

type TrackingParams = {
  clickedElement?: string
  productVendor?: ProductVendor
  blueprintId?: number
  productName?: string
  slot?: string
  numberOfPhotos?: number
  numberOfPhotosReady?: number
}

export type C2AMetadata = TrackingParams

const useRecordUserAction = (api: ApiClient) => {
  const location = useLocation()
  const recordUserAction = useCallback(
    (action: string, additionalMetadata: object = {}) => {
      const metadata = Object.assign(
        {
          pathname: location.pathname,
          url: window.location.href,
        },
        additionalMetadata
      )
      return api.logUserAction(action, metadata)
    },
    [location.pathname]
  )

  return {
    recordLoopC2ATap: (metadata?: TrackingParams) =>
      recordUserAction(UserActions.DID_TAP_LOOP_C2A, metadata || {}),
    recordDidCopyLoopPromoCodeTap: () =>
      recordUserAction(UserActions.DID_COPY_LOOP_PROMO_CODE, {}),
    recordSmokeScreenC2ATap: (smokescreen: string) =>
      recordUserAction(UserActions.DID_CLICK_SMOKESCREEN_C2A, {
        smokescreen,
      }),
    recordDidTapLoopStoreBuyNow: () =>
      recordUserAction(UserActions.DID_TAP_LOOP_STORE_BUY_NOW),
    recordDidAddLoopProductToCart: (
      productHandle: string,
      variantTitle: string
    ) =>
      recordUserAction(UserActions.DID_ADD_LOOP_PRODUCT_TO_CART, {
        productHandle,
        variantTitle,
      }),
    recordDidReachLoopCheckout: () =>
      recordUserAction(UserActions.DID_REACH_LOOP_CHECKOUT),
    recordDidCreateLoopCart: (cartId: string) =>
      recordUserAction(UserActions.DID_CREATE_LOOP_CART, {
        cartId,
      }),
    recordDidTapSocksC2A: (metadata: TrackingParams) =>
      recordUserAction(UserActions.DID_TAP_SOCKS_C2A, metadata),
    recordDidCopySocksPromoCodeTap: () =>
      recordUserAction(UserActions.DID_COPY_SOCKS_PROMO_CODE),
    recordDidTapEGiftsC2A: (metadata: TrackingParams) =>
      recordUserAction(UserActions.DID_TAP_EGIFTS_C2A, metadata),
    recordDidTapC2A: (metadata: TrackingParams) =>
      recordUserAction(UserActions.DID_TAP_C2A, metadata),
    recordDidTapPhotoGiftsC2A: (metadata: TrackingParams) =>
      recordUserAction(UserActions.DID_TAP_PHOTO_GIFTS_C2A, metadata),
    photoProducts: {
      divvyup: {
        recordDidSelectPhotoProduct: (metadata: TrackingParams) =>
          recordUserAction(UserActions.DID_SELECT_PHOTO_PRODUCT, {
            ...metadata,
            productVendor: ProductVendor.DIVVYUP,
          }),
      },
      printify: {
        recordPreviewSuccess: (metadata: TrackingParams) =>
          recordUserAction(UserActions.PRINTIFY_PREVIEW_SUCCESS, metadata),
        recordPreviewFailure: (metadata: TrackingParams) =>
          recordUserAction(UserActions.PRINTIFY_PREVIEW_FAILURE, metadata),
        recordDidSelectPhotoProduct: (metadata: TrackingParams) =>
          recordUserAction(UserActions.DID_SELECT_PHOTO_PRODUCT, {
            ...metadata,
            productVendor: ProductVendor.PRINTIFY,
          }),
        recordDidTapPersonalize: (metadata: TrackingParams) =>
          recordUserAction(UserActions.DID_TAP_PERSONALIZE, {
            ...metadata,
            productVendor: ProductVendor.PRINTIFY,
          }),
        recordDidTapPreview: (metadata: TrackingParams) =>
          recordUserAction(UserActions.DID_TAP_PREVIEW, {
            ...metadata,
            productVendor: ProductVendor.PRINTIFY,
          }),
        recordDidTapShoppingCart: (metadata: TrackingParams) =>
          recordUserAction(UserActions.DID_TAP_SHOPPING_CART, {
            ...metadata,
            productVendor: ProductVendor.PRINTIFY,
          }),
        recordDidTapToGetToCheckout: (metadata: TrackingParams) =>
          recordUserAction(UserActions.DID_TAP_TO_GET_TO_CHECKOUT, {
            ...metadata,
            productVendor: ProductVendor.PRINTIFY,
          }),
        recordDidCompleteCheckout: (metadata: TrackingParams) =>
          recordUserAction(UserActions.DID_COMPLETE_CHECKOUT, {
            ...metadata,
            productVendor: ProductVendor.PRINTIFY,
          }),
      },
    },
  }
}

export default useRecordUserAction
