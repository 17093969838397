import { useCallback, useState } from 'react'
import Button from '../../../shared/buttons/Button'
import QuantitySelector from '../components/QuantitySelector'
import { useParams } from 'react-router-dom'
import { BottomPanel } from '../../../shared/BottomPanel'
import ConfirmationDialog from '../../../shared/ConfirmationDialog'

export default function Cart({
  next,
  products,
  subtotal,
  maxQuantity,
  onQuantityChange,
  navigateFromCartToPersonalize,
  navigateFromCartToProduct,
  navigateFromCardToProductsList,
  hasMultipleColors,
  hasMultipleSizes,
}: {
  next: (orderId: string) => void
  previous: () => void
  products: {
    title: string
    quantity: number
    price: string
    imageUrl: string
  }[]
  subtotal: string
  maxQuantity: number
  onQuantityChange: (quantity: number) => void
  navigateFromCartToPersonalize: () => void
  navigateFromCartToProduct: () => void
  navigateFromCardToProductsList: () => void
  hasMultipleColors: boolean
  hasMultipleSizes: boolean
}) {
  const { orderId } = useParams<{ orderId: string }>()
  const onNextClick = useCallback(() => {
    if (!orderId) {
      return
    }
    next(orderId)
  }, [next, orderId])

  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [isConfirmingProductChange, setIsConfirmingProductChange] =
    useState(false)

  const onProductChangeClick = useCallback(() => {
    setIsConfirmingProductChange(true)
  }, [])

  const onProductChangeConfirm = useCallback(() => {
    navigateFromCardToProductsList()
  }, [navigateFromCartToProduct])

  const onProductChangeCancel = useCallback(() => {
    setIsConfirmingProductChange(false)
  }, [])

  return (
    <>
      <div className="px-4 space-y-8 flex flex-col">
        <header>
          <h2 className="font-bold text-xl text-center">Shopping Cart</h2>
        </header>
        <section>
          <ul>
            {products.map((product) => (
              <li key={product.title} className="flex items-center space-x-4">
                <img
                  src={product.imageUrl}
                  alt={product.title}
                  className="w-16 h-16 object-cover rounded flex-shrink-0 flex-grow-0"
                />
                <div className="flex-1 space-y-2">
                  <h3 className="font-bold">{product.title}</h3>
                  <div className="flex items-centerm justify-between">
                    <div className="space-x-2">
                      <QuantitySelector
                        quantity={product.quantity}
                        setQuantity={onQuantityChange}
                        maxQuantity={maxQuantity}
                      />
                    </div>
                    {product.price}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </section>
        <section className="space-y-4 text-center border-t border-gray-5 pt-4">
          <div className="font-bold">Subtotal: {subtotal}</div>
          <p className="text-fa-text-secondary text-s">
            Shipping calculated at checkout
          </p>
        </section>
      </div>
      <footer className="text-center space-y-4 p-2 bg-white sticky top-full">
        <div className="grid grid-cols-2 gap-2">
          <Button
            onClick={() => setIsPanelOpen(true)}
            colorVariant="primary:invert"
          >
            Back
          </Button>
          <Button onClick={onNextClick} colorVariant="primary">
            Payment
          </Button>
        </div>
      </footer>
      <BottomPanel
        isOverlayOpen={isPanelOpen}
        close={() => setIsPanelOpen(false)}
      >
        <div className="flex flex-col space-y-4">
          <Button
            onClick={navigateFromCartToPersonalize}
            colorVariant="primary:invert"
          >
            Change design/edit photo
          </Button>
          {hasMultipleColors && (
            <Button
              onClick={navigateFromCartToProduct}
              colorVariant="primary:invert"
            >
              Select a different color
            </Button>
          )}
          {hasMultipleSizes && (
            <Button
              onClick={navigateFromCartToProduct}
              colorVariant="primary:invert"
            >
              Select a different size
            </Button>
          )}
          <Button onClick={onProductChangeClick} colorVariant="primary:invert">
            Select a different photo gift
          </Button>
          <Button
            onClick={() => setIsPanelOpen(false)}
            colorVariant="primary:invert:no-border"
          >
            Cancel
          </Button>
        </div>
      </BottomPanel>
      <ConfirmationDialog
        isOpen={isConfirmingProductChange}
        close={onProductChangeCancel}
        confirm={onProductChangeConfirm}
        title="Are you sure you want to select a different photo gift?"
        message="You will lose what you have uploaded and edited."
      />
    </>
  )
}
