import { CheckCircleIcon } from '@heroicons/react/24/solid'
import GiftCardFrame from '../../shared/GiftCardFrame'
import AnchorButton from '../../shared/buttons/AnchorButton'
import HowToUseYourBalance from './referred/HowToUseYourBalance'
import { ReactNode } from 'react'

const SuccesfulFamilyAlbumRewardAutoRedemption = ({
  familyAlbumStoreDeepLink,
  formattedReward,
  children,
  isPastRedemtpion,
}: {
  familyAlbumStoreDeepLink: string
  formattedReward: string
  children: ReactNode
  isPastRedemtpion?: boolean
}) => {
  return (
    <div className="max-w-md mx-auto">
      <div className="bg-[#E8F7FD] text-center p-4 mb-4">
        {isPastRedemtpion ? (
          <p>
            Your reward was already <br />
            applied to your account balance
          </p>
        ) : (
          <>
            <p>
              <CheckCircleIcon className="w-5 text-[#71C76B] align-text-bottom mr-2 inline-block" />
              <strong>{formattedReward}</strong> has been added
            </p>
            <p>to your account balance!</p>
          </>
        )}
      </div>
      <main className="pb-4 space-y-8 px-4 pt-4">
        <h1 className="text-xl font-bold px-4 text-center p">
          Congrats! You’ve earned a referral reward
        </h1>
        <GiftCardFrame
          animated={!isPastRedemtpion}
          formattedAmount={formattedReward}
        />
        <HowToUseYourBalance />
      </main>

      <div className="border-t border-t-object-separator-2">{children}</div>
      <footer className="px-2 pb-8">
        <AnchorButton
          colorVariant="primary"
          className="w-full block text-center p-2 rounded-lg"
          href={familyAlbumStoreDeepLink}
        >
          Visit store
        </AnchorButton>
      </footer>
    </div>
  )
}

export default SuccesfulFamilyAlbumRewardAutoRedemption
