import Lottie from 'lottie-react'
import { FC } from 'react'
import ApiClient from '../../../ApiClient'
import headerAnimation from '../../../animations/japanese_smokescreens/JP_LOOP_modal_A_animated_header.json'
import FaLogoColor from '../../../assets/fa-logo-color.svg?react'
import details1 from '../../../assets/japanese_smokescreens/details_1.png'
import loopWhy1Content from '../../../assets/japanese_smokescreens/how_it_works_1.png'
import loopWhy2Content from '../../../assets/japanese_smokescreens/how_it_works_2.png'
import loopWhy3Content from '../../../assets/japanese_smokescreens/how_it_works_3.png'
import loopWhy4Content from '../../../assets/japanese_smokescreens/how_it_works_4.png'
import usage1 from '../../../assets/japanese_smokescreens/usage_1.png'
import usage2 from '../../../assets/japanese_smokescreens/usage_2.png'
import StickyFooter from '../../../components/StickyFooter'
import LinkButton from '../../../components/buttons/LinkButton'
import useRecordUserAction from '../../../hooks/useRecordUserAction'
import LoopWhySection, { LoopWhyContent } from './components/LoopWhySection'
import UsageSection, { UsageContent } from './components/UsageSection'

export enum PricingTier {
  Low = 'low',
  Mid = 'mid',
  High = 'high',
}

type Config = {
  title: string
  pricing: {
    withoutTaxes: string
    withTaxes: string
  }
  paragraph: string
}

type ConfigMap = {
  [K in PricingTier]: Config
}

const config: ConfigMap = {
  [PricingTier.Low]: {
    title: 'みてねの写真・動画がいつでも見られる',
    pricing: {
      withoutTaxes: '15,000',
      withTaxes: '16,500',
    },
    paragraph:
      'みてねの写真や動画を部屋に飾れる、8インチサイズのコンパクトなスマートフォトフレーム。みてねと同期して写真が表示されるので、複雑な操作はいりません。',
  },
  [PricingTier.Mid]: {
    title: '高精細・高画質なスマートフォトフレーム',
    pricing: {
      withoutTaxes: '25,000',
      withTaxes: '27,500',
    },
    paragraph:
      'みてねの写真や動画を部屋に飾れる、10インチサイズの高精細・高画質なスマートフォトフレーム。視野角が広く、斜めからでも写真が綺麗で見やすいのが特徴です。',
  },
  [PricingTier.High]: {
    title: '音声アシスタント機能搭載',
    pricing: {
      withoutTaxes: '36,000',
      withTaxes: '39,600',
    },
    paragraph:
      'みてねの写真や動画を部屋に飾れる、10インチサイズの高精細・高画質なスマートフォトフレーム。視野角が広く、斜めからでも写真が綺麗で見やすいのが特徴です。音声アシスタント機能搭載で、ハンズフリー操作も可能です。',
  },
}

const loopWhyContents: LoopWhyContent[] = [
  {
    src: loopWhy1Content,
    title: '1分でセットアップ',
    description:
      '専用アプリでみてねと連携するだけで、みてねの写真や動画を見ることができます。',
  },
  {
    src: loopWhy2Content,
    title: 'お気に入りの写真や動画を表示',
    description:
      'みてねのお気に入り登録をした写真や動画が、スマートフォトフレームと同期して、自動で更新されます。',
  },
  {
    src: loopWhy3Content,
    title: '使いやすいタッチスクリーン',
    description:
      '画面を見ながらスマホと同じようにタッチ操作ができます。気に入った写真をタップして「いいね」を送ることもできます。',
  },
  {
    src: loopWhy4Content,
    title: '高精細・高画質なフォトフレーム',
    description:
      '大切な写真や動画を鮮やかに映し出し、斜めからでも綺麗に見える広視野角の10インチ大型パネルを採用。',
  },
]

const usageContents: UsageContent[] = [
  {
    src: usage1,
    title: 'どこでも思い出を振り返れる',
    description:
      '玄関や、寝室、リビングなど、様々な場所で思い出を振り返ることができます。',
  },
  {
    src: usage2,
    title: 'プレゼントにもおすすめ',
    description:
      '事前にセットアップを完了して贈れば、そのまますぐに利用可能です。離れて暮らす家族へのプレゼントとしてもおすすめです。',
  },
]

const JapaneseLoopModal: FC<{
  api: ApiClient
  priceTier: PricingTier
}> = ({ api, priceTier }) => {
  const { recordSmokeScreenC2ATap } = useRecordUserAction(api)

  const { title, pricing, paragraph } = config[priceTier]

  return (
    <div className="max-w-lg mx-auto font-hiragino">
      <div className="relative border-b-[1px] border-gray-300">
        <div className="absolute w-full top-6 z-10 text-center">
          <FaLogoColor className="w-20 mx-auto mb-5" />
          <div className="font-bold text-2xl mb-2">
            みてね
            <br />
            スマートフォトフレーム(仮)
          </div>
          <div className="text-[#1495DC] font-light text-base">
            - 企画検討中の商品をご紹介 -
          </div>
        </div>
        <Lottie loop={true} animationData={headerAnimation} autoplay />
      </div>
      <section className="px-4 mt-12 text-center space-y-8">
        <p className="font-light text-base">
          みてねに投稿した写真が自動で表示される、スマートフォトフレームを開発検討中です。
          <br />
          まるで写真立てのように大切な瞬間を飾ることができるので、離れて暮らす家族へのプレゼントとしてもおすすめです。
        </p>
      </section>
      <section className="px-4 py-12 space-y-12">
        <h2 className="font-bold text-2xl text-center xs:text-[23px]">
          スマートフォトフレームの特長
        </h2>
        {loopWhyContents
          // Only show the first 3 items for the low price tier
          .filter((_, index) => priceTier !== PricingTier.Low || index < 3)
          .map((content, index) => (
            <LoopWhySection key={index} {...content} />
          ))}
      </section>
      <section className="bg-[#E6F3FF] py-12 px-4 flex flex-col items-center space-y-12">
        <h2 className="font-bold text-2xl mt-6">利用シーン</h2>
        {usageContents.map((content, index) => (
          <UsageSection key={index} {...content} />
        ))}
      </section>
      <section className="pt-6 pb-12 px-4 flex flex-col items-center space-y-12 text-center">
        <h2 className="font-bold text-2xl mt-6">商品詳細</h2>
        <div>
          <img
            src={details1}
            alt="みてねスマートフォトフレーム(仮)"
            className="mx-auto mb-6"
          />
          <div className="text-sm font-bold mb-1">{title}</div>
          <h3 className="font-bold text-xl mb-2 text-fa-text-primary">
            みてねスマートフォトフレーム(仮)
          </h3>
          <div className="text-[13px] font-light mb-4">
            ¥<span className="font-bold text-base">{pricing.withoutTaxes}</span>
            <span>（税込 ¥{pricing.withTaxes}）</span>
          </div>
          <p className="text-fa-text-primary text-left text-base tracking-tight leading-7 font-light">
            {paragraph}
          </p>
        </div>
      </section>
      <hr className="mx-auto w-11/12" />
      <section className="py-12 px-4 flex flex-col items-center text-center">
        <p className="text-xl font-bold">
          今後の開発のため、
          <br />
          商品にご興味がある方は
          <br />
          アンケートにご協力ください
        </p>
      </section>
      <StickyFooter>
        <LinkButton
          onClick={() => recordSmokeScreenC2ATap(`jp_loop_${priceTier}`)}
          className="py-3 mx-auto block text-center rounded-lg text-base font-bold"
          colorVariant="primary"
          to="content"
        >
          詳しくはこちら
        </LinkButton>
      </StickyFooter>
    </div>
  )
}

export default JapaneseLoopModal
