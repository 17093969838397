import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import cx from 'classnames'
import { MouseEventHandler } from 'react'

const StepNumber = ({
  isActive,
  number,
}: {
  number: number
  isActive: boolean
}) => {
  return (
    <span
      className={cx(
        'relative font-bold rounded-full w-5 h-5 flex items-center justify-center transition-all',
        {
          'bg-mi-primary text-white': isActive,
          'text-mi-primary  border-mi-primary border bg-white border-solid':
            !isActive,
        }
      )}
    >
      {number}
    </span>
  )
}

export default function CheckoutProgressBar({
  activeStepNumber,
  orderedSteps,
  onBackClick,
}: {
  activeStepNumber: number
  orderedSteps: string[]
  onBackClick?: MouseEventHandler
}) {
  const numOfSteps = orderedSteps.length
  const showBackButton = !!onBackClick
  return (
    <div className="relative w-full flex justify-center">
      {showBackButton && (
        <button
          onClick={onBackClick}
          className="absolute text-s space-y-1 h-full left-0 pl-4 flex flex-col items-center justify-end text-fa-primary"
        >
          <ChevronLeftIcon className="h-5 w-5" />
          <span className="text-mi-primary">Back</span>
        </button>
      )}
      <ul
        className={cx('grid relative w-60 text-s', {
          'grid-cols-4': numOfSteps === 4,
          'grid-cols-3': numOfSteps === 3,
        })}
      >
        {orderedSteps.map((step, index) => (
          <div
            key={step}
            className="flex flex-col items-center space-y-1 relative"
          >
            <div
              className={cx('absolute top-3.5 bg-mi-primary h-[1px]', {
                'left-1/2  w-1/2': index === 0,
                'left-0 w-full': index > 0 && index < numOfSteps - 1,
                'right-1/2 w-1/2': index === numOfSteps - 1,
              })}
            />
            <StepNumber
              number={index + 1}
              isActive={index + 1 <= activeStepNumber}
            />
            <span className="text-mi-primary">{step}</span>
          </div>
        ))}
      </ul>
    </div>
  )
}
