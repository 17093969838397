import { FC } from 'react'

const CustomSocks: FC<{
  src: string
  alt: string
}> = ({ src, alt }) => (
  <div className="relative mx-auto py-2">
    <img src={src} alt={alt} className="w-[144px] h-[144px]" />
  </div>
)

export default CustomSocks
