import animationData from '../animations/fa-gift-card.json'
import Lottie from 'lottie-react'

const GiftCardFrame = ({
  formattedAmount,
  animated,
}: {
  formattedAmount: string
  animated: boolean
}) => (
  <div
    className="relative rounded-xl"
    style={{
      background: `
      linear-gradient(
        411.71deg, rgba(255, 246, 212, 0.9) 32.88%,
        rgba(255, 255, 255, 0.9) 58.82%,
        rgba(255, 220, 210, 0.9) 90.12%)
      `,
    }}
  >
    <Lottie autoplay={animated} animationData={animationData} />

    <div className="p-4 text-right absolute right-0 top-0">
      <p className="text-xl font-bold tracking-wide">{formattedAmount}</p>
      <p className="text-fa-text-secondary text-s">eGift card</p>
    </div>
  </div>
)

export default GiftCardFrame
