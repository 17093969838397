import { MouseEventHandler } from 'react'
import {
  FaglFamilyAlbumMediaFilter,
  FamilyAlbumPhotoSummaryResponseFamily,
} from '../../../ApiClient'
import Header from './Header'
import GroupHeader from './GroupHeader'
import GroupCategory from './GroupCategory'

export default function Summary({
  photoSummary,
  onCategoryClick,
  closePanel,
}: {
  photoSummary: FamilyAlbumPhotoSummaryResponseFamily[]
  closePanel: MouseEventHandler
  onCategoryClick: (
    familyAlbumFamilyId: number,
    filter: FaglFamilyAlbumMediaFilter
  ) => void
}) {
  return (
    <>
      <Header onClose={closePanel} title="Select photo" />
      {photoSummary.map(({ id, name, counts, thumbnails }) => (
        <div key={id}>
          <GroupHeader>{name}</GroupHeader>
          <ul>
            {thumbnails.favorite !== null && (
              <GroupCategory
                title="Favourites"
                count={counts.favorite}
                onClick={() => {
                  onCategoryClick(id, FaglFamilyAlbumMediaFilter.FAVORITE)
                }}
                thumbnailSrc={thumbnails.favorite}
              />
            )}
            {thumbnails.recommended !== null && (
              <GroupCategory
                title="Recommended"
                count={counts.recommended}
                onClick={() => {
                  onCategoryClick(id, FaglFamilyAlbumMediaFilter.RECOMMENDED)
                }}
                thumbnailSrc={thumbnails.recommended}
              />
            )}

            {thumbnails.all !== null && (
              <GroupCategory
                title="All photos"
                count={counts.all}
                onClick={() => {
                  onCategoryClick(id, FaglFamilyAlbumMediaFilter.ALL)
                }}
                thumbnailSrc={thumbnails.all}
              />
            )}
          </ul>
        </div>
      ))}
    </>
  )
}
