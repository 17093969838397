export const PRODUCT_BY_HANDLE_QUERY = `
  query ProductQuery($handle: String) {
    product(handle: $handle) {
      id
      handle
      title
      variants(first: 10) {
        nodes {
          id
          title
          compareAtPrice {
            amount
            currencyCode
          }
          price {
            amount
            currencyCode
          }
          unitPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
`

export const FETCH_CART_QUERY = `
  query FetchCartQuery($cartId: ID!) {
    cart(id: $cartId) {
      id
      createdAt
      updatedAt
      lines(first: 10) {
        nodes {
          id
          merchandise {
            ... on ProductVariant {
              id
              image {
                url
              }
              title
              product {
                title
              }
            }
          }
          quantity
          cost {
            subtotalAmount {
              amount
              currencyCode
            }
          }
        }
      }  
      cost {
        subtotalAmount {
          amount
          currencyCode
        }
      }
      checkoutUrl
    }
  }
`

export const CREATE_CART_MUTATION_WITH_COUPON_CODE = `
  mutation CreateCartMutation($couponCode: [String!]!) {
    cartCreate(input: {discountCodes: $couponCode}) {
      cart {
        id
      }
    }
  }
`

export const CREATE_CART_MUTATION = `
  mutation CreateCartMutation {
    cartCreate {
      cart {
        id
      }
    }
  }
`

export const ADD_LINE_ITEM_TO_CART_MUTATION = `
  mutation AddLineItemToCartMutation($cartId: ID!, $variantId: ID!, $quantity: Int!) {
    cartLinesAdd(cartId: $cartId, lines: [{ quantity: $quantity, merchandiseId: $variantId }]) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          nodes {
            id
            merchandise {
              ... on ProductVariant {
                id
                image {
                  url
                }
                title
                product {
                  title
                }
              }
            }
            quantity
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
            }
          }
        }  
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
        }
        checkoutUrl
      }
    }
  }
`

export const REMOVE_LINE_ITEM_FROM_CART_MUTATION = `
  mutation RemoveLineItemFromCartMutation($cartId: ID!, $lineId: ID!) {
    cartLinesRemove(cartId: $cartId, lineIds: [$lineId]) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          nodes {
            id
            merchandise {
              ... on ProductVariant {
                id
                image {
                  url
                }
                title
                product {
                  title
                }
              }
            }
            quantity
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
            }
          }
        }  
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
        }
        checkoutUrl
      }
    }
  }
`

export const UPDATE_LINE_ITEM_IN_CART_MUTATION = `
  mutation UpdateLineItemInCartMutation($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          nodes {
            id
            merchandise {
              ... on ProductVariant {
                id
                image {
                  url
                }
                title
                product {
                  title
                }
              }
            }
            quantity
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
            }
          }
        }  
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
        }
        checkoutUrl
      }
    }
  }
`

export const UPDATE_CART_ATTRIBUTES_MUTATION = `
  mutation CartAttributesUpdateMutation($cartId: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
      cart {
        id
      }
    }
  }
`
