import { ChevronRightIcon } from '@heroicons/react/24/solid'

export default function GroupCategory({
  title,
  count,
  thumbnailSrc,
  onClick,
}: {
  title: string
  count: number
  onClick: Function
  thumbnailSrc: string
}) {
  return (
    <li role="button" className="bg-white pl-4" onClick={() => onClick()}>
      <div className="py-3 pr-3 flex items-center border-b border-object-separator-2 last:border-b-0">
        <img
          alt="title"
          className="h-12 w-12 rounded-lg mr-4"
          src={thumbnailSrc}
          data-clarity-mask="True"
        />
        <div>
          <p className="mb-1">{title}</p>
          <p
            className="text-s text-fa-text-tertiary"
            data-clarity-unmask="true"
          >
            {count} photos
          </p>
        </div>
        <button className="ml-auto">
          <ChevronRightIcon className="h-6 text-[#9E9E9E]" />
        </button>
      </div>
    </li>
  )
}
