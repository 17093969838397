import { MouseEventHandler } from 'react'
import {
  AlreadyClaimedRedemptionWithFamilyAlbumGiftCard,
  AlreadyClaimedRedemptionWithRunaGiftCard,
} from '../../../ApiClient'
import GiftCardIcon from '../../../assets/gift-card-black.svg?react'

function ClaimedRunaGiftCard({
  value,
  deliveryUrl,
}: {
  value: number
  deliveryUrl: string
}) {
  return (
    <div className="rounded-3xl bg-[#F5F5F5] py-2.5 px-6 w-full text-left">
      <a href={deliveryUrl} className="text-black text-sm font-bold">
        <GiftCardIcon className="inline-block align-text-top mr-2" />
        <span>${value} Amazon.com Gift Card</span>
      </a>
    </div>
  )
}

function ClaimedFamilyAlbumGiftCard({
  value,
  onClick,
}: {
  value: number
  onClick: MouseEventHandler
}) {
  return (
    <button
      onClick={onClick}
      className="rounded-3xl bg-[#F5F5F5] py-2.5 px-6 w-full text-left"
    >
      <div className="text-black text-sm font-bold">
        <GiftCardIcon className="inline-block align-text-top mr-2" />
        <span>${value} FamilyAlbum eGift Card</span>
      </div>
    </button>
  )
}

export default function AdvocateClaimedRedemptions({
  claimedFamilyAlbumRedemptions,
  claimedRunaRedemptions,
  onFamilyAlbumRedemptionClick,
}: {
  claimedFamilyAlbumRedemptions: AlreadyClaimedRedemptionWithFamilyAlbumGiftCard[]
  claimedRunaRedemptions: AlreadyClaimedRedemptionWithRunaGiftCard[]
  onFamilyAlbumRedemptionClick: (redemptionId: number) => void
}) {
  return (
    <section className="space-y-2">
      <p className="text-sm font-bold text-fa-text-secondary mt-8 px-4">
        Claimed rewards
      </p>
      <div className="space-y-2 px-4">
        {claimedFamilyAlbumRedemptions.map((redemption) => (
          <ClaimedFamilyAlbumGiftCard
            key={redemption.id}
            value={redemption.value / 100}
            onClick={() => {
              onFamilyAlbumRedemptionClick(redemption.id)
            }}
          />
        ))}
        {claimedRunaRedemptions.map((redemption) => (
          <ClaimedRunaGiftCard
            key={redemption.id}
            value={redemption.value / 100}
            deliveryUrl={redemption.deliveryUrl}
          />
        ))}
      </div>
    </section>
  )
}
