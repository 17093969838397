import { FC, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ApiClient from '../../ApiClient'
import DadGiftTitle from '../../assets/fathers_day/special/dad_gifts_options_header_title.svg?react'
import FooterDisclaimer from '../../components/FooterDisclaimer'
import useRecordUserAction, {
  C2AMetadata,
} from '../../hooks/useRecordUserAction'
import { b64Encode } from '../../utils'
import EGiftsHeader from './components/EGiftsHeader'
import EGiftsSection from './components/EGiftsSection'
import SocksHeader from './components/SocksHeader'
import SocksSection from './components/SocksSection'

const SOCKS_DELIVERABLE_DEADLINE = '2024-06-02T11:59:59.000-04:00'
const EGIFTS_FATHERS_DAY_PROMO_END_DATE = '2024-06-16T23:59:59'

const shouldPrioritizeSocksPromo = (
  now: Date,
  socksDeliverableDeadline: Date
) => now.getTime() < socksDeliverableDeadline.getTime()

const now = new Date()
const socksDeliverableDeadline = new Date(SOCKS_DELIVERABLE_DEADLINE)
const egiftsFathersDayPromoEndDate = new Date(EGIFTS_FATHERS_DAY_PROMO_END_DATE)

const prioritizeSocksPromo = shouldPrioritizeSocksPromo(
  now,
  socksDeliverableDeadline
)

const FathersDaySpecial: FC<{
  api: ApiClient
  familyAlbumAutoLoginUrl: string
}> = ({ api, familyAlbumAutoLoginUrl }) => {
  const { recordDidTapSocksC2A, recordDidTapEGiftsC2A } =
    useRecordUserAction(api)

  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')

  const onCtaClickEGifts = useCallback((metadata: C2AMetadata) => {
    recordDidTapEGiftsC2A(metadata)
  }, [])

  const onCtaClickSocks = useCallback((metadata: C2AMetadata) => {
    window.scrollTo(0, 0)
    recordDidTapSocksC2A(metadata)
  }, [])

  const sectionsConfig = useMemo(() => {
    return {
      socks: {
        order: prioritizeSocksPromo ? 1 : 2,
        backgroundClass: prioritizeSocksPromo ? 'bg-[#FFFFFF]' : 'bg-[#FEFBE9]',
        deadline: socksDeliverableDeadline,
        onCtaClick: onCtaClickSocks,
      },
      eGifts: {
        order: !prioritizeSocksPromo ? 1 : 2,
        backgroundClass: !prioritizeSocksPromo
          ? 'bg-[#FFFFFF]'
          : 'bg-[#FEFBE9]',
        deadline: egiftsFathersDayPromoEndDate,
        onCtaClick: onCtaClickEGifts,
      },
    }
  }, [
    prioritizeSocksPromo,
    socksDeliverableDeadline,
    egiftsFathersDayPromoEndDate,
    onCtaClickSocks,
    onCtaClickEGifts,
  ])

  const sectionsOrder = prioritizeSocksPromo
    ? ['socks', 'eGifts']
    : ['eGifts', 'socks']

  const socksToPath = useMemo(() => `/divvyup-fathers-day?uuid=${uuid}`, [uuid])
  const eGiftsToPath = useMemo(
    () =>
      `${familyAlbumAutoLoginUrl}?data=${b64Encode(
        JSON.stringify({ redirection: 'fathersday_greeting' })
      )}`,
    [familyAlbumAutoLoginUrl]
  )

  return (
    <div className="max-w-lg mx-auto">
      {prioritizeSocksPromo ? (
        <SocksHeader
          deadline={sectionsConfig.socks.deadline}
          onCtaClick={sectionsConfig.socks.onCtaClick}
          toPath={socksToPath}
        />
      ) : (
        <EGiftsHeader
          deadline={sectionsConfig.eGifts.deadline}
          onCtaClick={sectionsConfig.eGifts.onCtaClick}
          toPath={eGiftsToPath}
        />
      )}
      <DadGiftTitle className="w-full" />
      <div className="grid grid-cols-1">
        {sectionsOrder.map((section, index) => (
          <div key={section} className={`order-${index + 1}`}>
            {section === 'socks' ? (
              <SocksSection {...sectionsConfig.socks} toPath={socksToPath} />
            ) : (
              <EGiftsSection {...sectionsConfig.eGifts} toPath={eGiftsToPath} />
            )}
          </div>
        ))}
      </div>
      <footer className="px-4 pb-8">
        <FooterDisclaimer />
      </footer>
    </div>
  )
}

export default FathersDaySpecial
