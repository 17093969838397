import { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ApiClient from '../../ApiClient'
import FooterDisclaimer from '../../components/FooterDisclaimer'
import useRecordUserAction, {
  C2AMetadata,
} from '../../hooks/useRecordUserAction'
import { b64Encode } from '../../utils'
import EGiftsHeader from './components/EGiftsHeader'
import EGiftsSection from './components/EGiftsSection'
import LoopHeader from './components/LoopHeader'
import LoopSection from './components/LoopSection'
import PhotoGiftsHeader from './components/PhotoGiftsHeader'
import PhotoGiftsSpecialSection from './components/PhotoGiftsSpecialSection'

const PHOTO_GIFTS_SALE_DEADLINE = '2024-08-27T23:59:59-07:00' // 8/27 (Tue) at 11:59 pm PDT
const LOOP_SALE_DEADLINE = '2024-08-30T11:59:59-07:00' // 8/30 (Fri) at 11:59 pm PDT
const LOOP_DELIVERY_DEADLINE = '2024-09-03T23:59:59-04:00' // 9/3 (Tue) at 11:59 pm EDT
const EGIFTS_PROMO_DEADLINE = '2024-09-08T23:59:59-04:00' // 9/8 (Sun) at 11:59 pm EDT

const now = new Date()
const photoGiftsSaleDeadline = new Date(PHOTO_GIFTS_SALE_DEADLINE)
const loopSaleDeadline = new Date(LOOP_SALE_DEADLINE)
const loopPromoDeadline = new Date(LOOP_DELIVERY_DEADLINE)
const eGiftsPromoDeadline = new Date(EGIFTS_PROMO_DEADLINE)

const shouldPrioritizePhotoGiftsPromo = (
  now: Date,
  photoGiftsSaleDeadline: Date
) => now.getTime() <= photoGiftsSaleDeadline.getTime()

const prioritizePhotoGiftsPromo = shouldPrioritizePhotoGiftsPromo(
  now,
  photoGiftsSaleDeadline
)

const shouldPrioritizeLoopPromo = (now: Date, loopPromoDeadline: Date) =>
  now.getTime() <= loopPromoDeadline.getTime()

const prioritizeLoopPromo = shouldPrioritizeLoopPromo(now, loopPromoDeadline)

const shouldDisplayLoopSaleHeader = (now: Date, loopSaleDeadline: Date) =>
  now.getTime() <= loopSaleDeadline.getTime()

const displayLoopSaleHeader = shouldDisplayLoopSaleHeader(now, loopSaleDeadline)

const shouldDisplayEGiftsDeadline = (now: Date, eGiftsPromoDeadline: Date) =>
  now.getTime() <= eGiftsPromoDeadline.getTime()

const displayEGiftsDeadline = shouldDisplayEGiftsDeadline(
  now,
  eGiftsPromoDeadline
)

export default function GrandparentsDaySpecial({
  api,
  familyAlbumAutoLoginUrl,
}: {
  api: ApiClient
  familyAlbumAutoLoginUrl: string
}) {
  const { recordDidTapPhotoGiftsC2A, recordDidTapEGiftsC2A, recordLoopC2ATap } =
    useRecordUserAction(api)

  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')

  const onCtaClickEGifts = useCallback((metadata: C2AMetadata) => {
    recordDidTapEGiftsC2A(metadata)
  }, [])

  const onCtaClickPhotoGifts = useCallback((metadata: C2AMetadata) => {
    window.scrollTo(0, 0)
    recordDidTapPhotoGiftsC2A(metadata)
  }, [])

  const onCtaClickLoop = useCallback((metadata: C2AMetadata) => {
    window.scrollTo(0, 0)
    recordLoopC2ATap(metadata)
  }, [])

  const sectionsConfig = useMemo(() => {
    const photoGiftsOrder = prioritizePhotoGiftsPromo ? 1 : 3
    const loopOrder =
      (prioritizeLoopPromo ? 1 : 2) + (prioritizePhotoGiftsPromo ? 1 : 0)
    const eGiftsOrder =
      (prioritizeLoopPromo ? 2 : 1) + (prioritizePhotoGiftsPromo ? 1 : 0)
    return {
      photoGifts: {
        key: 'photoGifts',
        order: photoGiftsOrder,
        backgroundClass: 'bg-[#FFFFFF]',
        deadline: photoGiftsSaleDeadline,
        displayDeadline: prioritizePhotoGiftsPromo,
        onCtaClick: onCtaClickPhotoGifts,
      },
      loop: {
        key: 'loop',
        order: loopOrder,
        backgroundClass: loopOrder % 2 === 1 ? 'bg-[#FFFFFF]' : 'bg-[#FEFBE9]',
        onSale: displayLoopSaleHeader,
        deadline: displayLoopSaleHeader ? loopSaleDeadline : loopPromoDeadline,
        displayDeadline: prioritizeLoopPromo,
        onCtaClick: onCtaClickLoop,
      },
      eGifts: {
        key: 'eGifts',
        order: eGiftsOrder,
        backgroundClass:
          eGiftsOrder % 2 === 1 ? 'bg-[#FFFFFF]' : 'bg-[#FEFBE9]',
        deadline: eGiftsPromoDeadline,
        displayDeadline: displayEGiftsDeadline,
        onCtaClick: onCtaClickEGifts,
      },
    }
  }, [
    prioritizePhotoGiftsPromo,
    prioritizeLoopPromo,
    photoGiftsSaleDeadline,
    loopSaleDeadline,
    loopPromoDeadline,
    displayLoopSaleHeader,
    eGiftsPromoDeadline,
    displayEGiftsDeadline,
    onCtaClickPhotoGifts,
    onCtaClickEGifts,
    onCtaClickLoop,
  ])

  const orderedSections = useMemo(() => {
    return [
      sectionsConfig.photoGifts,
      sectionsConfig.loop,
      sectionsConfig.eGifts,
    ].sort((a, b) => (a.order < b.order ? -1 : 1))
  }, [sectionsConfig])

  const photoGiftsToPath = useMemo(
    () => `/photo-gifts-grandparents-day?uuid=${uuid}`,
    [uuid]
  )
  const loopToPath = '/modal-loop-grandparents-day'
  const eGiftsToPath = useMemo(
    () =>
      `${familyAlbumAutoLoginUrl}?data=${b64Encode(
        JSON.stringify({ redirection: 'grandparentsday_greetings' })
      )}`,
    [familyAlbumAutoLoginUrl]
  )

  return (
    <div className="max-w-lg mx-auto">
      {prioritizePhotoGiftsPromo ? (
        <PhotoGiftsHeader
          deadline={sectionsConfig.photoGifts.deadline}
          onCtaClick={sectionsConfig.photoGifts.onCtaClick}
          toPath={photoGiftsToPath}
        />
      ) : prioritizeLoopPromo ? (
        <LoopHeader
          deadline={sectionsConfig.loop.deadline}
          onCtaClick={sectionsConfig.loop.onCtaClick}
          toPath={loopToPath}
          displaySaleHeader={displayLoopSaleHeader}
        />
      ) : (
        <EGiftsHeader
          deadline={sectionsConfig.eGifts.deadline}
          displayDeadline={displayEGiftsDeadline}
          onCtaClick={sectionsConfig.eGifts.onCtaClick}
          toPath={eGiftsToPath}
        />
      )}
      <div key="giftOptions" className="px-4 pt-8 text-[#202020] text-center">
        <h3 className="text-[26px] font-bold">Gift Options</h3>
        <p className="pt-2">
          Make them smile with custom socks, a digital photo frame filled with
          memories, or heartfelt digital greetings💓
        </p>
      </div>
      <div key="sections" className="grid grid-cols-1">
        {orderedSections.map((section) => (
          <div key={section.key}>
            {section.key == 'photoGifts' ? (
              <PhotoGiftsSpecialSection
                {...section}
                key={section.key}
                toPath={photoGiftsToPath}
              />
            ) : section.key == 'loop' ? (
              <LoopSection {...section} key={section.key} toPath={loopToPath} />
            ) : (
              <EGiftsSection
                {...section}
                key={section.key}
                toPath={eGiftsToPath}
              />
            )}
          </div>
        ))}
      </div>
      <footer key="footer" className="px-4 pb-8">
        <FooterDisclaimer />
      </footer>
    </div>
  )
}
