import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import FaDivvyUpLogos from '../../../assets/grandparents_day/divvy_up/fa_divvyup_logos.svg?react'
import { C2AMetadata } from '../../../hooks/useRecordUserAction'
import PhotoGiftProductCard from './PhotoGiftProductCard'
import { SocksItem, PhotoProductItem } from '../../../hooks/usePhotoGifts'

export default function PhotoGiftsSection({
  onProductClick,
  photoProductItems,
  socksItems,
}: {
  onProductClick: (metadata: C2AMetadata) => void
  photoProductItems: PhotoProductItem[],
  socksItems: SocksItem[],
}) {
  const onPhotoProductCtaClick = useCallback(
    async (blueprintId: number, productName: string) => {
      onProductClick({
        clickedElement: 'OUR_PRODUCT',
        blueprintId,
        productName,
      })
    },
    []
  )

  const onSocksCtaClick = useCallback(async (productName: string) => {
    onProductClick({
      clickedElement: 'DIVVYUP_PRODUCT',
      productName,
    })
  }, [])

  return (
    <>
      <section>
        <div className="grid grid-cols-2 gap-4 p-4 max-w-[400px] mx-auto">
          {photoProductItems && photoProductItems.map((item) => (
            <Link
              key={item.blueprintId}
              onClick={() => {
                onPhotoProductCtaClick(item.blueprintId, item.name)
              }}
              className="font-bold text-fa-primary leading-snug"
              to={item.url}
            >
              <PhotoGiftProductCard
                src={item.src}
                text={item.name}
                rebate={item.rebate}
              />
            </Link>
          ))}
        </div>
      </section>
      <section className="my-8">
        <FaDivvyUpLogos className="mx-auto mb-4" />
        <h3 className="text-center font-bold">
          Exclusive Offer for <br /> FamilyAlbum Users!
        </h3>
        <div className="grid grid-cols-2 gap-4 p-4 max-w-[400px] mx-auto">
          {socksItems && socksItems.map((item) => (
            <Link
              key={item.name}
              onClick={() => {
                onSocksCtaClick(item.name)
              }}
              className="font-bold text-fa-primary leading-snug"
              to={item.url}
            >
              <PhotoGiftProductCard
                src={item.src}
                text={item.name}
                rebate={item.rebate}
              />
            </Link>
          ))}
        </div>
      </section>
    </>
  )
}
