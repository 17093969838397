import cx from 'classnames'
import { FC } from 'react'

const CustomSocks: FC<{
  src: string
  alt: string
  text: string
  pClassName?: string
}> = ({ src, alt, text, pClassName }) => (
  <div>
    <img src={src} alt={alt} className="w-10/12 block mx-auto" />
    <p className={cx('text-fa-text-primary text-xs', pClassName)}>{text}</p>
  </div>
)

export default CustomSocks
