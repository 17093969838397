const getEmailSubjectWithReferredReward = (messagePayload: MessagePayload) =>
  `🎁 Get a ${messagePayload.referredReward} FamilyAlbum Gift Card when you try FamilyAlbum`

export const EMAIL_SUBJECT_WITHOUT_REFERRED_REWARD =
  "You should try FamilyAlbum, I know you'll love it"

interface MessagePayload {
  claimLink: string
  familyAlbumDownloadDeepLink: string
  referredReward: string
  advocateReward: string
  maxEarnings: string
}

export const getEmailMessageWithReferredReward = ({
  claimLink,
  familyAlbumDownloadDeepLink,
  referredReward,
}: MessagePayload) => `
Hi there!

I've been using this amazing app called FamilyAlbum to share photos with family and I thought you might like it too!

It's free, secure, and includes unlimited storage, which is awesome for keeping all your favorite memories in one place. It's helped me stay connected with family and share our latest adventures with ease.

You can earn a ${referredReward} FamilyAlbum eGift Card just for trying the app!
Here's how:

1. Download the free FamilyAlbum app here: ${familyAlbumDownloadDeepLink}
2. Open the app on your device, tap "Create Your Child's Album", and complete the album creation process (don't select "Got an Invite?")
3. Tap here on the same device to claim your gift card: ${claimLink} \r\r
	
Enjoy! 😊
`

export const getEmailMessageWithoutReferredReward = ({
  claimLink,
  familyAlbumDownloadDeepLink,
}: MessagePayload) => `
Hi there!

I've been using this amazing app called FamilyAlbum to share photos with family and I thought you might like it too!

It's free, secure, and includes unlimited storage, which is awesome for keeping all your favorite memories in one place. It's helped me stay connected with family and share our latest adventures with ease.

Here's how:

1. Download the free FamilyAlbum app here: ${familyAlbumDownloadDeepLink}
2. Open the app on your device, tap "Create Your Child's Album", and complete the album creation process (don't select "Got an Invite?")
3. Tap here on the same device so I can earn rewards: ${claimLink}
	
Enjoy! 😊
`

export const getShareMessageWithReferredReward = ({
  claimLink,
  referredReward,
  familyAlbumDownloadDeepLink,
}: MessagePayload) => `
I've been using the free FamilyAlbum app to share photos and videos with my family. Follow the steps below to try the app and get a ${referredReward} FamilyAlbum gift card!\n
1. Download the free FamilyAlbum app here: ${familyAlbumDownloadDeepLink} \r
2. Open the app, tap "Create Your Child's Album," and complete the album creation process (don't select "Got an Invite?")\r
3. Tap here on the same device to claim your gift card: ${claimLink} \r\r
Enjoy! 😊
`

export const getShareMessageWithoutReferredReward = ({
  claimLink,
  familyAlbumDownloadDeepLink,
}: MessagePayload) => `
I've been using the free FamilyAlbum app to share photos and videos with my family. Follow the steps below to try the app!\n
1. Download the free FamilyAlbum app here: ${familyAlbumDownloadDeepLink} \r
2. Open the app, tap "Create Your Child's Album," and complete the album creation process (don't select "Got an Invite?")\r
3. Tap here on the same device so I can earn rewards: ${claimLink} \r\r
Enjoy! 😊
`

export const getCopyToClipboardMessageWithReferredReward = ({
  claimLink,
  familyAlbumDownloadDeepLink,
  referredReward,
}: MessagePayload) => `
I've been using the free FamilyAlbum app to share photos and videos with my family. Follow the steps below to try the app and get a ${referredReward} FamilyAlbum gift card!\n
1. Download the free FamilyAlbum app here: ${familyAlbumDownloadDeepLink} \r
2. Open the app, tap "Create Your Child's Album," and complete the album creation process (don't select "Got an Invite?")\r
3. Tap here on the same device to claim your gift card: ${claimLink} \r\r
Enjoy! 😊
`

export const getCopyToClipboardMessageWithoutReferredReward = ({
  claimLink,
  familyAlbumDownloadDeepLink,
}: MessagePayload) => `
I've been using the free FamilyAlbum app to share photos and videos with my family. Follow the steps below to try the app!\n
1. Download the free FamilyAlbum app here: ${familyAlbumDownloadDeepLink} \r
2. Open the app, tap "Create Your Child's Album," and complete the album creation process (don't select "Got an Invite?")\r
3. Tap here on the same device so I can earn rewards: ${claimLink} \r\r
Enjoy! 😊
`

export const getShareDataWithReferredReward = (
  messagePayload: MessagePayload
) => ({
  title: 'FamilyAlbum',
  text: getShareMessageWithReferredReward(messagePayload),
})

export const getShareDataWithoutReferredReward = (
  messagePayload: MessagePayload
) => ({
  title: 'FamilyAlbum',
  text: getShareMessageWithoutReferredReward(messagePayload),
})

const getAdvocatePagePreMaxOutCopy = ({
  advocateReward,
  referredReward,
}: MessagePayload) => {
  let copy = `Every time a friend downloads the FamilyAlbum app and creates a new
        album for their family, you earn a ${advocateReward} FamilyAlbum eGift Card*!`

  if (referredReward) {
    if (advocateReward === referredReward) {
      copy = `Every time a friend downloads the FamilyAlbum app and creates a new
        album for their family, you and your friend each earn a ${referredReward} FamilyAlbum eGift Card*!`
    } else {
      copy = `Every time a friend downloads the FamilyAlbum app and creates a new
        album for their family, you earn ${advocateReward} and your friend earns a ${referredReward} FamilyAlbum eGift Card*!`
    }
  }

  return copy
}

const getUpToCopy = (maxEarnings: string) => `Get up to ${maxEarnings}!`

export const getCopy = (
  messagePayload: MessagePayload,
  hasReferredReward: boolean
) => {
  const emailSubject = hasReferredReward
    ? getEmailSubjectWithReferredReward(messagePayload)
    : EMAIL_SUBJECT_WITHOUT_REFERRED_REWARD

  const email = `mailto:?body=${encodeURIComponent(
    hasReferredReward
      ? getEmailMessageWithReferredReward(messagePayload)
      : getEmailMessageWithoutReferredReward(messagePayload)
  )}&subject=${emailSubject} `

  const shareData = hasReferredReward
    ? getShareDataWithReferredReward(messagePayload)
    : getShareDataWithoutReferredReward(messagePayload)

  const copyToClipboardMessage = hasReferredReward
    ? getCopyToClipboardMessageWithReferredReward(messagePayload)
    : getCopyToClipboardMessageWithoutReferredReward(messagePayload)

  return {
    email,
    shareData,
    copyToClipboardMessage,
    advocatePreMaxOutCopy: getAdvocatePagePreMaxOutCopy(messagePayload),
    upToCopy: getUpToCopy(messagePayload.maxEarnings),
  }
}
