import Spinner from '../../../shared/Spinner'

export default function AdvocateLoading() {
  return (
    <div className="h-screen flex flex-col justify-center items-center px-8 text-center">
      <p className="font-bold mb-2">Thanks for your patience!</p>
      <p className="text-fa-text-secondary mb-8">
        Please wait while we load your referral information...
      </p>
      <Spinner className="block h-20" />
    </div>
  )
}
