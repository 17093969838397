import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export function BottomPanel({
  isOverlayOpen,
  children,
  close,
}: {
  isOverlayOpen: boolean
  close: Function
  children: React.ReactNode
}) {
  return (
    <Transition show={isOverlayOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => close()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-fa-dark bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto flex justify-end items-end">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-y-20"
            enterTo="translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-20"
          >
            <Dialog.Panel className="bg-white px-4 pt-12 pb-4 rounded-t-lg w-full">
              <div className="">{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
