import { XMarkIcon } from '@heroicons/react/24/solid'
import Panel from '../../../shared/Panel'
import PhotoProductsTou from '../PhotoProductsTou'

export default function TermsOfUsePanel({
  isOpen,
  close,
}: {
  isOpen: boolean
  close: () => void
}) {
  return (
    <Panel isOpen={isOpen} close={close}>
      <div className="w-screen h-screen bg-white relative overflow-auto">
        <div className="sticky top-0 flex justify-end bg-white p-4">
          <button onClick={close} className="text-blue-10 flex items-center">
            Close <XMarkIcon className="w-5" />
          </button>
        </div>
        <PhotoProductsTou />
      </div>
    </Panel>
  )
}
