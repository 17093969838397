import postReferralIllustration from '../../../assets/post-referral-illustration.svg'

export default function AdvocatePostMaxOut() {
  return (
    <div className="px-4 w-full flex flex-col items-center pt-8">
      <h1 className="text-xl font-bold">Spread the word, invite friends</h1>
      <p className="mb-6">You've earned all available rewards for now</p>
      <img alt="" className="w-[230px] mb-5" src={postReferralIllustration} />
      <p className="px-4 text-center">
        While you won't receive additional rewards at this time, you can still
        invite friends to join FamilyAlbum and help them stay connected with
        their loved ones!
        <a
          target="_blank"
          href="https://help.family-album.com/hc/en-us"
          className="text-fa-primary font-extrabold block text-center"
          rel="noreferrer"
        >
          Learn more
        </a>
      </p>
    </div>
  )
}
