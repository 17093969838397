import { FC } from 'react'
import AnchorButton from '../../../shared/buttons/AnchorButton'
import inviteIllustration from '../../../assets/referral_friend.png'
import faAppLogo from '../../../assets/fa-app-logo.svg'
import { Link, useParams } from 'react-router-dom'
import ConfettiHeader from '../../../shared/ConfettiHeader'
import ErrorPage from '../../ErrorPage'

const RedeemLanding: FC<{
  familyAlbumDownloadDeepLink: string
  generateReferredUserAutologinUrl: (advocateReferralCode: string) => string
}> = ({ generateReferredUserAutologinUrl, familyAlbumDownloadDeepLink }) => {
  const { advocateReferralCode } = useParams<{ advocateReferralCode: string }>()
  if (!advocateReferralCode) {
    return <ErrorPage message="Missing referral code" error="Invalid URL" />
  }

  const autologinFinalUrl =
    generateReferredUserAutologinUrl(advocateReferralCode)

  return (
    <div className="max-w-md mx-auto h-screen pb-8">
      <ConfettiHeader className="pt-10 mb-8">
        <h1 className="text-xl font-bold px-20 text-center">
          Your referral reward is just a tap away!
        </h1>
      </ConfettiHeader>
      <div className="mb-16 px-4 space-y-8 text-center">
        <img className="max-w-[228px] mx-auto" src={inviteIllustration} />
        <AnchorButton
          href={autologinFinalUrl}
          colorVariant="primary"
          className="w-full px-2 py-1.5 block text-center rounded-lg"
        >
          Claim my reward now
        </AnchorButton>
        <p className="text-s px-4 text-fa-text-tertiary">
          FamilyAlbum Gift Card{' '}
          <Link className="text-fa-primary" to="/referral/gc-terms">
            Terms and Conditions
          </Link>
        </p>
      </div>

      <section className="mt-8 px-4 bg-fa-bg-secondary flex-1 py-12">
        <h2 className="font-bold mb-3 text-center">
          Don't have FamilyAlbum yet?
        </h2>
        <img src={faAppLogo} alt="FamilyAlbum" className="mx-auto pb-4" />
        <ol className="mb-8 text-left list-decimal pl-8 leading-7">
          <li>Download the free FamilyAlbum app</li>
          <li>
            Open the app and select ”Create Your Child's Album” —{' '}
            <span className="text-fa-attention">
              make sure not to select “Got an Invite?”
            </span>
          </li>
          <li>Come back here and claim your reward!</li>
        </ol>
        <AnchorButton
          colorVariant="primary:invert"
          className="w-full p-2 rounded-lg block text-center"
          href={familyAlbumDownloadDeepLink}
        >
          Download the app
        </AnchorButton>
      </section>
    </div>
  )
}

export default RedeemLanding
